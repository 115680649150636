
class LinkProps {

  
  private label?:string
  private link?:string

  constructor(label:string, link:string) {
    this.label = label
    this.link = link
  }

}

export default LinkProps;