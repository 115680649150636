
import Report from "../Report"

class ReportYusei  extends Report{


  return_unit = "持ち戻り個数"
  night_unit="夜間配達個数"
  large_unit="大型配達個数"
  load_unit  = "集荷個数"
  normal_unit = "通常配達個数"
  normal_load_unit  = undefined
  nekopos_unit = undefined
  nekopos_load_unit = undefined
  dm_unit = undefined
  dm_load_unit  = undefined
  fee ?= "本日の運賃"
  code = undefined
  meter_start = undefined
  meter_end = undefined
  
  requested_unit = undefined

  load_location = undefined
  delivery_location  = undefined

  return_requested_unit = undefined
  compleated_unit = undefined
  detail =undefined




  
  
  waiting_hour =undefined

  received_gender=undefined
  received_name =undefined



}

export default ReportYusei

