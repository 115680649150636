import React, {  } from 'react'
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

function ReportCreateSuccess(props:PropsValue) {




  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">日報入力</h2>
                <Breadcrumbs pages={[new LinkProps("日報入力","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">日報登録 完了</p>
                <p>日報登録が完了しました。</p>
									
							</div>
						</div> 
				</div>
		</div>

  )
}

export default ReportCreateSuccess

