import React, {useEffect, useState} from 'react';
import useReceiptState from '../../../receipt/useReceiptState';



interface ImageBoxProps {
  token:String,
  url:Any,
  report_id:Number,
  receipt_id:Number
}

/**
 * Primary UI component for user interaction
 */
export const ImageBox = ({
  token,
  url,
  report_id,
  receipt_id,
  ...props
}: ImageBoxProps) => { 
  
  const {media, getImage} = useReceiptState()
  const [src, setSrc] = useState(null)

  useEffect(() => {

    if(media)
      if(media.type === "application/pdf"){
        setSrc(<div className="link" onClick={downloadPdf}>↓ダウンロードPDF</div>);
      }else{
        setSrc(<img src={url.createObjectURL(media)} alt=""/>);
      }
      //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[media])



  useEffect(() => {

    getImage(report_id,receipt_id, token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const downloadPdf = () => {
    const link = document.createElement('a');
    // BlobをもとにURLを作成
    const url = URL.createObjectURL(media);
    link.href = url
    link.target = "_blank"
    // ダウンロード実行
    link.click()
    link.download = 'receipt_' + receipt_id + '.txt';
    //link.parentNode.removeChild();
  }

  return (
    <div >{src}</div>
    );
};

