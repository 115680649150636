import React, {useState, useEffect  } from 'react'
import { useParams , useSearchParams} from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ClientDetail from "./ClientDetail"
import ClientProps from './ClientProps';
import useClientState from './useClientState';

function ClientDetailApp(props:PropsValue) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const is_new = searchParams.get("new") ?  searchParams.get("new") : 0 ;
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)
  const [has_cookie, setHasCookie] = useState(false)

  const {client, saveOnCookie, getClient, loadFromCookie, hasCookie} = useClientState()

  useEffect(() => {

    if(token){
      setHasCookie(hasCookie())
      if(is_new === 0 && hasCookie()){
        loadFromCookie()
      }else{
        if(id === "new"){
          setItem(new ClientProps())
        }else{
          getClient(token, id)
        }
      }
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  

  useEffect(() => {
    if(client){
      setItem(new ClientProps(client))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client])

  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    saveOnCookie(_item)
    window.location.href = "/client/confirm"
  }
  
  const saveOnCookieHandler = (item) => {
    saveOnCookie(item)
    window.location.href = "/client"
  }

  return(
    <div id="home">
      <PageApp current_page="client" content={<ClientDetail  has_cookie={has_cookie} token={token} client={item} updateHandler={updateHandler} saveOnCookieHandler={saveOnCookieHandler} loadFromCookie={loadFromCookie}/>} authChangeHandler={authChangeHandler}  />
    </div>
  )
}

export default ClientDetailApp

