import { Record } from 'immutable'

const MonthlyReportProps = Record({
  year:null,
  month:null,
  date:null,
  content:null,
  total_number:null,
  total_number_yamato_normal:null,
  total_number_yamato_neko:null,
  total_number_yamato_dm:null,

  total_number_yusei_normal:null,
  total_number_yusei_big:null,
  total_number_yusei_night:null,
  total_number_yusei_collect:null,

  highway_cost:null,
  parking_cost:null,
  other_cost:null,
  unit_price:null,
  over_time_min:null,
  over_distance:null,
  driver:null,
  project:null,
  project_driver:null,
  url:null,
  updated_at:null,
  units:[]
});
export default MonthlyReportProps;
