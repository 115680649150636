import React, { useEffect, useState  } from 'react'
import config from 'react-global-configuration';
import jwt_decode from "jwt-decode";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import useLoginState from './useLoginState';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
function Login(props:PropsValue) {
  const [mail, setMail ] = useState(null)
  const [password, setPassword] = useState(null)
  const { loginError,  token, login, loginByPassword} = useLoginState()

  useEffect(() => {
    if(token){
      window.location.href = "/dashboard"
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  useEffect(() => {
    console.log(loginError)
  },[loginError])

  const loginClickHandler = () => {
    loginByPassword(mail, password)
  }

  return(
    <div className="inner">
		  <form >
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">ユーザーログイン</h2>
                <Breadcrumbs pages={[new LinkProps("ユーザーログイン","#")]} />
							</div>
							
							<div className="container">
                {loginError ? <div className="error">ログインに失敗しました。アカウントがシステムに登録されていない可能性があります。</div> : null}
                <GoogleOAuthProvider clientId={config.get("GOOGLE_ID")}>
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      const credential = jwt_decode(credentialResponse.credential)
                      login(credential.email)
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </GoogleOAuthProvider>
                  </div>
                  <div className="container">
                <table className="input_table col12 login_tbl">
                    <tbody>
                      <tr>
                        <th className="tl">ユーザーID</th>
                        <td><input type="text" className="col9" value={mail} onChange={(e) => setMail(e.target.value)}/></td>
                      </tr>
                      <tr>
                        <th class="tl">パスワード</th>
                        <td><input type="password" className="col9" value={password} onChange={(e) => setPassword(e.target.value)}/></td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="button_wrap center">
                  
                  <button className="red" onClick={loginClickHandler} type="button">ログイン</button>
                </div>
						  </div>
			
				</div>
      </form>
		</div>

  )
}

export default Login

