import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import Select from 'react-select';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ja from 'date-fns/locale/ja'
import format from 'date-fns/format';
import Utile from '../../utiles/Utile';

function ProjectDetailItem(props:PropsValue) {
  const default_styles={
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#f5f5f5",
      padding:".5rem .2rem",
      borderRadius:"10px",
    }),
  }

  const [item, setItem] = useState(null)
  const [format_types, setFormatTypes] = useState(null) 

  
  useEffect(() => {
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.clients])

  useEffect(() => {
    setItem(props.project)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project])

  useEffect( () => {
    if(item && item.category){

      const _list = config.get("REPORT_TYPE").map(_item => {
        if(_item.project_categories.includes(Number(item.category))){
          return(<option value={_item.value}>{_item.label}</option>)
        }else{ return null }
      })
      setFormatTypes(_list)
    }
   
  },[item])
  

  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
    props.changeHandler(new_item)
  } 

  const changeNameValueHandler = (name, value) => {
    const new_item = item.set(name, value)
    setItem(new_item)
    props.changeHandler(new_item)
  } 

  const getDefaultValue = ( a ) => {
   
    if(!a || !props.clients || !a.client_id){
      return null
    }  
    return props.clients.find(c => Number(c.value) === Number(a.client_id))
  }

  const getCleint = ( ) => {

    if(!item ) return null
    if(!props.clients) return null 

    const clinet = props.clients.find(c => Number(c.value) === Number(item.client_id))
    if(clinet) return   clinet.label
    if(item.client) return item.client.name
    return "-"
  }

  return(
    <table className="input_table col12 login_tbl">
      <tbody>
      
        <tr>
          <th className="tl">クライアント名 *</th>
          <td>
          { props.is_edit && item && !item.id && props.clients ? 
            <Select
                  placeholder= "選択してください"
                  options={props.clients}
                  loadOptions={props.loadOptions}
                  noOptionsMessage={()=>"該当するクライアント企業が見つかりません"}
                  onChange={props.changeClientrHandler}
                  styles={default_styles}
                  value={getDefaultValue(item)}
                />
            :  getCleint()
          }
          </td>
        </tr>
         
       
        <tr>
          <th className="tl">案件名 *</th>
          <td>
          {
            props.is_edit ? 
            <input autocomplete="off" type="text" className="col9" placeholder=""  name="name" value={item ? item.name : undefined}  onChange={changeHandler}/>
            : item ? item.name : null
          }
          </td>
        </tr>
      
        <tr>
          <th className="tl">種類 *</th>
          <td>
            {
              props.is_edit ? 
            <select name="category" value={item ? item.category : undefined}  onChange={changeHandler} >
              <option value="" selected="">選択してください</option>
              {
                config.get("PROJECT_CATEGORY").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
            }
            </select>
            : item ? Utile.getLabelByValue(config.get("PROJECT_CATEGORY"),Number(item.category)) : null
            }
          </td>
        </tr>
        <tr>
          <th className="tl">締め日 *</th>
          <td>
           {
              props.is_edit ? 
            <select name="closing_date" value={item ? item.closing_date : undefined}  onChange={changeHandler} >
            <option value="" selected="">選択してください</option>
            {
                config.get("PROJECT_CLOSING_DATE_TYPE").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
            }
            </select>
            : item ? Utile.getLabelByValue(config.get("PROJECT_CLOSING_DATE_TYPE"),Number(item.closing_date)) : null
            }
          </td>
        </tr>
        <tr>
          <th className="tl">月報ひな形 *</th>
          <td>
          {
              props.is_edit ? 
            <select name="report_type" value={item ? item.report_type : undefined}  onChange={changeHandler} >
            <option value="" selected="">選択してください</option>
            {
               format_types
              }
            </select>
             : item ? Utile.getLabelByValue(config.get("REPORT_TYPE"),Number(item.report_type)) : null
            }
          </td>
        </tr>
        <tr>
          <th className="tl">残業設定 *</th>
          <td>
          {
              props.is_edit ? 
            <select name="overtime_fee_type" value={item ? item.overtime_fee_type : undefined}  onChange={changeHandler}  >
              <option value="" selected="">選択してください</option>
              {
                config.get("PROJECT_OVERTIME_TYPE").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
              }
            </select>
            : item ? Utile.getLabelByValue(config.get("PROJECT_OVERTIME_TYPE"),Number(item.overtime_fee_type)) : null
          }
          </td>
        </tr>
        <tr>
          <th className="tl">距離設定 *</th>
          <td>
          {
              props.is_edit ? 
            <select  name="distance_type" value={item ? item.distance_type : undefined} onChange={changeHandler} >
            <option value="" selected="">選択してください</option>
            {
                config.get("PROJECT_DISTANCE_TYPE").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
              }
            
            </select>
            : item ? Utile.getLabelByValue(config.get("PROJECT_DISTANCE_TYPE"),Number(item.distance_type)) : null
          }
          </td>
        </tr>
        <tr>
          <th className="tl" >勤務時間設定 *</th>
          <td>
          {
              props.is_edit ? 
            <select name="working_time_type" value={item ? item.working_time_type : undefined} onChange={changeHandler} >
            <option value="" selected="">選択してください</option>
            {
                config.get("PROJECT_WORKING_TIME_TYPE").map(item => {
                  return(<option value={item.value}>{item.label}</option>)
                })
              }
            </select>
              : item ? Utile.getLabelByValue(config.get("PROJECT_WORKING_TIME_TYPE"),Number(item.working_time_type)) : null
            }
          </td>
        </tr>
        {item && Number(item.working_time_type) === 1 ? 
        <tr>
          <th className="tl" >時間設定開始時刻</th>
          <td>
          {
              props.is_edit ? 
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
              <TimePicker defaultValue={item ? new Date("1977-01-01 " +  item.overtime_start_at): undefined}ampm={false} onChange={(e) => {changeNameValueHandler("overtime_start_at", format(e, "HH:mm"))}}/>
            </LocalizationProvider>
             : item ? item.overtime_start_at : null
            }
          </td>
        </tr>
        : null}
        {item && Number(item.working_time_type) === 1 ? 
        <tr>
          <th className="tl">時間設定終了時刻</th>
          <td>
          {
              props.is_edit ? 
          
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
              <TimePicker defaultValue={item ? new Date("1977-01-01 " +  item.overtime_close_at): undefined} ampm={false} onChange={(e) => {changeNameValueHandler("overtime_close_at", format(e, "HH:mm"))}}/>
            </LocalizationProvider>
         
            : item ? item.overtime_close_at : null
          }
           </td>
        </tr>
        : null}
        {item && Number(item.working_time_type) === 2? 
        <tr>
          <th className="tl">拘束時間設定</th>
          <td>
          {
              props.is_edit ? 
              <span><input autocomplete="off" type="number" className="col5" max={24} min={0} placeholder="8" name="time_set" value={item ? item.time_set : undefined} onChange={changeHandler} /> 時間</span>
              : item ? item.time_set : null
            }
          </td>
        </tr>
        : null}
        <tr>
          <th className="tl">備考</th>
          <td>
          {
              props.is_edit ? <textarea type="text" className="col12" rows="5" name="note" value={item ? item.note : undefined} onChange={changeHandler} />
              : item ? item.note : null
          }
          </td>

        </tr>
      </tbody>
  </table>
    
  )
}

export default ProjectDetailItem

