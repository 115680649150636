import React, { useEffect, useState } from 'react'
import useReportState from '../report/useReportState'
import { subYears,subMonths, format } from 'date-fns';

function DriverDetailLinks(props:PropsValue) {
  const [projects, setProject] = useState(null)
  const [message, setMessage] = useState(null)
  const [reports_list, setReportList] = useState(null)
  const [payments, setPayments] = useState(null)
  const {list_updated_at, report_list,getReportUrl, getReportList} = useReportState()


  useEffect(() => {
    if(list_updated_at){
      
      const today = new Date()
      const _p_list = [];
      const _m_list = [];

      (new Array(12).fill(0).map((m, index) => {
        const target_date = subMonths(today, index)
      
        const report = isWorking(target_date,report_list )
        if(report){
          _m_list.push(<div className="link-list-item"><a  href={"/monthly_report?driver_id=" + report.driver.uid + "&year=" + target_date.getFullYear()+ "&month=" + (target_date.getMonth() + 1) }>{target_date.getFullYear()}年{target_date.getMonth() + 1}月</a></div> )
          
          _p_list.push(<div className="link-list-item"><a  href={"/monthly_payment/1/"  + target_date.getFullYear()+ "/" +(target_date.getMonth() + 1) +"/driver/" + report.driver.id + "/e" }>{target_date.getFullYear()}年{target_date.getMonth() + 1}月</a></div> )
        }
        return(  report  )
      }))
      setReportList(_m_list)
      setPayments(_p_list)
      
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])


  useEffect(() => { 
    
    if(props.driver && props.driver.company_driver === 1){ 
      const today = new Date()
      getReportList(props.token, null, 1, null, props.driver.id,  format(subYears(today,1),"yyyy-MM-dd"), format(today, "yyyy-MM-dd"), null, 1)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.driver])


  useEffect(() => {
    if(props.projects){ 
   
      const _projects= props.projects.filter(item => {
        const _driver_ids = item.drivers.map(d => {return (d.driver_id)} )
        if(_driver_ids.findIndex(_id => Number(_id) === Number(props.driver.id)) >= 0 ) {
          return item
        }
        else return null 
     
      }).filter(item => item) 
      setProject(_projects.map( project => {
        const project_driver = project.drivers.filter(driver => driver.driver.id === props.driver.id)[0]
        const url = getReportUrl(project.uid, project_driver.uid, "new")
        return(
        <div className="driver-list-sub">
          {project.name} <a href={"/project/" + project.id + "/driver/" + project_driver.id}>稼働条件 <i className="fa-solid fa-arrow-right"></i></a> 
          <div className="flex-right"><a  className="flex-right" href={url}>ドライバー入力フォーム <i className="fa-solid fa-arrow-right"></i> </a>&nbsp; &nbsp; <span className="link" onClick={() => copy(url, project_driver.id)}> <i className="fa-regular fa-copy"></i></span>{project_driver.id === message ? message : null}</div>
        </div>)
      }))
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.projects, message])


  const copy = (url,id) => {
    navigator.clipboard.writeText(url).then(  () => {}, () => {});
    setMessage(id)
  }

  const isWorking = (target_date, report_list) => {
    const year = target_date.getFullYear()
    const month = target_date.getMonth()
    
    const result = report_list.find(item => {
      const test_date = new Date(item.worked_date)
      return ( test_date.getFullYear() === year && test_date.getMonth() === month)
    })
  
    return result
  }

  return(
    <div className="column-right">
      <h4>案件一覧</h4>
       <div className="item-list">
        {projects}
      </div>
     
      {
        props.driver && props.driver.company_driver === 1 ? 
        <div>
          <h4>月報一覧（過去1年分）</h4>
          <div className="item-list">
            {reports_list}
          </div>
          <h4>支払い明細一覧（過去1年分）</h4>
          <div className="item-list">
            {payments}
          </div>
        </div>
      :null}

    </div>
  
    
  )
}

export default DriverDetailLinks

