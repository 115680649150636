import React, { useEffect, useState,  } from 'react'

import config from 'react-global-configuration';
import { Breadcrumbs } from '../../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../../utiles/Utile';

//project
import useProjectState from '../useProjectState';

function ProjectDriverDelete(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)

  const [project_object, setProjectObject] = useState(null)
  const {project, getProject} = useProjectState()


  useEffect(() => {
    if(props.project){
      setItem(props.project)
      getProject(props.token, props.project_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project])


  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler()
  }

  useEffect(() => {
    setProjectObject(project)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project])

  const getWorkingDayStying = () => {
    const days = []
    if(!item ) return null 
    if(item.sunday) days.push("日")
    if(item.monday) days.push("月")
    if(item.thuesday) days.push("火")
    if(item.wednesday) days.push("水")
    if(item.thursday) days.push("木")
    if(item.friday) days.push("金")
    if(item.saturday) days.push("土")
    return days.join(",")
  }


  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">案件 削除</h2>
                <Breadcrumbs pages={[new LinkProps("案件一覧","/project"),new LinkProps("案件削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">案件削除</p>
                {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
								<div className="input_wrap">
					
                <table className="input_table col12 login_tbl">
											<tbody>
												<tr>
													<th className="tl">クライアント名</th>
													<td>{project_object ? project_object.client.name : null}</td>
												</tr>
												<tr>
													<th className="tl">案件名</th>
													<td>{project_object ? project_object.name : null}</td>
												</tr>
												<tr>
													<th className="tl">区分</th>
													<td>{project_object && project_object.project_type ? Utile.getLabelByValue(config.get("PROJECT_TYPE"),Number(project_object.project_type)):null} </td>
												</tr>
												<tr>
													<th className="tl">種類</th>
													<td>{project_object && project_object.category ? Utile.getLabelByValue(config.get("PROJECT_CATEGORY"),Number(project_object.category)):null}</td>
												</tr>
												<tr>
													<th className="tl">依頼先会社名</th>
													<td>{item ? item.driver.partner.name : null}</td>
												</tr>
												<tr>
													<th className="tl">担当ドライバー</th>
													<td>{item ? item.driver.name : null}</td>
												</tr>
												<tr>
													<th className="tl">案件開始日</th>
													<td>{item ? item.start_date : null}</td>
												</tr>
												<tr>
													<th className="tl">稼働曜日</th>
													<td>{item ? getWorkingDayStying(): null}</td>
												</tr>
												<tr>
													<th className="tl">稼働条件</th>
													<td>{item && item.condition ? Utile.getLabelByValue(config.get("PROJECT_DRIVER_WORKING_TYPE"),Number(item.condition)):null}</td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>{item ? item.note : null}</td>
												</tr>
											</tbody>
										</table>
								</div>
							</div>
              <div className="button_wrap center">
								 <button className="red" type="button" onClick={btnClickHandler}>削除する</button>
               </div>
            </div> 
				</div>  
      
		</div>

  )
}

export default ProjectDriverDelete
