import ProfileProps from './ProfileProps';
const props = extendValues => class extends ProfileProps {};
class Profile extends props({}) {

  isFinanceManager(){
    if(this.user_type === "finance") return true
    return false
  }

  

}
export default Profile
