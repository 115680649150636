import React, { useEffect  } from 'react'
import { Header } from '../../stories/components/header/Header';
import { SideMenu } from '../../stories/components/sidemenu/SideMenu';
import useAuthState from '../../stories/auth/useAuthState';
import useProfileState from '../../stories/profile/useProfileState';

function PartnerDashboardApp(props:PropsValue) {

  const {token,  getToken} = useAuthState()
  const {profile,  getProfile} = useProfileState()

  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(token){
      getProfile(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])

  
  return(
    <div>
      <Header  noauth={true} profile={profile}/>
      <div className="main_row row">
        <SideMenu current_page={"dashboard"} user_type={"partner"}/>
        <main className="partner">
          
        </main>
      </div>
    </div>
  )
}

export default PartnerDashboardApp

