import React, {useState, useEffect  } from 'react'
import { useSearchParams } from "react-router-dom";

import { PageApp } from '../../stories/PageApp'
import UserList from "./UserList"
import useUserState from './useUserState';

function UserListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?  searchParams.get("page") : 1 ;

  const [token, setToken] = useState(null)
  const [list, setList] = useState([])
  const [profile, setProfile] = useState(null)
  const [total_number, setTotalNumber] = useState(1)
  const {user_list, list_updated_at, user_total_number, getUserList} = useUserState()


  useEffect(() => {
    if(user_total_number > 1){
      setTotalNumber(user_total_number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user_total_number])


  useEffect(() => {
    
    setList(user_list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])

  useEffect(() => {

    if(token){
      //GET LIST
      getUserList(token,page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  const searchHandler = (e) => {
    getUserList(token,page, e)
  }

  return(
    <div id="home">
      <PageApp current_page="user" content={profile ? <UserList  profile={profile} users={list} total_number={total_number} current_page={page}  search={searchHandler} /> : null} authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler} />
    </div>
     
  )
}

export default UserListApp

