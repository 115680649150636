import React, {useEffect, useState} from 'react';




interface PreviewBoxProps {

  file:Any,

}

/**
 * Primary UI component for user interaction
 */
export const PreviewBox = ({
  file,
  ...props
}: PreviewBoxProps) => { 

  const [media, setMedia] = useState(null)
  const  reader = new FileReader();
  
  useEffect(() => {

    reader.onload = (e) => {
      
      setMedia(<img src={e.target.result} alt=""/>);
    };
    
    reader.readAsDataURL(file)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

 
  


  return (
    <div >{media}</div>
    );
};

