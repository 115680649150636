
import ProjectDriverProps from './ProjectDriverProps'
import { isBefore} from 'date-fns';

const props = extendValues => class extends ProjectDriverProps {};
class ProjectDriver extends props({}) {

  is_working(){
    if(!this.end_date ) return true 
    if(!this.start_date ) return false 

    const end_date = new Date(this.end_date)    
    if(isBefore( end_date,  new Date())) return false
    return true
  }

}
export default ProjectDriver
