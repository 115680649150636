import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

import PartnerDetailOverlay from '../partners/PartnerDetailOverlay';
import DriverDetailOverlay from '../drivers/DriverDetailOverlay';
import { SearchBoxMonthlyPayment } from '../../stories/components/searchbox/SearchBoxMonthlyPayment';
import useReportState from '../report/useReportState';
import useMonthlyPaymentState from './useMonthlyPaymentState';
import {endOfMonth, addMonths, parseISO,format } from 'date-fns';
import ReportObject from '../report/ReportObject';


function MonthlyPaymentList(props:PropsValue) {


  const [detail, setDetail] = useState(null)
  const [list, setList] = useState(null)
  const [is_partner, setIsPartner] = useState(false)

  const {monthly_list_updated_at, monthly_payment_list, getMontylyPaynmentList} = useMonthlyPaymentState()
  const {list_updated_at, report_list, getReportListByPartners} = useReportState()
  const [message, setMessage] = useState(null)

  useEffect(() => {
    
    if(props.partners && props.partners.length > 0 && monthly_payment_list && report_list ){ 
      const _list = props.partners.map(item => {
        const has_report = report_list.filter(repo => {
          const report = new ReportObject(repo)
          return repo.driver && Number(repo.driver.partner_id) === Number(item.id) && report.isInTargetMonth(props.year, props.month)
        })

        
        if(has_report.length > 0){
          return createTd(item)
        }else{
          return null
        }
       
      })
      setList(
        _list
      )
    } else  if(props.drivers && props.drivers.length > 0 && monthly_payment_list && report_list ){ 
   
      const _list = props.drivers.map(item => {
        const has_report = report_list.filter(repo => {
          const report = new ReportObject(repo)
          return repo.driver && Number(repo.driver.id) === Number(item.id) && report.isInTargetMonth(props.year, props.month)
        })
        
        if(has_report.length > 0){
          return createTd(item)
        }else{
          return null
        }
      })
      setList(
        _list
      )
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[monthly_list_updated_at, list_updated_at, message])




  useEffect(() => {
    if(Number(props.total_number) === 0){ 
      setList(<tr><td colSpan={7}>該当する支払いはありません。</td></tr>)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.total_number])



  useEffect(() => {
    console.log(props.partners)
    if( props.partners || props.drivers){ 

      const ids = []
      if(props.partners && props.partners.length !== 0){
        props.partners.map(item => {
          return ids.push(item.id)
        })
      }else if(props.drivers  && props.drivers.length !== 0){
        ids.push(props.drivers[0].partner.id)
      }
     
      const start_date = new Date(props.year, props.month -1, 1)
      const end_date = endOfMonth(addMonths(start_date,1))
      getReportListByPartners(props.token,format(start_date,"yyyy-MM-dd"),format(end_date,"yyyy-MM-dd"),ids )
      getMontylyPaynmentList(props.token,null,props.year, props.month, ids.join(","))
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ props.partners,props.year, props.month, props.drivers ])



  useEffect(() => {
    setIsPartner(props.profile && props.profile.user_type === "partner" ? true : false)
  },[props.profile])
  


  const btnClickHandler = (e) =>{
    props.search(e)
  }


  const clearHandler = () =>{
    props.deleteSearchParamsHandler()
    window.location.href = "/monthly_payment"
  }

  const findLatestPayment = (item) =>{
    var target = []
    if(props.inhouse === "1"){
      if(item.partner){
        target = monthly_payment_list.filter(mnp => mnp.partner_id === item.partner.id)
      }
     
    }else{
      target = monthly_payment_list.filter(mnp => mnp.partner_id === item.id)
    }
    
    if(target.length > 0) return target[0]
    return null
  } 

  const copy = (url, id) => {
    navigator.clipboard.writeText(config.get("BASE_PATH") + url).then(  () => {}, () => {});
    setMessage(id)
  }



  const createTd = (item) => {
    const payment_report = findLatestPayment(item)
    return(
      <tr key={"item_" + item.id}>
        <td className={is_partner ? null : "link"}  onClick={()=> displayPartner(item)}>{ item.name}</td>
        <td>{props.year}/{props.month}</td>
        <td>{payment_report? format(parseISO(payment_report.created_at),"yyyy年M月d日 HH:mm") : null}</td>
        {is_partner ? null :
        <td>
          <div className="buttons">
						{payment_report && payment_report.confirmed_by_partner !== null ? null :
						<a href={getLink(item) + "/e"} className="a icon_button"><i className="fa-solid fa-pen"></i></a>
            }
            </div>
        </td>
          }
        <td>
          {
            payment_report && payment_report.confirmed_at ? 
            is_partner ?  
            <a href={"/monthly_payment/" + item.id + "/" + props.year +"/" + props.month + "/partner"} >支払い明細確認</a>　:
						<div className="buttons"><div className="a icon_button link" onClick={() => copy(getLink(item) + "/partner", item.id)}>■</div></div>
          :null}
          {Number(message) === item.id ? <span className="copyied">コピーしました。</span> : null}
					
  
        </td>
        <td>{payment_report && payment_report.returned_at ? format(parseISO(payment_report.returned_at),"yyyy年M月d日 HH:mm") : null}</td>
        <td>{payment_report && payment_report.confirmed_by_partner ? format(parseISO(payment_report.confirmed_by_partner),"yyyy年M月d日 HH:mm") : null}</td>
      </tr>
    )
  }


  const closeHandler = () => {
    setDetail(null)
  }

  const displayPartner = (target) => {
    
   if(!is_partner)  {
    if(props.inhouse === "1"){
      setDetail(<DriverDetailOverlay  token={props.token}  projects={props.project_list}  profile={props.profile}  driver={target} is_edit={false}  closeHandler={closeHandler}  />)
    }else{
      setDetail(<PartnerDetailOverlay token={props.token}  profile={props.profile} partner={target} is_edit={false} closeHandler={closeHandler} />)
    }
    
   }
  }

  const moveToList = (type ) => {
    if(type === "regular" && props.inhouse === "1"){
      window.location.href = "/monthly_payment?inhouse=0"
    }else if (type === "inhouse"  && props.inhouse !== "1"){
      window.location.href = "/monthly_payment?inhouse=1"
    }
  }

  const getLink = (item) => {
    console.log(item)
    if(props.inhouse === "1"){
      if(item.partner)
        return "/monthly_payment/1/" + props.year +"/" + props.month + "/driver/" + item.id
      else return "#"
    }else{  
      return "/monthly_payment/" + item.id + "/" + props.year +"/" + props.month 
    }
    
    
  }
  return(
    <div className="inner">

			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">支払明細書一覧</h2>
                <Breadcrumbs pages={[new LinkProps("支払明細書一覧","#")]} />
						</div>
           
            <SearchBoxMonthlyPayment is_partner={ is_partner}  year={props.year} month={props.month } btnClickHandler={btnClickHandler} clearHandler={clearHandler} />
            
            <div className="tab-box">
              <div className={ props.inhouse === "1"  ? "tab-item selected" : "tab-item" }  onClick={() => moveToList("inhouse")}>自社</div>
              <div className={props.inhouse !== "1"  ? "tab-item selected" : "tab-item" } onClick={() => moveToList("regular")} >協力会社</div> 
            </div>
            
            <table className="datatable dayreport_tbl">
							<thead>
                <tr>
									<th>{ props.inhouse === "1"  ? "ドライバー名" : "依頼先会社名" }  </th>
                  <th>年月</th>
                  <th>最終更新日</th>
                  {is_partner ? null :
									  <th>支払明細確認</th>
                  }
                  <th>{is_partner ? "確認" : "リンクコピー"}</th>
                  <th>差戻し</th>
                  <th>承認</th>
								</tr>
							</thead>
							<tbody>
              {list}
							</tbody>
						</table>
            {detail}
				</div>
		</div>

  )
}

export default MonthlyPaymentList


