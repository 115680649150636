import React, {  } from 'react'




function ReportMobSuccessApp(props:PropsValue) {


  
  
  return(
    <div >
      <header>
					<div className="logo">
          軽貨物月報システム
					</div>
	  	</header>
      <div className="day-report-sp">
        日報を登録しました。
      </div>
    </div>
     
  )
}

export default ReportMobSuccessApp

