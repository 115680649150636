import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { Pageing } from '../../stories/components/pageing/Pageing';
import UserProps from './UserProps';
import './user.css';
import { SearchBoxUser } from '../../stories/components/searchbox/SearchBoxUser';
import UserDetailOverlay from './UserDetailOverlay';

function UserList(props:PropsValue) {

  const [list, setList] = useState(null)
  const [total_number, SetTotalNumber] = useState(1)
  const [detail, setDetail] = useState(null)


  const closeHandler = () => {
    setDetail(null)
  }


  useEffect(() => {
    setList(
      props.users.map(item => {
        return(
          <tr key={"item_" + item.id}>
          <td>{item ? item.id : null}</td>
          <td>{item ? config.get("USER_TYPE_" + item.user_type) : null}</td>
          <td>{item ? item.name : null}</td>
          <td>{item ? item.tel : null}</td>
          <td>{item ? item.username : null}</td>
          <td>
            <div className="buttons">
              <div className="textbutton" onClick={() => displayDetailInfo(item)}>詳細確認</div>
              
            </div>
          </td>
        </tr>
        )
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.users])

  
  useEffect(() => {
    SetTotalNumber(props.total_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.total_number])
  

  
  const displayDetailInfo = (item) => {
    setDetail(<UserDetailOverlay token={props.token} profile={props.profile} user={item} is_edit={false} closeHandler={closeHandler} />)
  }

  const btnClickHandler = (e) =>{
    props.search(e)
  }

  const clearHandler = () => {
    window.location.href = "/user"
  }

  return(
    <div className="inner">

			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">ユーザー一覧</h2>
                <Breadcrumbs pages={[new LinkProps("ユーザー一覧","/user")]} />
						</div>
            <a href="/user/new?new=1" className="button red">新規ユーザー登録</a>
              <SearchBoxUser  data={new UserProps()} btnClickHandler={btnClickHandler} clearHandler={clearHandler}/>
            <table className="datatable">
							
							<thead>
								<tr>
									<th>No.</th>
                  <th>種別</th>
									<th>ユーザー名</th>
									<th>電話番号</th>
                  <th>メール</th>
									<th>詳細確認</th>
                  
								</tr>
							</thead>
							<tbody>
              {list}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan="99">
                    <Pageing total_number={total_number} current_page={props.page}  />
									</td>
								</tr>
							</tfoot>
						</table>
				</div>
        {detail}
		</div>

  )
}

export default UserList


