
import Report from "../Report"

class ReportSpotCharter  extends Report{


  delivery_unit = "持出個数"
  return_unit = "持戻個数"
  load_unit ?= "便数（往復回数）"

  area = undefined
  delivery_location=  "納品先"
  received_gender = "受領者（性別）"
  received_name  = "受領者（名前）"

  detail=  "案件名"
  driver_name = "ドライバー名"

  meter_start=  undefined
  meter_end =  undefined


  compleated_unit =  undefined
  nekopos_unit=  undefined
  dm_unit =  undefined
  normal_unit =  undefined
  night_unit =  undefined

  large_unit =  undefined
  



  spot_type = 0

}

export default ReportSpotCharter

