import { Record } from 'immutable'


const BankInfoProps = Record({
  address_1:null,
  address_2:null,
  tel:null,
  fax:null
});
export default BankInfoProps;

