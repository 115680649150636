import { Record } from 'immutable'

const MontylyPaymentParmProps = Record({
  highway_cost:0,
  other_cost:0,
  parking_cost:0,
  energy_money:0,
  title_1:"",
  title_2:"",
  title_3:"",
  title_4:"",
  title_5:"",
  title_6:"",
  title_7:""
});
export default MontylyPaymentParmProps;
