import React, { useEffect, useState } from 'react'

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { Pageing } from '../../stories/components/pageing/Pageing';

import { SearchBox } from '../../stories/components/searchbox/SearchBox';
import ClientSearchBox from '../client/ClientSearchBox';
import PartnerDetailOverlay from '../partners/PartnerDetailOverlay';


function MonthlyReceiptList(props:PropsValue) {
  const [search_form ,setSearchForm] = useState({})
  const [sarchHtml ,setSearchHtml] = useState(null)
  const [detail, setDetail] = useState(null)
  const [list, setList] = useState(null)
  const [total_number, SetTotalNumber] = useState(1)

  const onChangHandler = (e) => {
    const target = e.target 
    setSearchForm(search_form.set(target.name,target.value ))
  }



  useEffect(() => {
    if(props.list){
   
     
      const _list = props.list.map(item => {
        return createTd(item)
      })
     
      setList(
        _list
      )
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.list])




  useEffect(() => {

    if(props.token){

      setSearchHtml(
        <div>
        <div className="input_wrap">
            <label className="">
              <span className="label_name">ステータス</span>
              <span className="select_wrap">
                <select name="user_type" onChange={onChangHandler} >
                  <option value="all">全て</option>
                  <option value="1">契約中</option>
                  <option value="0">未契約</option>
                </select>
              </span>
            </label>
    
            <ClientSearchBox token={props.token}/>
    
            <label className="">
              <span className="label_name">案件名</span>
              <input type="text" name="name" onChange={onChangHandler} />
            </label>
          </div>
      </div>
      )
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.token])
  
 
  
  useEffect(() => {
    SetTotalNumber(props.total_number)
  },[props.total_number])
  
  
  const btnClickHandler = () =>{
    props.search(search_form)
  }

  const clearHandler = () =>{
    window.location.href = "/monthly_receipt"
  }

  const closeHandler = () => {
    setDetail(null)
  }

  const displayPartner = (partner) => {
    setDetail(<PartnerDetailOverlay token={props.token}  profile={props.profile} partner={partner} is_edit={false} closeHandler={closeHandler} />)
  }


  const createTd = (item) => {

    return(
      <tr key={"item_" + item.id}>
        <td>{item && item.project && item.project.client ? item.project.client.name : null }</td>
        <td>{item && item.project ? item.project.name : null }</td>
        <td  className="link" onClick={()=> displayPartner(item.driver.partner)}>{item && item.driver && item.driver.partner ? item.driver.partner.name : null }</td>
        <td>{item && item.driver  ? item.driver.name : null }</td>
        <td>{item ? item.year : null}/{item ? item.month : null}</td>
        <td>
          <div class="buttons">
						<div  className="a icon_button">■</div>
						<a href={"/project/" + item.project.uid + "/monthly_receipt/e/" +item.id +"/" + item.driver.id +"/" +item.year +"/" + item.month} className="a icon_button"><i className="fa-solid fa-pen"></i></a>
					</div>
        </td>
      </tr>
    )
  }
  

  return(
    <div className="inner">

			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">立替金明細一覧</h2>
                <Breadcrumbs pages={[new LinkProps("立替金明細一覧","#")]} />
						</div>
            
            <SearchBox content={sarchHtml} data={search_form} btnClickHandler={btnClickHandler} clearHandler={clearHandler} />
           
            <table className="datatable dayreport_tbl">
							
							<thead>
                <tr>
							
									<th>クライアント名</th>
									<th>案件名	</th>
									<th>依頼先会社名	</th>
									<th>ドライバー名	</th>
                  <th>年月</th>
									<th>DL / 編集</th>
                
								</tr>
							</thead>
							<tbody>
              {list}
							</tbody>
							<tfoot>
								<tr>
									<td colspan="99">
                    <Pageing total_number={total_number} current_page={props.page}  />
									</td>
								</tr>
							</tfoot>
						</table>
            {detail}
				</div>
		</div>

  )
}

export default MonthlyReceiptList


