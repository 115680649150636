
import Project from '../Project'
import YamatoProject from "./YamatoProject"
import YuseiProject from "./YuseiProject"

const CreateProject = {

  create: function(props){
 
    if(props.category === 4) return new YamatoProject(props)
    if(props.category === 5) return new YuseiProject(props)
    
    return new Project(props)
  },
  

  
}

export default CreateProject
