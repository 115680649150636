import React, {useState, useEffect  } from 'react'
import { PageApp } from '../../stories/PageApp'
import ClientConfirm from "./ClientConfirm"
import useClientState from './useClientState';

function ClientConfirmApp(props:PropsValue) {

  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {client, client_error, client_created, loadFromCookie, createClient, removeCookie} = useClientState()


  useEffect(() => {
    if(client){
      setItem(client)
    }
  },[client])


  useEffect(() => {
    if(token){
      loadFromCookie()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(client_created && client_created.id){
      removeCookie()
      const is_update = item.id ? "?updated=true" : ""
      window.location.href = "/client/success" + is_update
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client_created])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    createClient(token, _item)
  }
  
  return(
    <div id="home">
      <PageApp current_page="client" content={<ClientConfirm   token={token} client={item} error={client_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ClientConfirmApp

