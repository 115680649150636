import React, {  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import PartnerCreateSuccess from "./PartnerCreateSuccess"
import PartnerDeleteSuccess from './PartnerDeleteSuccess';

function PartnerSuccessApp(props:PropsValue) {
  const { id } = useParams();
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      //setToken(e.token)
    }
  }

  return(
    <div id="home">
      <PageApp current_page="partner" content={id === "deleted" ?  <PartnerDeleteSuccess /> :<PartnerCreateSuccess />} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default PartnerSuccessApp

