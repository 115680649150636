import { Record } from 'immutable'
const ProjectDriverFeeProps = Record({
  unit_price:null,
  unit_yamato_normal:null,
  unit_yamato_nekopos:null,
  unit_yamato_dm:null,
  unit_yusei_normal:null,
  unit_yusei_big:null,
  unit_yusei_collect:null,
  unit_yusei_yakan:null,
  over_time_unit_price:null,
  over_distance_unit_price:null,
});
export default ProjectDriverFeeProps;
