import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../../stories/PageApp'
import ProjectDriverConfirm from "./ProjectDriverConfirm"
import useProjectDriverState from './useProjectDriverState';

function ProjectDriverConfirmApp(props:PropsValue) {
  const { project_id } = useParams();
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {project_driver, project_driver_error, project_driver_created, loadFromCookie, createProjectDriver, removeCookie} = useProjectDriverState()


  useEffect(() => {

    if(project_driver){
      setItem(project_driver)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_driver])


  useEffect(() => {

    if(token){
      loadFromCookie()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
   
    if(project_driver_created && project_driver_created.id){
      removeCookie()
      const is_update = item.id ? "?updated=true" : ""
      window.location.href = "/project/" + project_id + "/driver/success" + is_update
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_driver_created,project_id])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    createProjectDriver(token, project_id, _item)
  }
  
  return(
    <div id="home">
      <PageApp content={<ProjectDriverConfirm  token={token} project_driver={item} project_id={project_id} error={project_driver_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectDriverConfirmApp

