import React, {ReactNode,useEffect} from 'react';


import './pageapp.css';
import useAuthState from './auth/useAuthState';


interface NonUserPageAppProps {

   
  /**
   * content
   */
  content:ReactNode
  authChangeHandler: (e:any) => void
}

/**
 * Primary UI component for user interaction
 */
export const NonUserPageApp = ({

  content,
  authChangeHandler,
  ...props
}: NonUserPageAppProps) => {

  const {token, getToken} = useAuthState()

  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(token){
      authChangeHandler({token:token})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])

  

  return (
    <div>
      <div className="main_row row">
        <main>{content}</main>
      </div>
    </div>
  );
};

