import React, {  } from 'react'

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

function PartnerCreateSuccess(props:PropsValue) {




  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">依頼先会社削除</h2>
                <Breadcrumbs pages={[new LinkProps("依頼先会社一覧","/partner"),new LinkProps("依頼先会社削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">依頼先会社削除 完了</p>
                <p>依頼先会社の削除が完了しました。</p>
									
							</div>
						</div> 
				</div>
		</div>

  )
}

export default PartnerCreateSuccess

