import { Record } from 'immutable'

const MontylyPaymentProps = Record({
  id:null,
  content:null,
  comments:[],
  year:null,
  month:null,
  report_type:1,
  is_confirm:0,
  confirmed_by_partner:null,
  created_at:null,
  returned_at:null,

});
export default MontylyPaymentProps;
