
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action';
const UserAction = {


  getList(token,item, offset,  callback, errorHandler){
    const data = new FormData() 
    var limit = 10
    data.append('offset', offset)
    if(item && item.user_type && item.user_type !== "all")
      data.append('user_type', item.user_type)
    if(item && item.name && item.name !== "")
      data.append('name', item.name)
    if(item && item.tel && item.tel !== "")
      data.append('tel', item.tel)

    if(item) 
      limit = 100
    data.append('limit', limit)

    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_USER") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler, {limit:limit})
  },


  getDetail(token, id,  callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_USER")  + id  , token, "GET", {}, null,  callback, errorHandler)
  },

  create( token, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_USER") + item.id  : config.get("API_PATH") + config.get("API_USER")
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },



  delete( token, id,  callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_USER") + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  saveOnCookie(item){
    cookie.save('user_form',item, { path: '/' })
  },

  hasCookie(item, callback, errorHandler){
    const user =  cookie.load("user_form")
    if(user) return true 
    return false
  },

  loadFromCookie(item, callback, errorHandler){
    const user =  cookie.load("user_form")
    if(user) {
      callback({data:user})
    }else{
      errorHandler({"error":"not found"})
    }
  },
  removeCookie(item, callback, errorHandler){
    cookie.remove('user_form', { path: '/' })
  }
 
}

export default UserAction
