import React, {  } from 'react'

function HeaderDefault(props:PropsValue) {

  /**
   * year
   * mnth
   * project
   * project_driver
   * report list
   */

  
  return(
    <div>
       <h3 className="report_ttl">立替金精算書</h3>
        <p className="report_manth">稼働年月：<input type="text" className="in01" value={props.year} />年<input type="text" className="in01" value={props.month} />月</p>
        <div className="report_casename">ハウンドジャパン株式会社　御中</div><br/>
        <div className="report_driver">Dr名：<input type="text" className="in01" value={props.project_driver ? props.project_driver.driver.name : null} /></div>
        <div className="report_period">立替金合計　<span className="yen_mark">&yen;</span><input type="text" className="in01" value={ props.total_price ? props.total_price.toLocaleString() : null} /></div>
		</div>
    
  )
}

export default HeaderDefault

