import { Record} from 'immutable'
import PositionProps from '../../position/PositionProps';

const MemoProps = Record({
  id:undefined,
  category: undefined,
  uniqueid: undefined,
  content: "",
  position: PositionProps()
});
export default MemoProps;
