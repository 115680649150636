import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ClientDelete from "./ClientDelete"
import useClientState from './useClientState';

function ClientDeleteApp(props:PropsValue) {
  const { id } = useParams();
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {client, client_error, client_deleted, getClient, deleteClient} = useClientState()
 
  

  useEffect(() => { 
    if(client){
      setItem(client)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client])


  useEffect(() => {
    if(token){
      getClient(token, id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(client_deleted){
      window.location.href = "/client/success/deleted"
    }
  },[client_deleted])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = () => {
    deleteClient(token, id)
  }
  
  return(
    <div id="home">
      <PageApp current_page="client" content={<ClientDelete client={item} error={client_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ClientDeleteApp

