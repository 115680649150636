import React, {  } from 'react'

function HeaderSpot(props:PropsValue) {

  /**
   * year
   * mnth
   * project
   * project_driver
   * report list
   */

  
  return(
    <div>
       <h3 className="report_ttl spot"> スポット月報<br />（{props.project ? props.project.name : null}）</h3>
		</div>
    
  )
}

export default HeaderSpot

