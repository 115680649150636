import { useState } from 'react';
import PartnerAction from './PartnerAction';
import config from 'react-global-configuration';

function usePartnerState() {

  const [limit, setLimit] = useState(config.get("SEARCH_LIMIT"))
  const [error, setError] = useState(null)
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState([])
  const [total_number, setTotalNumber] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)

  const listLoadeddHandler = (data) => {
    setList(data.data)
    setListUpdatedAt(new Date())
    setTotalNumber(data.headers.get("total-number"))
  }
  const loadedHandler = (data) => {
    setItem(data.data)
  }
  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }


  const errorHandler = (data) => {
    setError(data)
  }

  const hasCookie = () => {
    return PartnerAction.hasCookie()
  }

  const saveOnCookie = (item) => {
    PartnerAction.saveOnCookie(item)
  }

  const loadFromCookie = (item) => {
    PartnerAction.loadFromCookie(item, loadedHandler, errorHandler)
  }

  const removeCookie = () => {
    PartnerAction.removeCookie()
  }
  
  const getList = (token, page, item = null) => {
    const offset = (page - 1)  * limit 
    PartnerAction.getList(token, offset,limit,item, listLoadeddHandler, errorHandler)
  }


  const create = (token, item) => {
    PartnerAction.create(token, item, createdHandler, errorHandler)
  }

  const getDetail = (token, id) => {
    PartnerAction.getDetail(token, id, loadedHandler, errorHandler)
  }
  const getDetailByUserId = (token, user_id) => {
    PartnerAction.getDetailByUserId(token, user_id,loadedHandler, errorHandler)
  }

  const deleteItem = (token, id) => {
    PartnerAction.delete(token, id, createdHandler, errorHandler)
  }



  return { partner:item, partner_list:list, partner_updated_at:list_updated_at, partner_error:error, partner_deleted:created, partner_created:created, partner_total_number:total_number, partner_search_limit:limit, 
    getPartnerList:getList, getPartner:getDetail,getPartnerByUserId:getDetailByUserId, saveOnCookie:saveOnCookie, loadFromCookie:loadFromCookie, hasCookie:hasCookie, removeCookie:removeCookie, createPartner:create, deletePartner:deleteItem,
    setPartnerSerachLimit:setLimit};
}

export default usePartnerState