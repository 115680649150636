import React, {  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ClientCreateSuccess from "./ClientCreateSuccess"
import ClientDeleteSuccess from './ClientDeleteSuccess';

function ClientSuccessApp(props:PropsValue) {
  const { id } = useParams();
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      //setToken(e.token)
    }
  }

  return(
    <div id="home">
      <PageApp current_page="client" content={id === "deleted" ?  <ClientDeleteSuccess /> :<ClientCreateSuccess />} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ClientSuccessApp

