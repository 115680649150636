
import Report from "../Report"

class ReportYamato  extends Report{


  area = "ヤマト稼働コース"
  
  nekopos_load_unit = "ネコポス持出"
  dm_load_unit  = "DM持出"
  normal_load_unit = "一般持出個数"
  fee ?= "本日の運賃"
  code = "使用コード"

  delivery_unit  = undefined
  requested_unit = undefined
  load_unit  = undefined
  load_location = undefined
  delivery_location  = undefined
  return_unit = undefined
  return_requested_unit = undefined
  compleated_unit = undefined
  detail =undefined

  meter_start=undefined
  meter_end =undefined


  night_unit=undefined

  large_unit=undefined
  
  waiting_hour =undefined

  received_gender=undefined
  received_name =undefined

  cost = false

}

export default ReportYamato

