import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';

function UserDetailItem(props:PropsValue) {
 

  const [item, setItem] = useState(null)

  
  useEffect(() => {
    setItem(props.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.user])
  

  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value.trim())
    setItem(new_item)
    if(props.changeHandler){
      props.changeHandler(new_item)
    }
   
  } 


  return(
    <table className="input_table col12 login_tbl">
    <tbody>
      <tr>
        <th className="tl">ユーザー種別 *</th>
        <td>
          {
            props.is_edit ?  
              <span>
                <label className="radio"><input autocomplete="off" type="radio" name="user_type" checked={item && item.user_type === "finance" ? "checked" : false}   value="finance" onChange={changeHandler} /><span></span><b>{config.get("USER_TYPE_finance")}</b></label>
                <label className="radio"><input autocomplete="off" type="radio" name="user_type"  checked={item && item.user_type === "manager" ? "checked" : false}  value="manager" onChange={changeHandler}/><span></span><b>{config.get("USER_TYPE_manager")}</b></label>
              </span>
            :
               item ? item.user_type === "finance" ? "経理" : "マネージャー" :  null
            }
          
        </td>
      </tr>
      <tr>
        <th className="tl">ユーザー名 *</th>
        <td>
        {
            props.is_edit ?  
              <input autocomplete="off" type="text" className="col9" name="name" value={item ? item.name : undefined} placeholder="犬飼　太郎" onChange={changeHandler}/>
            :item ? item.name : null
        }
        </td>
      </tr>
      <tr>
        <th className="tl">フリガナ *</th>
        <td>
          {
            props.is_edit ?  
          <input autocomplete="off" type="text" className="col9" name="furigana" value={item ? item.furigana : undefined} placeholder="イヌカイ　タロウ" onChange={changeHandler}/>
          :item ? item.furigana : null
          }
          </td>
      </tr>
      <tr>
        <th className="tl">メールアドレス *</th>
        <td>
        {
            props.is_edit ?  
            <input autocomplete="off" type="text" className="col9" name="username" value={item ? item.username : undefined} placeholder="abc@hound-japan.com"  onChange={changeHandler}/>
            :item ? item.username : null
          }
        </td>
      </tr>

      <tr>
			  <th className="tl">パスワード *</th>
				  <td>
            {
             props.is_edit ? 
              <input autocomplete="off"  type="text" className="col9" placeholder="********" name="password" value={item ? item.password : undefined}  onChange={changeHandler} />
              :"******"
            }
            </td>
				</tr>
      <tr>
        <th className="tl">電話番号 *</th>
        <td>
        {
            props.is_edit ?  <input autocomplete="off" type="text" className="col9" name="tel" value={item ? item.tel : undefined} placeholder="09011112222"  onChange={changeHandler}/>
            :item ? item.tel : null
        }
        </td>
      </tr>
      <tr>
        <th className="tl">備考</th>
        <td>
          {
            props.is_edit ?  
            <textarea type="text" className="col12" rows="5" name="note" value={item ? item.note : undefined} onChange={changeHandler}/>
            :item ? item.note : null
          }
          </td>
      </tr>
    </tbody>
  </table>
    
  )
}

export default UserDetailItem

