
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import { sha1 } from 'js-sha1';
import Action from '../../action/Action';
import format from 'date-fns/format';

const LoginAction = {


  login( mail, callback, errorHandler){
    const today = new Date()
    const password = sha1(mail[0] + format(today,"yyyyMMdd"))

    const data = new FormData() 
    data.append('grant_type', "password")
    data.append('username', mail)
    data.append('password', password)
    data.append('scope', "finance")
    
  
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa(config.get("CLIENT_ID") + ":" + config.get("CLIENT_SECRET")));

    Action.fetchJson( config.get("API_PATH") + config.get("API_AUTH") , "POST", headers, data,  callback, errorHandler)

  },

  loginByPassword( mail, password, callback, errorHandler){

    const data = new FormData() 
    data.append('grant_type', "password")
    data.append('username', mail)
    data.append('password', password)
    data.append('scope', "finance")
    
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa(config.get("CLIENT_ID") + ":" + config.get("CLIENT_SECRET")));

    Action.fetchJson( config.get("API_PATH") + config.get("API_AUTH") , "POST", headers, data,  callback, errorHandler)

  },

  loginPartner( mail, password, callback, errorHandler){

    const data = new FormData() 
    data.append('grant_type', "password")
    data.append('username', mail)
    data.append('password', password)
    data.append('scope', "partner")
    
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa(config.get("CLIENT_ID") + ":" + config.get("CLIENT_SECRET")));

    Action.fetchJson( config.get("API_PATH") + config.get("API_AUTH") , "POST", headers, data,  callback, errorHandler)

  },

  hasToken(){
    const token =  cookie.load("token")
    if(token) return true 
    return false
  },

  logout(){
    cookie.remove('token', { path: '/' })
    cookie.remove('refresh_token', { path: '/' })
  }
   
 
}

export default LoginAction
