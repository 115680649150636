import React, {useState,useEffect} from 'react';
import { Record } from 'immutable'

interface SearchBoxMonthlyPaymentProps {
   /**  
   * content
   */
   token:String,

   /**
    * Form 
    */
   data:?Record,

   /**
    * SearchBtnClickHandler
    */
   btnClickHandler:Function,

   clearHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const SearchBoxMonthlyPayment = ({
  //current_page,
  token,
  content,
  data,
  btnClickHandler,
  clearHandler,
  ...props
}: SearchBoxMonthlyPaymentProps) => { 
  const today = new Date()
  const [form_css, setFormCss] = useState(null)
  const [form_active, setFormActive] = useState("active")
  const [year , setYear] = useState(today.getFullYear())
  const [month , setMonth] = useState(today.getMonth() + 1)
  //const [item, setItem]= useState(Record({year:today.getFullYear(), month:(today.getMonth() + 1)}))
  const [year_option, setYearOption] = useState([])
  const [month_option, setMonthOption] = useState([])

  

  useEffect(() => {
    if(props.year && props.month){
      const duration = today.getFullYear() - 2022
      const _year_option = new Array(duration).fill(0).map((item , index ) => {
        return <option value={2023 + index} selected={(index + 2023) === props.year ? true : false}>{2023 + index}年</option>
      })
      _year_option.unshift(<option value={0}>選択してください</option>)
      const _month_option = new Array(12).fill(0).map((item , index ) => {
        return <option value={index + 1} selected={index + 1 === props.month ? true : false}>{index + 1}月</option>
      })
      _month_option.unshift(<option value={0}>選択してください</option>)
      setYear(props.year)
      setMonth(props.month)
      setYearOption(_year_option)
      setMonthOption(_month_option)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.year, props.month])



  const toggleSearch = () => {
    if(form_css){
      setFormCss(null)
      setFormActive("active")
    }else{
      setFormCss("search-hidden")
      setFormActive(null)
    }
  }


  
  const onChangHandler = (e) => {
    const target = e.target 
    if(target.name === "year"){
      setYear(target.value)
    }else if(target.name === "month"){
      setMonth(target.value)
    }
  
    //setItem(new_item)
  }

  const clickHandler = () => {
    btnClickHandler({year:year, month:month})
  }



  const kedownHandler = (e) => {
    if (e.key === 'Enter') {
      clickHandler()
    }
  }

  
  return (
    <div className="container more_search">
    <div className="column">
    <p id="more_search_ttl" className={form_active} onClick={toggleSearch}>絞り込み検索</p>
    <form className={form_css} onKeyDown={kedownHandler}>
      <div>
        <div >
        <div>
          <div className="input_wrap">
              <label className="">
              
                <span className="select_wrap">
                  <select name="year" onChange={onChangHandler} >
                    {
                      year_option
                    }
                  </select>
                </span>
              </label>

              <label className="">
              
                <span className="select_wrap">
                  <select name="month" onChange={onChangHandler} >
                    {
                      month_option
                    }
                  </select>
                </span>
              </label>

             
            </div>
        </div>

      </div>
          <div className="input_wrap"></div>
          <div className="button_wrap center">
            
            <button type="button" onClick={clearHandler}>クリア</button>
            <input type="button" value="検索" className="button red" onClick={clickHandler} />
          </div>
        </div>
        
      </form>
    </div>
  </div>

  );
};


/**
 * 

           
 */