import React, {useState,useEffect} from 'react';
import Select from 'react-select';
import useDriverState from '../../../components/drivers/useDriverState';
import Utile from '../../../utiles/Utile';

interface DriverSearchBoxProps {

    token:string,
    defaultValue:Number,
  /**
    * changeHandler
    */
   changeHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const DriverSearchBox = ({
  token,
  defaultValue,
  changeHandler,
  ...props
}: DriverSearchBoxProps) => { 

  const [managers, setManagers] = useState(null)
  const {driver_list, getDriverListAll} = useDriverState()
  const [default_value, setDefaultValue] = useState(null)
  useEffect(() => {
    getDriverListAll(token)  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])


  useEffect(() => {
    const _list = [{value:0, label:"選択してください"}]
    setManagers(_list.concat(driver_list.map(item => {
      return {value:item.id, label:item.name}
    }).filter(item => item !== null)))

    const def = driver_list.filter(item => item.id ===  Number(defaultValue) || item.uid === defaultValue) 
    if(def.length > 0){
      const def_val = {value:Number(def[0].id), label:def[0].name}
      setDefaultValue(def_val)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver_list])



  const onChangeHandler = (e) => {

    changeHandler(e)
    setDefaultValue(e)
  }

  return (
    <Select
    placeholder= "選択してください"
    options={managers}
    defaultValue = {default_value}
    value = {default_value}
    noOptionsMessage={()=>"該当するドライバーが見つかりません"}
    onChange={onChangeHandler}
    styles={Utile.getDefaultSelectStyle()}
    />
  );
};

