
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action';

const MonthlyPaymentAction = {


  getList(token,  partner_id, driver_id, year, month,  partner_ids, callback, errorHandler){
    const data = new FormData() 
    if(partner_id) {
      data.append('partner_id', partner_id)
    }
    if(partner_ids) {
      data.append('partner_ids', partner_ids)
    }
    if(driver_id) {
      data.append('driver_id', driver_id)
    }

    data.append('year', year)
    data.append('month', month)

  
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_MONTHLY_PAYMENTS") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },
  

  getDetail(token, id,  callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_MONTHLY_PAYMENTS")  + id  , token, "GET", {}, null,  callback, errorHandler)
  },

  create( token, partner_id, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_MONTHLY_PAYMENTS") + partner_id  + "/" + item.id : config.get("API_PATH") + config.get("API_MONTHLY_PAYMENTS") + partner_id 
    
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },

  confirm( token, id, is_confirm, is_return,  comment,  callback, errorHandler){
    const method =  "PATCH" 
    const url = config.get("API_PATH") + config.get("API_MONTHLY_PAYMENTS") + id + "/confirm"
    const item = {
      confirm:is_confirm,
      return :is_return,
      comment: comment
    }
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },

  delete( token, id, partner_id, callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_MONTHLY_PAYMENTS")  + partner_id  + "/" + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  saveOnCookie(item){
    cookie.save('mp_form',item, { path: '/' })
  },

  loadFromCookie( callback, errorHandler){
    const driver =  cookie.load("mp_form")
   
    if(driver) {
      callback({data:driver})
    }else{
      errorHandler({"error":"not found"})
    }
  },

  hasCookie(item, callback, errorHandler){
    const project_form =  cookie.load("mp_form")
    if(project_form) return true 
    return false
  },

  removeCookie(item, callback, errorHandler){
    cookie.remove('mp_form', { path: '/' })
  },
  
  loadSearchParams( ){
    return   cookie.load("mpayment_search_form")
  },

  saveSearchParams(item){
    cookie.save('mpayment_search_form',item, { path: '/' })
  },

  deleteSearchParams(item){
    cookie.remove('mpayment_search_form', { path: '/' })
  },
 
}

export default MonthlyPaymentAction
