import React, {useState, useEffect  } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import DriverDetail from "./DriverDetail"
import DriverProps from './DriverProps';
import useDriverState from './useDriverState';
import useProjectState from '../project/useProjectState';
import Utile from '../../utiles/Utile';

function DriverDetailApp(props:PropsValue) {
  const { id } = useParams();
  const [token, setToken] = useState(null)
  const [profile, setProfile] = useState(null)
  const [searchParams] = useSearchParams();
  const [has_cookie, setHasCookie] = useState(false)
  const is_new = searchParams.get("new") ?  searchParams.get("new") : 0 ;
  const [item, setItem] = useState(null)
  
// const [projects, setProjects] = useState([])
  const {driver, saveOnCookie,getDriver, hasCookie, loadFromCookie,removeCookie} = useDriverState()
  const {project_list} = useProjectState()
  useEffect(() => {

    if(token){
     
      setHasCookie(hasCookie())
      if(is_new === 0 && hasCookie()){
        loadFromCookie()
      }else{
        if(id === "new"){
          setItem(new DriverProps(driver))
          //setContent(<DriverDetail  token={token} driver={new DriverProps()} updateHandler={updateHandler}/>)
        }else{
          removeCookie()
          getDriver(token, id)
          //const [item, setItem] = useState(null)getProjectList(token,1,[id])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(driver){
      const driver_item = new DriverProps(driver)
      const new_ditem = driver_item.set("car_no", driver_item.car_no ? Utile.toHalfWidth(driver_item.car_no) :null)
      setItem(new_ditem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver])
  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
  }


  const updateHandler = (_item) => {
    
    const driver_item = _item.set("car_no", _item.car_no ? Utile.toHalfWidth(_item.car_no) : _item.car_no)
    saveOnCookie(driver_item)
    window.location.href = "/driver/confirm"
  }

  const saveOnCookieHandler = (item) => {
    const driver_item = item.set("car_no", item.car_no ? Utile.toHalfWidth(item.car_no) : item.car_no)
    saveOnCookie(driver_item)
    window.location.href = "/driver"
  }


  
  return(
    <div id="home">
      <PageApp current_page="driver" content={<DriverDetail  token={token}  has_cookie={has_cookie} profile={profile} projects={project_list} driver={item} loadFromCookie={loadFromCookie} saveOnCookieHandler={saveOnCookieHandler} updateHandler={updateHandler}/>} profileChangeHandler={profileChangeHandler} authChangeHandler={authChangeHandler} /> 
      
    </div>
     
  )
}

export default DriverDetailApp

