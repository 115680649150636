import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { Pageing } from '../../stories/components/pageing/Pageing';

import { SearchBoxMonthlyReport } from '../../stories/components/searchbox/SearchBoxMonthlyReport';
import ClientDetailOverlay from '../client/ClientDetailOverlay';
import ProjectDetailOverlay from '../project/ProjectDetailOverlay';
import PartnerDetailOverlay from '../partners/PartnerDetailOverlay';
import DriverDetailOverlay from '../drivers/DriverDetailOverlay';
import useProjectState from '../project/useProjectState';

function MonthlyList(props:PropsValue) {

  const [detail, setDetail] = useState(null)
  const [selected_driver, setSelectedDriver] = useState(null)
  const [list, setList] = useState(null)
  const [is_partner, setIsPartner] = useState(false)
  const [total_number, SetTotalNumber] = useState(1)
  const [message, setMessage] = useState(null)
  const {project_list, list_updated_at, getProjectList} = useProjectState()


  useEffect(() => {
    
    if(project_list && selected_driver){
      setDetail(<DriverDetailOverlay projects={project_list}  profile={props.profile}  driver={selected_driver} closeHandler={closeHandler}  />)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])


  useEffect(() => {
    if(props.list && props.projects){

      const _list = getTaretList().map(item => {
        return createTd(item)
      })
      //自社ドライバー
     
      const target_driver_list = getTaretDriver()
      const _list_driver = target_driver_list.map(item => {
        return createInternalDriverTd(item)
      })

      setList(
        _list.concat(_list_driver)
      )
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.list, props.projects, props.internal_drivers,props.project_id,message])



  useEffect(() => {

    setIsPartner(props.profile && props.profile.user_type === "partner" ? true : false)
  },[props.profile])

  useEffect(() => {
    SetTotalNumber(props.total_number)
  },[props.total_number])
  


  const btnClickHandler = (e) =>{

    props.search(e)
  }


  const getTaretDriver = () => {
    var target_drivers = props.internal_drivers
    
    if(props.project_id && props.project_id > 0){
      return []
    }
    if(props.driver_id){
      target_drivers = target_drivers.filter(item => item.id === Number(props.driver_id) || item.uid === props.driver_id  )
    }
    if(props.params.client_id){
      target_drivers = target_drivers.filter(item => item.id === Number(props.params.client_id))
    }

    if(props.params.partner_id){
      target_drivers = target_drivers.filter(item => item.id === Number(props.params.partner_id))
    }
    

    //props.driver_id ?  :  props.internal_drivers
    return target_drivers
  }


  const getTaretList = () => {
    var target_list = props.list
    
    if(props.project_id !== null && props.project_id === -1){
        
      target_list = target_list.filter(item => getProject(item) ?  getProject(item).project_type !== 1 : false)
          
    }


    if(props.driver_id){
      target_list = target_list.filter(item => item.driver.id === props.driver_id || item.driver.uid === props.driver_id  )
    }
    /**if(props.params.client_id){
      target_list = target_list.filter(item => item.id === props.client_id)
    }**/
    
    if(props.params.partner_id){
      target_list = target_list.filter(item => Number(item.driver.partner.id) === Number(props.params.partner_id))
    }

    //props.driver_id ?  :  props.internal_drivers
    return target_list
  }

  const clearHandler = () =>{
    props.deleteSearchParams()
    window.location.href = "/monthly_report"
  } 

  const copy = (url, id) => {
    navigator.clipboard.writeText(config.get("BASE_PATH") + url).then(  () => {}, () => {});
    setMessage(id)
  }

  const getProject = (item) => {
    const projects = props.projects.filter((_project) => Number(_project.id) === Number(item.project_id))
    if(projects.length === 0) return null
    return projects[0]
  }

  const createTd = (item) => {
    const projects = props.projects.filter((_project) => Number(_project.id) === Number(item.project_id))
    if(projects.length === 0) return null
    const project = projects[0]
    return(
      project? 
      <tr key={"item_" + item.id}>
        
        <td>{props.year}/{props.month}</td>
        { project.project_type === 1 ?
        <td className="link" onClick={()=> displayClientDetail(project.client)}>{item && project && project.client ? project.client.name : null }</td>
        :<td>-</td>}
        { project.project_type === 1 ?
        <td className="link" onClick={()=> displayProjectDetail(project)}>{item && project ? project.name : null }</td>
        :<td>スポット</td>}
         { project.project_type === 1 ?
        <td className="link" onClick={()=> displayPartner(item.driver.partner)}>{item && item.driver && item.driver.partner ? item.driver.partner.name : null }</td>
        :<td>{item && project ? project.name : null }</td>}
         { project.project_type === 1 ?
        <td className="link" onClick={()=> displayDriverDetail(item.driver  )}>{item && item.driver ? item.driver.name : null}</td>
         
         :<td>-</td>}
        <td>
          <div class="buttons">
						<a href={"/project/" + project.uid + "/monthly_report/" +item.uid +"/" +props.year +"/" + props.month} className="a icon_button"><i className="fa-solid fa-pen"></i></a>
					</div>
        </td>
        {
          props.profile && props.profile.user_type !== "partner" ? 
        <td>
          <div class="buttons">
					<div className="a icon_button link" onClick={()=>(copy("/project/" + project.uid + "/monthly_report/" +item.uid +"/" +props.year +"/" + props.month + "/pub" , item.id))}>■</div>
          {Number(message) === item.id ? <span className="copyied">コピーしました。</span> : null}
					</div>
        </td>
         : null
        }
      
      </tr>
      :null
    )
  }
  const createInternalDriverTd = (item) => {
 

    return(
   
      <tr key={"item_d_" + item.id}>
        
        <td>{props.year}/{props.month}</td>
        <td>-</td>
        <td>スポット</td>
        <td>{props.profile && props.profile.company ? props.profile.company.name : null}</td>
        <td className="link" onClick={()=> displayDriverDetail(item  )}>{item.name}</td>
        <td>
          <div class="buttons">
						<a href={"/project/" +( props.profile && props.profile.company ? props.profile.company.project_id : null ) + "/monthly_report/driver/" +item.uid +"/" +props.year +"/" + props.month} className="a icon_button"><i className="fa-solid fa-pen"></i></a>
					</div>
        </td>
        {
          props.profile && props.profile.user_type !== "partner" ? 
            <td>
            <div class="buttons">
            <div className="a icon_button link" onClick={()=>(copy("/project/" + (props.profile && props.profile.company ? props.profile.company.project_id : null )+ "/monthly_report/driver/" +item.uid +"/" +props.year +"/" + props.month + "/pub" , item.id))}>■</div>
            {Number(message) === item.id ? <span className="copyied">コピーしました。</span> : null}
            </div>
          </td>
           : null
        }
        
      
      </tr>
      
    )
  }
  
  
  const closeHandler = () => {
    setDetail(null)
  }
  const displayClientDetail = (client) => {
    setDetail(<ClientDetailOverlay token={props.token} profile={props.profile} client={client} is_edit={false} closeHandler={closeHandler} />)
  }
  const displayProjectDetail= (item) => {
    setDetail(<ProjectDetailOverlay profile={props.profile} token={props.token} project={item} is_edit={false} closeHandler={closeHandler} />)
  }
  const displayPartner = (partner) => {
    setDetail(<PartnerDetailOverlay token={props.token}  profile={props.profile} partner={partner} is_edit={false} closeHandler={closeHandler} />)
  }

  const displayDriverDetail= (item) => {
    setSelectedDriver(item)
    getProjectList(props.token, 1, [item.id])
    
  }





  return(
    <div className="inner">
        {detail ?  <div id="overlay" className="overlay" onClick={closeHandler}></div> : null}
			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">月報一覧</h2>
                <Breadcrumbs pages={[new LinkProps("月報一覧","#")]} />
						</div>
            
            <SearchBoxMonthlyReport is_partner={ is_partner}  btnClickHandler={btnClickHandler} clearHandler={clearHandler} token={props.token} params={props.params}/>
           
           

            <table className="datatable dayreport_tbl">
							
							<thead>
                <tr>
								
                <th>年月</th>
									<th>クライアント名</th>
									<th>案件名	</th>
									<th>依頼先会社名	</th>
									<th>ドライバー名	</th>
                  <th>月報確認	</th>
                  {
                    props.profile && props.profile.user_type !== "partner" ? <th>リンクコピー</th> : null
                  }
									
                
								</tr>
							</thead>
							<tbody>
              {list}
							</tbody>
							<tfoot>
								<tr>
									<td colspan="99">
                    <Pageing total_number={total_number} current_page={props.page}  />
										
									</td>
								</tr>
							</tfoot>
						</table>
            {detail}
				</div>
		</div>

  )
}

export default MonthlyList


