import { Record } from 'immutable'


const BankInfoProps = Record({
  bank_name:null,
  account_type:null,
  branch_name:null,
  account_id:null,
  account_name:null
});
export default BankInfoProps;

