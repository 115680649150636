import React, {  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ProjectCreateSuccess from "./ProjectCreateSuccess"
import ProjectDeleteSuccess from './ProjectDeleteSuccess';

function ProjectSuccessApp(props:PropsValue) {
  const { id } = useParams();
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      //setToken(e.token)
    }
  }

  return(
    <div id="home">
      <PageApp current_page="project"  content={id === "deleted" ?  <ProjectDeleteSuccess /> :<ProjectCreateSuccess />} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectSuccessApp

