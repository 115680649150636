
import CompanyProps from './CompanyProps'

const props = extendValues => class extends CompanyProps {};
class Company extends props({}) {



}
export default Company


