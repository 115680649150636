import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import UserDetailItem from './UserDetailItem';

function User(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)

  useEffect(() => {
    setItem(props.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.user])
  
  const changeHandler = (e) => {
    setItem(e)
  } 

  const btnClickHandler = () => {
    
    const errors = getErrorMessagte()
    
    setErrorMessage(null)
    if(errors.length >0 ){
      setErrorMessage(errors.map(item => <div>{item}</div>))
    }else{
      props.updateHandler(item)
    }
  }

  const btnSaveOnCookieHandler = () => {
    props.saveOnCookieHandler(item)
  }

  const getErrorMessagte = () => {
    var errors = []
    if(!item.name || item.name === "") errors.push("ユーザー名が未入力です。")
    if(!item.password || item.password === "") errors.push("パスワードが未入力です。")
    if(!item.user_type || item.user_type === "") errors.push("ユーザー種別が未入力です。")
    if(!item.furigana || item.furigana === "") errors.push("フリガナが未入力です。")
    if(item.furigana &&  !item.furigana.match(/^[ァ-ヶー　]+$/)) errors.push("フリガナは全角カタカナで入力してください。")
    if(!item.username || item.username === "") errors.push("メールアドレスが未入力です。")
    if(item.username && !item.username.match(config.get("MAIL_PATTERN")))  errors.push("メールアドレスの形式を正しく入力してください。")
   
    if(!item.tel || item.tel === "") errors.push("電話番号が未入力です。")
    if(item.tel && !item.tel.match(/^[0-9]+$/))  errors.push("電話番号は半角数字で入力してください。")

    return errors
  }


  return(
    <div className="inner">
       <form >
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">{item ? item.id ? "ユーザー情報編集" :"新規ユーザー登録" : null}</h2>
                <Breadcrumbs pages={[new LinkProps(item ?item.id ? "ユーザー情報編集" :"新規ユーザー登録": null,"#")]} />
							</div>
							
							<div className="container">
                {item && !item.id && props.has_cookie === true ?<div className="link right" onClick={props.loadFromCookie}><i className="fa-solid fa-rotate-right"></i> 下書きから読み込む</div> : null}
              <div className="column"><p className="container_name">{item ? item.id ? "ユーザー情報編集" :"新規ユーザー登録" : null}</p>
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
									<div className="input_wrap">
                    <UserDetailItem user={item} is_edit={true} changeHandler={changeHandler}/>
									</div>
								</div> 
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                <div className="button_wrap center">
                  {item && !item.id ? <button className="textbutton" type="button"  onClick={btnSaveOnCookieHandler}>下書き保存</button> : null}
								  <button className="red" type="button" onClick={btnClickHandler}>登録内容確認</button>
							  </div>

						  </div>
			
				</div>
        </form>
		</div>

  )
}

export default User

