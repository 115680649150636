import React, { useEffect, useState } from 'react'

import { format, parseISO } from 'date-fns';

function BodyDefault(props:PropsValue) {
  
  const [list, setList] = useState(null)
  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())

  useEffect(() => {

    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
 
  },[ props.repot_list, props.repo_list_updated_at])

  useEffect(() => {
    
    if(repot_list ){
      const _list = repot_list.map(item => {
        return(
          <tr>  
              {item ? (<td>{format(parseISO(item.paid_date),"yyyy年MM月dd日")} </td>) : <td></td> }
              {item ? (<td><input type="text" className="report_erea" value={item.payto} name="payto" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
              {item ? ( <td><input type="text"  className="report_erea" value={item.description}  name="description" onChange={(e) => props.changeHandler(e, item.id)} /></td>): <td></td>}
              {item ? (  <td><input type="price" className="" value={item.price.toLocaleString()}  name="price" onChange={(e) => props.changeHandler(e, item.id)}  /></td>): <td></td>}
          </tr>
        )   
      })

      _list.push(<tr>  <td colSpan="3" class="report_subtotal">消費税10%対象　小計</td><td><span className="yen_mark">&yen;</span> {props.total_price ? props.total_price.toLocaleString() :null}</td> </tr>)
      _list.push(<tr>  <td colSpan="3" class="report_subtotal">立替金合計</td><td><span className="yen_mark">&yen;</span> {props.total_price ? props.total_price.toLocaleString() :null}</td> </tr>)
      setList(_list)

      setList(_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at])




  return(
    <table>
      <tbody>
      <tr>
      <th>支払日</th><th>支払先</th><th>内容</th><th>金額（税込）</th>
      </tr>
      {list}
      </tbody>
    </table>
    
  )
}

export default BodyDefault

