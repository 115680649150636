
import { subMonths } from 'date-fns';
import ProjectProps from './ProjectProps'
import Utile from '../../utiles/Utile';
import config from 'react-global-configuration';




const projectProps = extendValues => class extends ProjectProps {};
class Project extends projectProps({}) {

  is_unit_price = true
  is_over_time_fee = true
  is_over_distance_fee = true


  validateForm(form){
    return []
  }




  getLatestReportMonthDate(){
    const today = new Date()
    return today
  }

  getEndDateString(){
    return Utile.getLabelByValue(config.get("PROJECT_CLOSING_DATE_TYPE"), this.closing_date)
  }

 

  getReportMonthAndYear(target_date){
    var date = target_date.getDate();

    var new_target_date = target_date

    switch(this.closing_date){
     
      case 2 :  {
        if(date <= 25){
          new_target_date = subMonths(target_date,1)
        }     
        break;
      }
     
      case 3 :  {
        if(date <= 20){
          new_target_date = subMonths(target_date,1)
        }     
        break;
      }

      case 4 :  {
        if(date <= 15){
          new_target_date = subMonths(target_date,1)
        }     
        break;
      }
     
      default : {}
    }

    return [new_target_date.getFullYear(), new_target_date.getMonth() + 1]
  }
  

  getOverTimeUnitTime(){
    switch(this.overtime_fee_type){
      case 1 : return 1
      case 2 : return 5
      case 3 : return 10
      case 4 : return 15
      case 5 : return 15
      case 6 : return 30
      case 7 : return 30
      case 8 : return 30
      case 9 : return 30
      case 10 : return 30
      case 11 : return 60
      default : return null
    }
  }

}
export default Project

