import React, { useEffect } from 'react'
import { PageApp } from '../../stories/PageApp'
import Login from "./Login"
import useLoginState from './useLoginState'

function LoginApp(props:PropsValue) {

  //const [content, setContent] = useState()
  const {isLogin} = useLoginState()

  useEffect(() => {
    if(isLogin() === true){
     window.location.href = "/dashboard"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  


  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      //setToken(e.token)
    }
  }
  return(
    <div id="home">
      <PageApp noauth={true} content={<Login  />} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default LoginApp

