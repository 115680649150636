import React, {useState,useEffect} from 'react';
import {setHours, setMinutes} from 'date-fns';
import ReportObject from '../report/ReportObject';
import useReportState from '../report/useReportState';


interface ChangeAllWindowProps {
  token:String,
  report_list:Array[Report],
  closeHandler:Function,
}

/**
 * Primary UI component for user interaction
 */
export const ChangeAllWindow = ({
  token,
  report_list,
  closeHandler,
  
  ...props
}: ChangeAllWindowProps) => { 

  
  const [new_start_time, setNewStartTime] = useState(null)
  const [new_end_time, setNewEndTime] = useState(null)
  const [new_distance, setNewDistance] = useState(null)

  const {createReport} = useReportState()
  useEffect(() => {

   
    
   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const btnClickHandler = () => {
    setNewStartTime(null)
    setNewEndTime(null)
    setNewDistance(null)
    for(var i = 0; i < report_list.length; i++){
      var item = new ReportObject(report_list[i])
      var is_changed = false
      if(new_start_time){
        is_changed = true
        const hour = new_start_time.split(":")[0]
        const min = new_start_time.split(":")[1]
        const start_date = setMinutes(setHours(new Date(item.work_start_at), hour),min)
        item = item.set("work_start_at", start_date)
      }

      if(new_end_time){
        is_changed = true
        const hour = new_end_time.split(":")[0]
        const min = new_end_time.split(":")[1]
        const end_date = setMinutes(setHours(new Date(item.work_end_at), hour),min)
        item = item.set("work_end_at", end_date)
      }

      if(new_distance){
        is_changed = true
        item = item.set("distance", new_distance)
      }


      if(is_changed){
        createReport(token,item.project.uid, item.driver.uid, item)
      }

      closeHandler()
      
    }
  }

  return (
    <div className="modal-window sml" data-id="modal1" >
 
    <button className="js-close button-close" onClick={closeHandler}>Close</button>
        <div className="modal-scroll__container">
        <table className="input_table col12 login_tbl">
        <tbody>
          <tr>
            <th>業務開始時間</th>
            <td><input autocomplete="off" type="time" className="col9" value={new_start_time} onChange={(e) => setNewStartTime(e.target.value)}/></td>
          </tr>

          <tr>
            <th>完了時間</th>
            <td><input autocomplete="off" type="time" className="col9" value={new_end_time} onChange={(e) => setNewEndTime(e.target.value)}/></td>
          </tr>

          <tr>
            <th>距離項目</th>
            <td><input autocomplete="off" type="number" className="col9" value={new_distance} onChange={(e) => setNewDistance(e.target.value)}/> km</td>
          </tr>

        </tbody>
        </table>
        <div className="button_wrap center">
            <button className="red" type="button" onClick={btnClickHandler}>一括更新</button>
        </div>
        </div>
  
  </div>
  );
};