import React, { useEffect, useState } from 'react'

import BodyDefaultItem from './BodyDefaultItem'
import PaymentItem from '../PaymentItem'

function BodyDeduction(props:PropsValue) {
  
  const [list, setList] = useState(null)
  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())

  useEffect(() => {
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
 
  },[ props.repot_list, props.repo_list_updated_at])


  useEffect(() => {

    if(repot_list){
      var total_target_10 = 0
      var total_target_8 = 0
      var total_untax = 0
      //comitionable
      const _list = []
   
      repot_list.map((item, index) => {
        const _index = index
        total_target_10 +=  item.getTypedTaxTotal(10)
        total_target_8 +=  item.getTypedTaxTotal(8)
        total_untax +=  item.getTypedTaxTotal(100)
        _list.push(<BodyDefaultItem editable={props.editable} index={_index} is item={item} updateRowHadler={(_item) => updateRowHadler(_item, _index)} deleteItem={() => deleteItem(_index)}/>) 
        return null
      })

      
      const total_price = total_target_10 + total_target_8 + total_untax

      if(props.editable){
        _list.push( <tr  className="new_row"><td></td><td></td><td onClick={() => props.addItem(new PaymentItem({is_non_tax:true, is_edit_all:true, is_tax_rate:true}), true)}>新しい行を追加</td><td></td><td></td><td></td><td></td><td></td><td></td></tr>)
      }
      _list.push(<tr><td  className="lefttop" colSpan={7} rowSpan={5}>(備考)</td><td  >消費税10%対象</td><td className="right">{total_target_10.toLocaleString()}</td> </tr>)
      _list.push(<tr><td  >消費税8%対象</td><td className="right">{total_target_8.toLocaleString()}</td> </tr>)
      _list.push(<tr><td  >内消費税額</td><td className="right">({(Math.round((total_target_10 / 110)*10) + Math.round((total_target_8 / 108)*8)).toLocaleString()})</td> </tr>)
      _list.push(<tr><td  >消費税対象外小計</td><td className="right">{total_untax}</td> </tr>)
      _list.push(<tr><td  >控除金額合計</td><td className="right">{total_price.toLocaleString()}</td> </tr>)

      setList(_list)
      props.updateTotal(total_price, true)
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at,props.editable,props.updated_at ])


  const deleteItem = (index) => {
    props.deleteItem(index, true)
  }

  const updateRowHadler = ( item, index) => {
    props.changeHandler(item, index, true)
  }

  return(
    <table className="monthly-payment">
    <tbody>
    <tr>
      <th>No</th>
      <th>月日</th>
      <th>{props.editable ?<input type="text" value={props.params.title_3 ? props.params.title_3 : ""} name="title_3" onChange={(e) => props.changeParamHandler(e, true)}/> :props.params.title_3 ? props.params.title_3 : ""}</th>
      <th>{props.editable ?<input type="text" value={props.params.title_4 ? props.params.title_4 : ""} name="title_4" onChange={(e) => props.changeParamHandler(e, true)}/> :props.params.title_4 ? props.params.title_4 : ""}</th>
      <th>{props.editable ?<input type="text" value={props.params.title_5 ? props.params.title_5 : ""} name="title_5" onChange={(e) => props.changeParamHandler(e, true)}/>:props.params.title_5 ? props.params.title_5 : ""}</th>
      <th>{props.editable ?<input type="text" value={props.params.title_6 ? props.params.title_6 : ""} name="title_6" onChange={(e) => props.changeParamHandler(e, true)}/>:props.params.title_6 ? props.params.title_6 : ""}</th>
      <th>{props.editable ?<input type="text" value={props.params.title_7 ? props.params.title_7 : ""} name="title_7" onChange={(e) => props.changeParamHandler(e, true)}/>:props.params.title_7 ? props.params.title_7 : ""}</th>
      <th>消費税率</th><th>金額</th>
    </tr>
    {list}
    </tbody>
  </table>
    
  )
}

export default BodyDeduction

