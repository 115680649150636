import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import PartnerDelete from "./PartnerDelete"
import usePartnerState from './usePartnerState';

function UserDeleteApp(props:PropsValue) {
  const { id } = useParams();
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {partner, partner_error, partner_deleted, getPartner, deletePartner} = usePartnerState()
 
  

  useEffect(() => {
    if(partner){
      setItem(partner)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner])


  useEffect(() => {
    if(token){
      getPartner(token, id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token,id])
  
  useEffect(() => {
    if(partner_deleted){
      window.location.href = "/partner/success/deleted"
    }
    
  },[partner_deleted])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = () => {
    deletePartner(token, id)
  }
  
  return(
    <div id="home">
      <PageApp current_page="partner" content={<PartnerDelete  user={item} error={partner_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default UserDeleteApp

