import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import CompanyDetailItem from './CompanyDetailItem';

import useCompanytState from './useCompanyState'


function CompanyDetail(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [formItem, setFormItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const {company_created, updateCompany} = useCompanytState()
  useEffect(() => {
    
    setItem(props.company)
    
    if(props.company){
      setFormItem(<CompanyDetailItem company={props.company} is_edit={true} changeHandler={changeHandler} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.company])


  useEffect(() => {
    if(company_created){
      setErrorMessage("更新されました") 
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[company_created])

  
  const changeHandler = (new_item) => {
    setItem(new_item)
  } 

  const btnClickHandler = () => {
    
    const errors = getErrorMessagte()
    setErrorMessage(null)

    if(errors.length >0 ){
      setErrorMessage(errors.map(item => <div>{item}</div>))
    }else{
      updateCompany(props.token, item)
    }
  }

  const getErrorMessagte = () => {
    var errors = []
  
    if(!item.name || item.name === "") errors.push("会社名が未入力です。")
    return errors
  }


  return(
    <div className="inner">
       <form >
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">{item && item.id ? "自社情報" : "自社情報編集"}</h2>
                <Breadcrumbs pages={[new LinkProps("自社情報編集","#")]} />
							</div>
							
							<div className="container">
              
                <div className="column"><p className="container_name">自社情報編集</p>
               
                  <div className="input_wrap">

                    {formItem}
										
           
									</div>


                  {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  { props.is_edit ? 
                  <div className="button_wrap center">

                    <button className="red" type="button" onClick={btnClickHandler}>更新</button>
                  </div>
                  : null}
                </div>
                
              </div>
              
				</div>
       </form>
		</div>
    
  )
}

export default CompanyDetail
