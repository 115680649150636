import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import PartnerDetailItem from './PartnerDetailItem';
import config from 'react-global-configuration';

function PartnerDetail(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [formItem, setFormItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  
  useEffect(() => {
    
    setItem(props.partner)
    
    if(props.partner){
      setFormItem(<PartnerDetailItem partner={props.partner} is_edit={props.is_edit} changeHandler={changeHandler} />)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.partner])

  
  const changeHandler = (new_item) => {
    setItem(new_item)
  } 

  const btnClickHandler = () => {
    
    const errors = getErrorMessagte()
    setErrorMessage(null)

    if(errors.length >0 ){
      setErrorMessage(errors.map(item => <div>{item}</div>))
    }else{
      props.updateHandler(item)
    }
  }

  const getErrorMessagte = () => {
    var errors = []
  
    if(!item.name || item.name === "") errors.push("依頼先会社名が未入力です。")
    if(!item.kana || item.kana === "") errors.push("フリガナが未入力です。")
    if(!item.id && (!item.password || item.password === "")) errors.push("パスワードが未入力です。")
    if(item.kana &&  !item.kana.match(/^[ァ-ヶー　]+$/)) errors.push("フリガナは全角カタカナで入力してください。")
    if(!item.prefecture || item.prefecture === "") errors.push("都道府県が未入力です。")
    if(!item.address || item.address === "") errors.push("所在地が未入力です。")
    if(!item.tel || item.tel === "") errors.push("電話番号が未入力です。")
    if(item.tel && !item.tel.match(/^[0-9]+$/))  errors.push("電話番号は半角数字で入力してください。")
    
    if(!item.email || item.email === "") errors.push("メールアドレスが未入力です。")
    if(item.email && !item.email.match(config.get("MAIL_PATTERN")))  errors.push("メールアドレスの形式を正しく入力してください。")

    //if(!item.contract_url || item.contract_url === "") errors.push("契約書URLが未入力です。")
    if(!item.name_in_charge || item.name_in_charge === "") errors.push("担当者名が未入力です。")
    if(!item.kana_in_charge || item.kana_in_charge === "") errors.push("担当者フリガナが未入力です。")
    if(item.kana_in_charge &&  !item.kana_in_charge.match(/^[ァ-ヶー　]+$/)) errors.push("担当者フリガナは全角カタカナで入力してください。")

    if(!item.bank_info || !item.bank_info.bank_name ) errors.push("振込先銀行名が未入力です。")
    if(!item.bank_info || !item.bank_info.branch_name  ) errors.push("振込先支店名が未入力です。")
    if(!item.bank_info || !item.bank_info.account_type ) errors.push("振込先口座種別が未入力です。")
    if(!item.bank_info || !item.bank_info.account_id ) errors.push("振込先口座番号が未入力です。")
    if(!item.bank_info || !item.bank_info.account_name ) errors.push("振込先口座名が未入力です。")



    if(item.commision === null || item.commision === "") errors.push("支払い手数料が未入力です。")
    if(item.payment_day_type === null || item.payment_day_type === "") errors.push("支払いサイトが未入力です。")
    if(!item.id && (!item.password || item.password )=== "") errors.push("パスワードが未入力です。")
    return errors
  }


  const btnSaveOnCookieHandler = () => {
    props.saveOnCookieHandler(item)
  }
  
  return(
    <div className="inner">
       <form >
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">{item && item.id ? "依頼先情報" : "依頼先会社登録"}</h2>
                <Breadcrumbs pages={[new LinkProps("依頼先会社一覧","/partner"),new LinkProps("依頼先会社登録","#")]} />
							</div>
							
							<div className="container">
              {item && !item.id && props.has_cookie === true ?<div className="link right" onClick={props.loadFromCookie}><i className="fa-solid fa-rotate-right"></i> 下書きから読み込む</div> : null}
                <div className="column"><p className="container_name">{item && item.id ? "依頼先情報" : "新規依頼先会社登録"}</p>
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  <div className="input_wrap">

                    {formItem}
										
									</div>
                  {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  { props.is_edit ? 
                  <div className="button_wrap center">
                    {item && !item.id ? <button className="textbutton" type="button"  onClick={btnSaveOnCookieHandler}>下書き保存</button> : null}
                    <button className="red" type="button" onClick={btnClickHandler}>登録内容確認</button>
                  </div>
                  : null}
                </div>
                
              </div>
              
				</div>
       </form>
		</div>
    
  )
}

export default PartnerDetail

/**
 * {item && item.id ? 
              <div className="container">
                <div className="column"><p className="container_name">{item && item.id ? "依頼先情報" : "新規依頼先会社登録"}</p>
                  <div className="input_wrap">
                    <a href={"/driver/partner/" + item.id}>ドライバー一覧へ</a>
                  </div>
                </div>
              </div>
              : null}
 */