
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action';
const PartnerAction = {


  getList(token, offset,limit, item,  callback, errorHandler){
    const data = new FormData() 
    data.append('limit', limit)
    data.append('offset', offset)
    
    if(item && item.prefecture && item.prefecture !== null){
      data.append('prefecture', item.prefecture)
    }


     
    if(item && item.name && item.name !== "")
      data.append('name', item.name)
    if(item && item.tel && item.tel !== "")
      data.append('tel', item.tel)
    if(item && item.is_self && item.is_self === 1)
      data.append('no_self', true)
    if(item && item.name_in_charge && item.name_in_charge !== "")
      data.append('name_in_charge', item.name_in_charge)
    if(item && item.commision && item.commision !== "")
      data.append('commision', item.commision)
    if(item && item.target_date && item.target_date !== "")
      data.append('target_date', item.target_date)

    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_PARTNER") + "?"  + queryString
  
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },


  getDetail(token, id,  callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_PARTNER")  + id  , token, "GET", {}, null,  callback, errorHandler)
  },

  getDetailByUserId(token, user_id,  callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_PARTNER")   + "uesrs/" + user_id  , token, "GET", {}, null,  callback, errorHandler)
  },

  create( token, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_PARTNER") + item.id  : config.get("API_PATH") + config.get("API_PARTNER")
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },



  delete( token, id,  callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_PARTNER") + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  hasCookie(item, callback, errorHandler){
    const user =  cookie.load("partner_form_")
    if(user) return true 
    return false
  },
  saveOnCookie(item){
    cookie.save('partner_form_',item, { path: '/' })
  },

  loadFromCookie(item, callback, errorHandler){
    const user =  cookie.load("partner_form_")
    if(user) {
      callback({data:user})
    }else{
      errorHandler({"error":"not found"})
    }
  },
  removeCookie(item, callback, errorHandler){
    cookie.remove('partner_form_', { path: '/' })
  }
 
 
}

export default PartnerAction
