
import ReportProps from './ReportProps';
import {endOfMonth,isBefore,setDay,parseISO,format,isDate} from "date-fns"
import Project from '../project/Project';
const props = extendValues => class extends ReportProps {};
class ReportObject extends props({}) {

  getLatestReportMonthDate(){
    const today = new Date()
    return today
  }

  getTatekaeTotal(){
    var total = 0
    this.receipts.map(receipt => {
      total += receipt.price
      return null
    })
    return total
  }

  getDeliveryNumber() {
    const project = new Project(this.project)
    switch(project.category){
      case 4 : return this.normal_unit + this.nekopos_unit + this.dm_unit 
      default : return this.delivery_unit
    }
    
  }


  isInTargetMonth(year, month) {
    var target_date = new Date(year, month, 1)
    switch(this.project.closing_date){
      case 1 :target_date = endOfMonth(target_date)
        break;
      case 2 :target_date = setDay(target_date,20)
        break;  
      case 3 :target_date = setDay(target_date,25)
      break;
      case 4 :target_date = setDay(target_date,15)
      break;
      default :{}
    }
    if(isBefore(this.worked_date, target_date)){
      return false
    }
   
    return true
  }

  findProjectDriver(id){
    return this.project.drivers.find(item =>Number(item.driver_id)=== Number(id))
  }


  getRealFee() {
   
    const project_driver = this.project_driver
    if(!project_driver) return 0

    if( this.project.category === 4){
      return (this.normal_unit * project_driver.fee_information.unit_yamato_normal) 
       + (this.nekopos_unit * project_driver.fee_information.unit_yamato_nekopos) 
       + (this.dm_unit * project_driver.fee_information.unit_yamato_dm) 
    }
    if( this.project.category === 0){
      return this.fee_information && this.fee_information.unit_price ? this.fee_information.unit_price : 0
    }
    if(this.fee_information && this.fee_information.unit_price ){
      return Number(this.fee_information.unit_price ) 
    }

    if(project_driver.fee_information){
      return Number(project_driver.fee_information.unit_price) 
    }
   
    return 0
  }


  getTargetReportYear(){
    const project = new Project(this.project)
    return project.getReportMonthAndYear(this.work_start_at)[0]

    
  }
  getTargetReportMonth(){
    const project = new  Project(this.project)
    return project.getReportMonthAndYear(this.work_start_at)[1]
  }

  getDriverCompanyTd(project, project_driver,driver){
    //定期案件
    if(project && project.project_type === 1){
      if(project_driver && project_driver.driver && project_driver.driver.partner){
        return project_driver.driver.partner.name
      }
    }else if(project){ //スポット 
      if(project_driver && project_driver.driver && project_driver.driver.partner && project_driver.driver.partner.is_self !== 1){ //他社
        return project_driver.driver.partner.name 
      }else if(project && project.user && project.user.company){
        return project.user.company.name
      }
        return null
    }
    return null
  }

  getText(report,project, project_driver,driver) {
    const texts = []
    const item = this
    console.log(item)
    if(report && !report.driver_name  && (!report.spot_type || report.spot_type !== 0) ) {
      texts.push("ドライバー :" + (project_driver && project_driver.driver ? project_driver.driver.name : driver ? driver.name : "-"))
    }
    texts.push("送信日時 :" + (item.created_at ? format(parseISO(item.created_at), "yyyy年M月d日 HH:mm") : ""))
    if(this.getDriverCompanyTd(project, project_driver,driver)){
      texts.push("依頼先 :" + (this.getDriverCompanyTd(project, project_driver,driver)))
    }
    if(report && (report.spot_type || report.spot_type === 0) ){
      texts.push("ドライバー名 :" + (item.driver_name))
    } 
    if(project && project.project_type === 1 ){
      texts.push("案件 :" + (project ? project.name : ""))
    }
    if(report && report.detail ){
      texts.push((report.detail) + " :" + (item ? item.detail : ""))
    }   
    texts.push("日付 :" + (item && item.work_start_at && isDate(item.work_start_at) ?  format(item.work_start_at , "yyyy年MM月dd日"): ""))
    
    if(item && report && report.work_start_at ){
      texts.push( "業務開始時間 :" + (item && item.work_start_at && isDate(item.work_start_at) ? format(item.work_start_at , "HH:mm") : ""))
    } 

    if(item && report && report.drive_start_at ){
      texts.push((report.drive_start_at) + " :" + (item && item.drive_start_at && isDate(item.drive_start_at) ? format(item.drive_start_at , "HH:mm") : ""))
    } 

    if(item && report && report.area ){
      texts.push((report.area) + " :" + (item && item.area ? item.area : ""))
    } 

    if(item && report && report.load_location ){
      texts.push((report.load_location) + " :" + (item && item.load_location ? item.load_location : ""))
    } 

    if(item && report && report.delivery_location ){
      texts.push((report.delivery_location) + " :" + (item  && item.delivery_location ? item.delivery_location : ""))
    } 

    if(item && report && report.requested_unit ){
      texts.push((report.requested_unit) + " :" + (item && item.requested_unit ? item.requested_unit : ""))
    } 

    if(item && report && report.delivery_unit ){
      texts.push((report.delivery_unit) + " :" + (item && item.delivery_unit ? item.delivery_unit : ""))
    } 

    if(item && report && report.return_unit ){
      texts.push((report.return_unit) + " :" + (item && item.return_unit ? item.return_unit : ""))
    } 

    if(item && report && report.normal_load_unit ){
      texts.push((report.normal_load_unit) + " :" + (item && item.normal_load_unit ? item.normal_load_unit : ""))
    } 

    if(item && report && report.normal_unit ){
      texts.push((report.normal_unit) + " :" + (item && item.normal_unit? item.normal_unit : ""))
    } 

    if(item && report && report.nekopos_load_unit ){
      texts.push((report.nekopos_load_unit) + " :" + (item && item.nekopos_load_unit ? item.nekopos_load_unit : ""))
    } 

    if(item && report && report.nekopos_unit ){
      texts.push((report.nekopos_unit) + " :" + (item && item.nekopos_unit ? item.nekopos_unit : ""))
    } 

    if(item && report && report.dm_load_unit ){
      texts.push((report.dm_load_unit) + " :" + (item && item.dm_load_unit ? item.dm_load_unit : ""))
    } 

    if(item && report && report.code ){
      texts.push((report.code) + " :" + (item && item.code ? item.code : ""))
    } 

    if(item && report && report.load_unit ){
      texts.push((report.load_unit) + " :" + (item && item.load_unit ? item.load_unit : ""))
    } 

    if(item && report && report.large_unit ){
      texts.push((report.large_unit) + " :" + (item  && item.large_unit ? item.large_unit : ""))
    } 

    if(item && report && report.distance ){
      texts.push((report.distance) + " :" + (item && item.distance ? (item.distance + "km") : ""))
    } 

    if(item && report && report.waiting_hour ){
      texts.push((report.waiting_hour) + " :" + (item && item.waiting_hour ?  item.waiting_hour - (60 * Math.floor(item.waiting_hour / 60)) :  ""))
    } 

    if(item && report && report.received_gender ){
      texts.push((report.received_gender) + " :" + (item.received_gender))
    } 

    if(item && report && report.received_name ){
      texts.push((report.received_name) + " :" + (item.received_name))
    } 

    if(item && report && report.work_end_at ){
      texts.push((report.work_end_at) + " :" + (item && item.work_end_at && isDate(item.work_end_at) ? format(item.work_end_at , "HH:mm") : ""))
    } 
    
 

    if( item && item.receipts){
      for(var i = 0; i < item.receipts.length; i++){
        texts.push(item.receipts[i].description + " :" + (item.receipts[i].price) )
      }
    }


    if(item){
      texts.push("備考 :" + (item.note ? item.note : "") )
    } 
    return texts.join("\n")
  }


}
export default ReportObject
