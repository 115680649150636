import React, {useState, useEffect  } from 'react'
import { useParams,useSearchParams } from 'react-router-dom';

import { PageApp } from '../../stories/PageApp'
import PartnerDetail from "./PartnerDetail"
import PartnerProps from './PartnerProps';
import usePartnerState from './usePartnerState';
import BankInfo from '../bankinfo/BankInfo';


function PartnerDetailApp(props:PropsValue) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const is_new = searchParams.get("new") ?  searchParams.get("new") : 0 ;
  const [token, setToken] = useState(null)
  const [has_cookie, setHasCookie] = useState(false)
  const [item, setItem] = useState(null)

  const {partner, saveOnCookie, getPartner, hasCookie, loadFromCookie } = usePartnerState()

  useEffect(() => {
    setHasCookie(hasCookie())
    if(token){
      if(is_new === 0 && hasCookie()){
        loadFromCookie()
      }else{
        if(id === "new"){
          setItem(new PartnerProps())
        }else{
          getPartner(token,id)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  

  useEffect(() => {

    if(partner){
     if(id !== "new" && Number(partner.id) !== Number(id)){
      getPartner(token,id)
     }else{
      var p_partner = new PartnerProps(partner)
      p_partner = p_partner.set("bank_info", new BankInfo(partner.bank_info))
      setItem(p_partner)
     }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner])

  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    saveOnCookie(_item)
    window.location.href = "/partner/confirm"
  }
  const saveOnCookieHandler = (item) => {
    saveOnCookie(item)
    window.location.href = "/partner"
  }

  return(
    <div id="home">
      <PageApp current_page="partner" content={<PartnerDetail partner={item} has_cookie={has_cookie} is_edit={true} loadFromCookie={loadFromCookie} updateHandler={updateHandler} saveOnCookieHandler={saveOnCookieHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default PartnerDetailApp

