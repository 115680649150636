import React, {useState, useEffect  } from 'react'
import { useSearchParams } from "react-router-dom";
import { PageApp } from '../../stories/PageApp'
import MonthlyReceiptList from "./MonthlyReceiptList"

import useMonthlyReportState from '../monthly_report/useMonthlyReportState';

function MonthlyReceiptListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?  searchParams.get("page") : "1" ;
  const [token, setToken] = useState(null)
  const [list, setList] = useState([])
  const [total_number, setTotalNumber] = useState(1)
  const [profile, setProfile] = useState(null)
  const {monthly_report_list,  list_updated_at, monthly_repot_total_number, getMontylyReportList  } = useMonthlyReportState()


 


  useEffect(() => {

    setList(monthly_report_list)

    if(monthly_repot_total_number > 1){
      setTotalNumber(monthly_repot_total_number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at, monthly_repot_total_number])

  useEffect(() => {
    if(token){
      //GET LIST
      getMontylyReportList(token,null,null, 2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])

  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const searchHandler = (e) => {
    //getReportList(token,page, e)
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  return(
    <div id="home">
      <PageApp current_page="monthly_report" content={<MonthlyReceiptList profile={profile} token={token} list={list} total_number={total_number} current_page={page} search={searchHandler} />} authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler}/>
    </div>
     
  )
}

export default MonthlyReceiptListApp

