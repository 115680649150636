import React, { useEffect } from 'react';
import './breadcrumbs.css';

interface BreadcrumbsProps {
  /**
   * pages
   */
    pages: [],
  
}

/**
 * Primary UI component for user interaction
 */
export const Breadcrumbs = ({
  pages,
  //content,
  ...props
}: BreadcrumbsProps) => {

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <ul className="breadcrumbs">
				<li><a href="/dashboard">TOP</a></li>

			  {
          pages.map(item => {
            return (<li><a href={item.link}>{item.label}</a></li>)
          })
        }
		</ul>
  );
};

