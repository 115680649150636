import React, { useEffect, useState } from 'react'

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import ClientDetailItem from './ClientDetailItem';
import config from 'react-global-configuration';



function ClientDetail(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  
  useEffect(() => {
    if(props.client){
      setItem(props.client)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.client])


  const changeHandler = (e) => {
    setItem(e)
  } 

  const btnClickHandler = () => {
    const errors = getErrorMessagte()
    setErrorMessage(null)
    if(errors.length >0 ){
      setErrorMessage(errors.map(item => <div>{item}</div>))
    }else{
      props.updateHandler(item)
    }
  }

  const getErrorMessagte = () => {
    var errors = []
    if(!item.name || item.name === "") errors.push("クライアント名が未入力です。")
    if(!item.kana || item.kana === "") errors.push("フリガナが未入力です。")
    if(item.kana &&  !item.kana.match(/^[ァ-ヶー　]+$/)) errors.push("フリガナは全角カタカナで入力してください。")

    if(!item.prefecture || item.prefecture === "") errors.push("都道府県が未入力です。")
    if(!item.address || item.address === "") errors.push("所在地が未入力です。")
    if(!item.tel || item.tel === "") errors.push("電話番号が未入力です。")
    if(item.tel && !item.tel.match(/^[0-9]+$/))  errors.push("電話番号は半角数字で入力してください。")

    if(!item.email || item.email === "") errors.push("メールアドレスが未入力です。")
    if(item.email && !item.email.match(config.get("MAIL_PATTERN")))  errors.push("メールアドレスの形式を正しく入力してください。")

    //if(!item.contract_url || item.contract_url === "") errors.push("契約書URLが未入力です。")
    if(!item.name_in_charge || item.name_in_charge === "") errors.push("担当者名が未入力です。")
    if(!item.kana_in_charge || item.kana_in_charge === "") errors.push("担当者フリガナが未入力です。")
    if(item.kana_in_charge  && !item.kana_in_charge.match(/^[ァ-ヶー　]+$/)) errors.push("担当者フリガナは全角カタカナで入力してください。")

    if(!item.closing_date || item.closing_date === "") errors.push("締め日が未入力です。")
    if(!item.fee_rate || item.fee_rate === "") errors.push("請求手数料が未入力です。")
    if(!item.payment_day_type || item.payment_day_type === "") errors.push("支払日が未入力です。")


    return errors
  }

  const btnSaveOnCookieHandler = () => {
    props.saveOnCookieHandler(item)
  }
  
  return(
    <div className="inner">
       <form >
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">クライアント登録</h2>
                <Breadcrumbs pages={[new LinkProps("クライアント一覧","/client"),new LinkProps("クライアント登録","#")]} />
							</div>
							
							<div className="container">
              {item && !item.id && props.has_cookie === true ?<div className="link right" onClick={props.loadFromCookie}><i className="fa-solid fa-rotate-right"></i> 下書きから読み込む</div> : null}
                <div className="column"><p className="container_name">新規クライアント登録</p>
                {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  <div className="input_wrap">
                    <ClientDetailItem client={item} is_edit={true} changeHandler={changeHandler} />
                  </div>
                  {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
                  <div className="button_wrap center">
                    {item && !item.id ? <button className="textbutton" type="button"  onClick={btnSaveOnCookieHandler}>下書き保存</button> : null}
                    <button className="red" type="button" onClick={btnClickHandler}>登録内容確認</button>
                  </div>
                </div>
              </div>
				</div>
       </form>
		</div>
    
  )
}

export default ClientDetail

