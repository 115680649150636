import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ProjectDelete from "./ProjectDelete"
import useProjectState from './useProjectState';

function ProjectDeleteApp(props:PropsValue) {
  const { id } = useParams();
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {project, project_error, project_deleted, getProject, deleteProject} = useProjectState()
 
  

  useEffect(() => {
    if(project){
      setItem(project)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project])


  useEffect(() => {
    if(token){
      getProject(token, id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(project_deleted){
      window.location.href = "/project/success/deleted"
    }
  },[project_deleted])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = () => {
    deleteProject(token, id)
  }
  
  return(
    <div id="home">
      <PageApp current_page="project"  content={<ProjectDelete token={token} project={item} error={project_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectDeleteApp

