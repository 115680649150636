import React, { } from 'react'

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

function ClientDeleteSuccess(props:PropsValue) {




  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">クライアント削除</h2>
                <Breadcrumbs pages={[new LinkProps("クライアントー一覧","/client"),new LinkProps("クライアント削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">クライアント削除 完了</p>
                <p>クライアントの削除が完了しました。</p>
									
							</div>
						</div> 
				</div>
		</div>

  )
}

export default ClientDeleteSuccess

