import React, {useState} from 'react';
import { Record } from 'immutable'
import { ClientSearchBox } from '../client_search/ClientSearchBox'; 
import Project from '../../../components/project/Project';
interface SearchBoxProjectProps {
   /**  
   * content
   */
   token:String,

   /**
    * Form 
    */
   data:?Record,

   /**
    * SearchBtnClickHandler
    */
   btnClickHandler:Function,

   clearHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const SearchBoxProject = ({
  //current_page,
  token,
  data,
  btnClickHandler,
  clearHandler,
  ...props
}: SearchBoxProjectProps) => { 


  const [form_css, setFormCss] = useState(null)
  const [form_active, setFormActive] = useState("active")
  const [item, setItem]= useState(new Project())


  const toggleSearch = () => {
    if(form_css){
      setFormCss(null)
      setFormActive("active")
    }else{
      setFormCss("search-hidden")
      setFormActive(null)
    }
  }


  
  const onChangHandler = (e) => {
    const target = e.target 
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  }

  const clickHandler = () => {
    btnClickHandler(item)
  }



  const onChangeClientSelectHandler = (e) => {
    const new_item = item.set("client_id", Number(e.value) )
    setItem(new_item)
  }

  const kedownHandler = (e) => {
    if (e.key === 'Enter') {
      clickHandler()
    }
  }


  return (
    <div className="container more_search">
    <div className="column">
    <p id="more_search_ttl" className={form_active} onClick={toggleSearch}>絞り込み検索</p>
    <form className={form_css} onKeyDown={kedownHandler}>
      <div>
        <div>
        
        <div>
           <div className="input_wrap">
              
              <label className="">
                <span className="label_name">クライアント名</span>
                <ClientSearchBox changeHandler={onChangeClientSelectHandler} token={token} />
              </label>
              
              <label className="">
                <span className="label_name">案件名</span>
                <input type="text" name="name" onChange={onChangHandler} />
              </label>
            </div>
        </div>


        </div>
          <div className="input_wrap"></div>
          <div className="button_wrap center">
            
            <button type="button" onClick={clearHandler}>クリア</button>
            <input type="button" value="検索" className="button red" onClick={clickHandler} />
          </div>
        </div>
        
      </form>
    </div>
  </div>

  );
};

