import { Record } from 'immutable'

const PaymentItemProps = Record({
  date:null,
  detail:null,
  driver_name:null,
  driver_id:null,
  unit_number:null,
  unit_price:null,
  unit_name:null,
  unit_cost_name:null,
  unit_min:1,
  comments:[],
  non_tax_price:undefined,
  fee_text_1:null,
  fee_text_2:null,
  fee_text_3:null,
  cost:undefined,
  tatekae:null,
  total_price:undefined,
  tax_rate:undefined,
  is_non_tax:false,
  is_unit_price:false,
  is_edit_all:false,
  is_non_commition:false,
  is_tax_rate:false,
  is_self:false,
  is_add:false,
  url:null
});
export default PaymentItemProps;

