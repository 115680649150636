import { Record } from 'immutable'



const  CompanyProps = Record({
  id:null,
  name:null,
  address_info:null,
  invoice_id:null,
  project_id:null,
  project_driver_id:null,
  representative:null,
  logo:null,
  mail:null
});
export default CompanyProps;

