import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import MonthlyReceiptDetail from "./MonthlyReceiptDetail"
import Receipt from '../../receipt/Receipt';

import useReportState from '../report/useReportState';
import useProjectState from '../project/useProjectState';
import useDriverState from '../drivers/useDriverState';
import useMonthlyReportState from '../monthly_report/useMonthlyReportState';
import useProjectDriverState from '../project/driver/useProjectDriverState';

import {lastDayOfMonth,   subMonths ,addDays, format} from "date-fns"

function MonthlyReceiptDetailApp(props:PropsValue) {
  const { id } = useParams();
  const { project_id } = useParams();
  const { project_driver_id } = useParams();
  
  const { year } = useParams();
  const { month } = useParams();

  const [token, setToken] = useState(null)
  const [total_price, setTotalPrice]= useState(0)
  const [list, setList] = useState(null)
  const [start_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null)
  const { report_list, list_updated_at, getReportList} = useReportState()
  const {project, getProject, getEndDateByType} = useProjectState()
  const {driver} = useDriverState()
  const {project_driver, getProjectDriver} = useProjectDriverState()
  const {monthly_report, monthly_report_created, createMonthlyReport, getMontylyReport} = useMonthlyReportState()
  useEffect(() => {

    if(token){
      getProjectDriver(token,project_id, project_driver_id)
      //getProject(token, project_id)
      //getDriver(token, driver_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])

  useEffect(() => {

    if(project_driver ){
      getProject(token, project_driver.project_id)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ project_driver])


  
  useEffect(() => {
    if(report_list){
      var _total = 0
      setList(report_list.map(item => 
       { 
         return( 
          item.receipts.map(_receipt=>{
              var  _item = new Receipt(_receipt)
              _item = _item.set("paid_date", item.worked_date)
              _total += _item.price
              return _item
          })
        )
      }).flat())


      setTotalPrice(_total)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])

  useEffect(() => {

    if(monthly_report){
      var _total = 0
      const _list = JSON.parse(monthly_report.content)
      setList(
        _list.map(_receipt=>{
          var  _item = new Receipt(_receipt)
          _total += _item.price
          return _item
        })
      )
      setTotalPrice(_total)
      
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[monthly_report])


  useEffect(() => {

    if(project && driver ){
      const end_date_day = getEndDateByType(project.closing_date)
      
      var start_date = null 
      var end_date = null 
      if(end_date_day === 0 ){
          const target_date = new Date(year, month-1, 1)
          start_date = target_date
          end_date = lastDayOfMonth(target_date)
      }else{
          const target_date = new Date(year, month-1, end_date_day)
          start_date = addDays(subMonths(target_date,1),1)
          end_date = target_date
      }

      setStartDate(start_date)
      setEndDate(end_date)
      if(!id){
        getReportList(token,0,project.id, driver.id, format(start_date, "yyyy-MM-dd"), format(end_date,"yyyy-MM-dd"))
      }else if(id){
        getMontylyReport(token, id)
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project, driver])


  useEffect(() => {

    if(monthly_report_created && monthly_report_created.id ){ 
      window.location.href  = "/project/"+ project.id + "/driver/" + driver.id 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[monthly_report_created])

  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (repot_list) => {
    createMonthlyReport(token, project_id, driver.uid, {year:Number(year), month:Number(month), report_type:2, content:JSON.stringify(repot_list)})
  }
  
  return(
    <div id="home">
      <PageApp current_page="monthly_report" content={<MonthlyReceiptDetail  total_price={total_price} year={year} month={month} start_date={start_date} end_date={end_date} project={project} driver={driver} report_list={list} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default MonthlyReceiptDetailApp

