import React, {useState} from 'react';
import { Record } from 'immutable'
import ReportObject from '../../../components/report/ReportObject';
import { DriverSearchBox } from '../driver_search/DriverSearchBox';
import { ProjectSearchBox } from '../project_search/ProjectSearchBox';
import { differenceInMonths, subMonths,format } from 'date-fns';
import { ParnterSearchBox } from '../partner_search/ParnterSearchBox';
interface SearchBoxProps {
   /**
   * content
   */
   token:String,

   /**
    * Form 
    */
   data:?Record,

   is_partner:Boolean,

   /**
    * SearchBtnClickHandler
    */
   btnClickHandler:Function,

   clearHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const SearchBox = ({
  //current_page,
  token,
  content,
  data,
  is_partner,
  btnClickHandler,
  clearHandler,
  ...props
}: SearchBoxProps) => { 

  const [form_css, setFormCss] = useState(null)
  const [form_active, setFormActive] = useState("active")
  const [item, setItem]= useState(new ReportObject())
  const [type, setType] = useState(null)
  const [error, setError] = useState(null)

  const today = new Date()
  const duration = differenceInMonths(today, new Date("2023-09-01"))
  const year_option = new Array(duration).fill(0).map((item , index ) => {
    const target_date= subMonths(today, index)
    return <option value={format(target_date, "yyyy-MM")}>{format(target_date, "yyyy年M月")}</option>
  })




  const toggleSearch = () => {
    if(form_css){
      setFormCss(null)
      setFormActive("active")
    }else{
      setFormCss("search-hidden")
      setFormActive(null)
    }
  }

  const onChangHandler = (e) => {
    const target = e.target 
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  }

  const clickHandler = () => {
    setError(null)
    if(type !== 100){
      btnClickHandler(item, type)
    }else{
      setError("定期・スポットを選択してください。")
    }
    
  }


  const onChangeDriverSelectHandler = (e) => {
    const new_item = item.set("driver_id", Number(e.value) )
    setItem(new_item)
  }

  const onChangeProjectSelectHandler = (e) => {
    const new_item = item.set("project_id", Number(e.value) )
    setItem(new_item)
  }

  const onChangePartnerSelectHandler = (e) => {
    const new_item = item.set("partner_id", Number(e.value) )
    setItem(new_item)
  }

  const selectChangeHandler = (e) => {
    const target = e.target
    if(type === null){
      if(Number(target.value) === 1){
        setType("2")
      }else if(Number(target.value) === 2){
        setType("1")
      }
    }else{
      if(type === 100){
        setType(target.value)
      }else if(type !== target.value){
        setType(null)
      }else{
        setType(100)
      }
    }
    
  }

  const kedownHandler = (e) => {
    if (e.key === 'Enter') {
      clickHandler()
    }
  }

  return (
    <div className="container more_search" >
      <div className="column">
      <p id="more_search_ttl" className={form_active} onClick={toggleSearch}>絞り込み検索</p>
      <form className={form_css} onKeyDown={kedownHandler}>
        <div>
          <div >
             <div>
        <div className="input_wrap">
            <label className="">
              <span className="label_name">稼働日</span>
              <input type="date" class="col9" placeholder="2023年6月1日" name="worked_date"   onChange={onChangHandler} />
            </label>
            {
              is_partner  ? null : 
            <label className="">
              <span className="label_name">依頼先</span>
              <ParnterSearchBox changeHandler={onChangePartnerSelectHandler} token={token} />
            </label>
            }

            {
              is_partner  ? null : 
            <label className="">
              <span className="label_name">ドライバー</span>
              <DriverSearchBox changeHandler={onChangeDriverSelectHandler} token={token} />
            </label>
            }
             {
              is_partner  ? null : 
            <label className="">
              <span className="label_name">案件</span>
              <ProjectSearchBox changeHandler={onChangeProjectSelectHandler} token={token} />
            </label>
              }
            
          </div>
          <div className="input_wrap">
            <label className="">
                <span className="label_name">フリー</span>
                <input type="text" class="col9" placeholder="" name="detail"   onChange={onChangHandler} />
            </label>

            <label className="">
            <span className="label_name">月別検索</span>
                <span className="select_wrap">
                  <select name="search_year" onChange={onChangHandler} >
                  <option value={0}>選択してください</option>
                    {
                      year_option
                    }
                  </select>
                </span>
              </label>

            <label className="">
            <span className="label_name">定期・スポット</span>
                <span className="check_wrap">
                <label class="check"><input type="checkbox" value="1" onChange={selectChangeHandler} checked={type === null || type==="1" ? true : false} /><span></span>　定期</label>
                <label class="check"><input type="checkbox" value="2" onChange={selectChangeHandler} checked={type === null || type==="2" ? true : false} /><span></span>　スポット</label>
                </span>
            </label>
          </div>
      </div>
          </div>
          <div className="error">{error}</div>
          <div className="button_wrap center">
            
            <button type="button" onClick={clearHandler}>クリア</button>
            <input type="button" value="検索" className="button red" onClick={clickHandler} />
          </div>
        </div>
        
      </form>
    </div>
  </div>
  );
};


/**
 * 

           
 */