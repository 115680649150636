
import 'whatwg-fetch'
import config from 'react-global-configuration';
import Action from '../../action/Action';
const MemoAction = {


  get(token, category, uniqueid,  callback, errorHandler){
    const data = new FormData() 
   
    data.append('category', category)
    data.append('uniqueid', uniqueid)
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_MEMO") + "?"  + queryString
  
    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)

  },

  create( token, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const additional_data =  item.id ? {} : {token:token}
    const url = item.id ? config.get("API_PATH") + config.get("API_MEMO") + item.id  : config.get("API_PATH") + config.get("API_MEMO")
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler,  additional_data)
  },

  delete( token, id,  callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_MEMO") + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

 
}

export default MemoAction
