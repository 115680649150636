
import ReportDriverFeeprops from './ReportDriverFeeProps';

const props = extendValues => class extends ReportDriverFeeprops {};
class ReportDriverFee extends props({}) {



}
export default ReportDriverFee
