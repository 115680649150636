import React, { useEffect, useState } from 'react'
import Utile from '../../../utiles/Utile';
import { format, parseISO,addDays,isSameDay } from 'date-fns';

function BodyYusei(props:PropsValue) {
  
  const [list, setList] = useState(null)
  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())


  useEffect(() => {
   
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ props.repot_list, props.repo_list_updated_at])

  useEffect(() => {
    
    
    if(repot_list ){
        
        var distance_total = 0
        var normal_unit_total  = 0
        var night_unit_total = 0
        var load_unit_total = 0
        var return_unit_total = 0
        var large_unit_total = 0
       // var sales_total = 0

        const _list = new Array(props.days + 1).fill(0).map((d,index) => {

          const target_day = addDays(props.start_date, index)
          const item = repot_list.find(item => isSameDay(parseISO(item.worked_date),target_day ))

          if(item){
            distance_total += item.distance
            normal_unit_total += item.normal_unit
            night_unit_total += item.night_unit
            load_unit_total += item.load_unit
            return_unit_total += item.return_unit
            large_unit_total += item.large_unit
           // sales_total += item.sales
          }
     
          return(
            <tr className={item ? "link" : null} >
               {props.is_public ||!props.has_edit_right  ? null : <td>{item ? <input type="checkbox" checked={props.selected_list.find( s => (item && s === item.id)) ? true : false} onClick={() => props.checkHandler(item.id)}/> : null}</td>}
              <td onClick={() => item? props.changeHandler(item.id) :null}>{format(target_day, "dd")}</td>
              <td onClick={() => item? props.changeHandler(item.id) :null}>{Utile.getWeekString(target_day)}</td>
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_start_at), "HH:mm")}～{format(parseISO(item.work_end_at), "HH:mm")} </td>) : <td></td> }
              {item ? (<td onClick={() => item? props.changeHandler(item.id) :null}>{item.area}</td>): <td></td>}
              {item ? (  <td onClick={() => item? props.changeHandler(item.id) :null}>{item.distance} <span className="km">km</span></td>): <td></td>}
              {item ? (  <td onClick={() => item? props.changeHandler(item.id) :null}>{item.normal_unit}<span className="yen_mark">個</span></td>): <td></td>}
              {item ? (  <td onClick={() => item? props.changeHandler(item.id) :null}>{item.night_unit}<span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (  <td onClick={() => item? props.changeHandler(item.id) :null}>{item.load_unit}<span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (  <td onClick={() => item? props.changeHandler(item.id) :null}>{item.large_unit}<span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (  <td onClick={() => item? props.changeHandler(item.id) :null}>{item.return_unit}<span className="yen_mark" >個</span></td>) : <td></td>}
            
            </tr>
          )
        })
      _list.push(
        <tr>
          <td colspan={props.is_public ||!props.has_edit_right ?  4 : 5}  class="report_subtotal">合計</td>
          <td>{distance_total.toLocaleString()}<span class="km">km</span></td>
          <td>{normal_unit_total.toLocaleString()}<span class="overtime_mark">個</span></td>
          <td>{night_unit_total.toLocaleString()}<span class="overtime_mark">個</span></td>
          <td>{load_unit_total.toLocaleString()}<span class="overtime_mark">個</span></td>
          <td>{large_unit_total.toLocaleString()}<span class="overtime_mark">個</span></td>
          <td>{return_unit_total.toLocaleString()}<span class="overtime_mark">個</span></td>

        </tr>)
      setList(_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at,props.selected_at])




  return(
    <table>
      <tbody>
      <tr>
      {props.is_public ||!props.has_edit_right  ? null : <th><input type="checkbox" onChange={props.checkAll}/></th>}<th>日</th><th>曜</th><th>着車時間～完了時間</th><th>配達エリア</th><th>距離</th><th>普通</th><th>夜間便</th><th>集荷</th><th>大型</th><th>持戻り</th>
      </tr>
      {list}
      </tbody>
    </table>
    
  )
}

export default BodyYusei



/**
 *  {item ? (<td><input type="time" className="report_time" value={format(parseISO(item.work_start_at), "HH:mm")} name="work_start_at" onChange={(e) => props.changeTimeHandler(e, item.id)} />～<input type="time" value={format(parseISO(item.work_end_at), "HH:mm")} name="work_end_at" onChange={(e) => props.changeTimeHandler(e, item.id)} className="report_time" /></td>) : <td></td> }
              {item ? (<td><input type="text" className="report_erea" value={item.area} name="area" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
              {item ? (  <td><input type="number"  className="report_distance" value={item.distance}  name="distance" onChange={(e) => props.changeHandler(e, item.id)} /><span className="km">km</span></td>): <td></td>}
              
              {item ? (  <td><input type="number" className="" value={item.normal_unit}  name="normal_unit" onChange={(e) => props.changeHandler(e, item.id)}  /><span className="yen_mark">個</span></td>): <td></td>}
              {item ? (  <td><input type="number" className="" value={item.night_unit}  name="night_unit" onChange={(e) => props.changeHandler(e, item.id)}  /><span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (  <td><input type="number" className="" value={item.load_unit}   name="load_unit" onChange={(e) => props.changeHandler(e, item.id)} /><span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (  <td><input type="number" className="" value={item.large_unit}   name="large_unit" onChange={(e) => props.changeHandler(e, item.id)} /><span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (  <td><input type="number" className="" value={item.return_unit}   name="return_unit" onChange={(e) => props.changeHandler(e, item.id)} /><span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (    <td><span className="yen_mark">&yen;</span><input type="number"   name="sales" onChange={(e) => props.changeHandler(e, item.id)}  className="report_reimbursement" value={item.sales}  /></td>): <td></td>}
 */