import React, {  } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

function ClientCreateSuccess(props:PropsValue) {

  const [searchParams] = useSearchParams();
  const updated_str = searchParams.get("updated") ?  "クライアント更新" : "新規クライアント登録" ;


  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
                <h2 className="page_title">{updated_str}</h2>
                <Breadcrumbs pages={[new LinkProps("クライアント一覧","/client"),new LinkProps(updated_str,"#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">{updated_str} 完了</p>
                <p>{updated_str}が完了しました。</p>
									
							</div>
						</div> 
				</div>
		</div>

  )
}

export default ClientCreateSuccess

