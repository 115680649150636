import { useState } from 'react';
import AuthAction from './AuthAction';

function useAuthState() {

  
  const [error, setError] = useState(null)
  const [token, setToken] = useState(null)

  const completedHandler = (data) => {
  
    setToken(data)

  }

  const errorHandler = (data) => {

    setError(data)
  
  }

  const getToken = () => {
    AuthAction.getToken(completedHandler, errorHandler)
  }
  

  return { token:token,  auth_error:error, getToken:getToken};
}

export default useAuthState