import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import DriverDelete from "./DriverDelete"
import useDriverState from './useDriverState';

function DriverDeleteApp(props:PropsValue) {
  const { id } = useParams();
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {driver, driver_error, driver_deleted, getDriver, deleteDriver} = useDriverState()
 
  

  useEffect(() => {
    if(driver){
      setItem(driver)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver])


  useEffect(() => {
    if(token){
      getDriver(token, id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(driver_deleted){
      window.location.href = "/driver/success/deleted"
    }
  },[driver_deleted])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = () => {
    deleteDriver(token, id)
  }
  
  return(
    <div id="home">
      <PageApp current_page="driver" content={<DriverDelete token={token} driver={item} error={driver_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default DriverDeleteApp

