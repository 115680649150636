import React, {  } from 'react'
import UserDetailItem from './UserDetailItem'
import config from 'react-global-configuration';


function UserDetailOverlay(props:PropsValue) {



  const btnClickHandler = () => {
    window.location.href = "/user/" + props.user.id  + "?new=1"
  }
 
  const btnDeleteHandler = () => {
    window.location.href = "/user/" + props.user.id + "/delete"
  }
  
  return(
    <div className="modal-window" data-id="modal1" >
    <button className="js-close button-close" onClick={props.closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail">
            <div className="column-left">
              <UserDetailItem user={props.user} is_edit={false}/>
            </div>

          </div>
          { props.profile && props.profile.user_type === config.get("USER_FINANCE") ? 
            <div className="button_wrap center">
              <button className="textbutton" onClick={btnDeleteHandler}>削除</button>
              <button className="red" type="button" onClick={btnClickHandler}>編集</button>
            </div>
            : null
          }
        
        </div>
  </div>
  
    
  )
}

export default UserDetailOverlay   

