
import 'whatwg-fetch'
import Error from './Error';


const Action =  {


  fetchJson( path, method, header, body=null,  callbackHandler = null, errorHandler = null, response_data = {}){


    fetch(path  , {
      method: method,
      headers: header,
      body: (method === "POST" || method === "PATCH") &&  body ? body : null
    }).then(function(response) {
     
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 400){
        return {"error":400}
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return {"error":1, "data":response.json()}
         } else {
           return {"error":response.status}
        }
      }else{
        return {"error":0}
      }
    }).then(function(data) {
   
      if(data && !data.error && callbackHandler){
   
        response_data.data = data
        callbackHandler(response_data)
      }else{
        if(data && data.error === 1){
          //message有の場合
          data.data.then(function(error){
            errorHandler(error)
          })
          
        }else if(errorHandler){
          errorHandler(data)
        }else{
          console.log("error")
        }
      }
    })
  },
  

  fetchAuthorizedJson( path, token, method, header, body=null,  callbackHandler = null, errorHandler = null, response_data = {}){
   
    if(!token) {
      
      return false
    }

    header.Authorization =  'Bearer ' + token

    fetch(path  , {
      method: method,
      headers: header,
      body: body ? body : null
    }).then(function(response) {
      
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return {body:response.json(),headers:response.headers}
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return {"error":1, "data":response.json()}
         } else {
           return {"error":response.status}
        }
        
      }else{
        return {"error":response.status}
      }
    }).then(function(data) {  
     
      if(data && !data.error && callbackHandler){
      
        data.body.then(function(r) {
          
          response_data.data = r
          response_data.headers = data.headers
          callbackHandler(response_data)
        })
       
      }else{
        if(data && data.error === 1){
          //message有の場合
          data.data.then(function(error){
            errorHandler(error)
          })
          //
          
        }else if(data && data.error === 401){
          console.log("auth error")
          Error.authError()
          
        }else if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },

  

  

  fetchAuthorizedDelete( path, token,  header, callbackHandler = null, errorHandler = null, response_data = {}){

    if(!token) {
      return false
    }

    header.Authorization =  'Bearer ' + token

    fetch(path  , {
      method: "DELETE",
      headers: header
    }).then(function(response) {
      
      if(response.status === 204  ){
        return {"status":1}
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error && callbackHandler){
        response_data.data = data
        callbackHandler(response_data)
      }else{
        if(data && data.error === 403){
          Error.authError()
        }
        if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },


  createMedia(path, form, callbackHandler = null, errorHandler = null, response_data = {}){

   
    fetch(path, {
      method:  "POST",
      body: form
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
           return response.json()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 403){
        return  {"error":413}
      }else{
        throw new Error('Network response was not ok.');
      }
    }).then(function(data) {
      if(data && !data.error && callbackHandler){
        response_data.data = data
        callbackHandler(response_data)
      }else{
        if(data && data.error === 403){
          Error.authError()
        }
        if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },


  getMedia(token, path, callbackHandler = null, errorHandler = null){

    var header = {}
    header.Authorization =  'Bearer ' + token

    fetch(path, {
      method:  "GET",
      headers: header
    }).then(function(response) {
      if(response.status === 200  ){
        const contentType = response.headers.get("content-type");
        if (contentType && (contentType.indexOf("image/jpeg") !== -1 ||  contentType.indexOf("application/pdf") !== -1  || contentType.indexOf("image/png") !== -1)) {
           return response.blob()
         } else {
           return {"error":response.status}
        }
      }else if(response.status === 401){
        return {"error":401}
      }else if(response.status === 413){
        return  {"error":403}
      }else if(response.status === 500){
        return  {"error":500}
      }

    }).then(function(data) {
      if(data && !data.error && callbackHandler){
        callbackHandler(data)
      }else{
        if(data && data.error === 403){
          Error.authError()
        }
        if(errorHandler){
          errorHandler(data)
        }
      }
    })
  },




  logout(){
    console.log("logout")
  }
  

}

export default Action
