import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { PageApp } from '../../stories/PageApp'
import User from "./User"
import UserProps from './UserProps';
import useUserState from './useUserState';

function UserDetailApp(props:PropsValue) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const is_new = searchParams.get("new") ?  searchParams.get("new") : 0 ;
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)
  const [has_cookie, setHasCookie] = useState(false)
  const {user, hasUserCookie, saveOnCookie,getUserDetail,loadFromCookie, removeUserCookie} = useUserState()

  useEffect(() => {

    if(token){
     
      setHasCookie(hasUserCookie())
      if(is_new === 0 && hasUserCookie()){
        loadFromCookie()
      }else{
        if(id === "new"){
          setItem(new UserProps())
        }else{
          removeUserCookie()
          getUserDetail(token, id)
        }
      }      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  

  useEffect(() => {
    if(user){
      if(id !== "new" && Number(user.id) !== Number(id)){
        getUserDetail(token, id)
      }else{
        setItem(new UserProps(user))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user])

  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    saveOnCookie(_item)
    window.location.href = "/user/confirm"
  }

  const saveOnCookieHandler = (item) => {
    saveOnCookie(item)
    window.location.href = "/user"
  }

  
  
  return(
    <div id="home">
      <PageApp current_page="user" content={<User  has_cookie={has_cookie} user={item} updateHandler={updateHandler} loadFromCookie={loadFromCookie} saveOnCookieHandler={saveOnCookieHandler} />} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default UserDetailApp

