import React, {  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import DriverCreateSuccess from "./DriverCreateSuccess"
import DriverDeleteSuccess from './DriverDeleteSuccess';

function DriverSuccessApp(props:PropsValue) {
  const { id } = useParams();
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      //setToken(e.token)
    }
  }

  return(
    <div id="home">
      <PageApp current_page="driver" content={id === "deleted" ?  <DriverDeleteSuccess /> :<DriverCreateSuccess />} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default DriverSuccessApp

