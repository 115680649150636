import { useState } from 'react';
import MonthlyReportAction from './MonthlyReportAction';
import {subMonths,getYear,getMonth,getDate, format} from "date-fns"
import MonthlyReport from './MonthlyReport';
import Report from '../report/Report';
import ReportAmazon from '../report/report_category/ReportAmazon';
import ReportYamato from '../report/report_category/ReportYamato';
import ReportSpot from '../report/report_category/ReportSpot';
import ReportCharter from '../report/report_category/ReportCharter';
import ReportCorporate from '../report/report_category/ReportCorporate';

import useReportState from '../report/useReportState';


function useMonthlyReportState() {

  
  const [error, setError] = useState(null)
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState([])
  const [total_number, setTotalNumber] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)

  const {getHighwayCost, getParkingCost, getOtherCost} = useReportState()

  const listLoadeddHandler = (data) => {
   
    setList(data.data)
    setTotalNumber(data.headers.get("total-number"))
    setListUpdatedAt(new Date())
  }

  

  const loadedHandler = (data) => {
    setItem(data.data)
  }
  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  }
  
  const hasCookie = () => {
    return MonthlyReportAction.hasCookie()
  }

  const removeCookie = () => {
    MonthlyReportAction.removeCookie()
  }

  const saveOnCookie = (item) => {
    MonthlyReportAction.saveOnCookie(item)
  }

  const loadFromCookie = () => {
    MonthlyReportAction.loadFromCookie( loadedHandler, errorHandler)
  }
  

  const getList = (token,  project_id, driver_id, report_type, year = null, month = null) => {

    MonthlyReportAction.getList(token, 0, project_id, driver_id, report_type, year, month, listLoadeddHandler, errorHandler)
  }

  const create = (token, project_id, driver_id, project_driver_id, item) => {

    MonthlyReportAction.create(token, project_id, driver_id,  project_driver_id, item, createdHandler, errorHandler)
  }



  const getDetail = (token, id) => {
    MonthlyReportAction.getDetail(token, id, loadedHandler, errorHandler)
  }

  const deleteItem = (token,project_id, driver_id, id) => {
    MonthlyReportAction.delete(token, id, createdHandler, errorHandler)
  } 

  const hasLatestRerpot = (list, end_date) => {
    const target = getTargetYearAndMonth(end_date)
    const target_year = target[0]
    const target_month = target[1]

    //find from list
    var is_find = false 
    for(var i = 0; i < list.length; i++){
      const _item = list[i]
      
      if(Number(_item.year) === Number(target_year) && Number(_item.month) === Number(target_month)){
        is_find = true 
        break;
      }
    }
    return is_find
  }

  const clearList = () => {
    setList([])
  }

  const getTargetYearAndMonth = (end_date) => {
    const today = new Date()
    var target_year = 0
    var target_month = 0 
    const prev_month_date = subMonths(today, 1)

    if(end_date === 0) {
      target_year = getYear(prev_month_date)
      target_month = getMonth(prev_month_date) + 1
    }else {
      if(getDate(today) > end_date ){
        target_year = getYear(today)
        target_month = getMonth(today) + 1
      }else{
        target_year = getYear(prev_month_date)
        target_month = getMonth(prev_month_date) + 1
      }
    }
    return [target_year,target_month]
  }

  const deleteSearchParams= (item) => {
    MonthlyReportAction.deleteSearchParams(item)
  }

  const saveSearchParams= (item) => {
    MonthlyReportAction.saveSearchParams(item)
  }

  const loadSearchParams = () => {
    return MonthlyReportAction.loadSearchParams()
  }
  

  const createListByReportList = (year, month, project, report_list, project_driver_list) => {
   
   // var m_w_date = this.set("year",year).set("month",month) 
   
  
    const _target_report = report_list.filter(report => Number(report.project_id) ===  Number(project.id))
    const target_project_drivers = project_driver_list.filter(project_driver => Number(project_driver.project_id )===  Number(project.id))
    const target_project_driver = target_project_drivers[0]
   
   
    //スポットの時
    if(project.category === 0){
   
      const monthly_report_list = _target_report.map(spot_repot => {
        if((new Date(spot_repot.worked_date).getMonth() + 1 ) !== Number(month)) return null
        const highway_cost = getHighwayCost(spot_repot.receipts,spot_repot.fee_information ? spot_repot.fee_information.highway_fee : 0)
        const parking_cost = getHighwayCost(spot_repot.receipts,spot_repot.fee_information ? spot_repot.fee_information.parking_fee : 0)
        const other_cost = getHighwayCost(spot_repot.receipts,spot_repot.fee_information ? spot_repot.fee_information.other_fee : 0)
        
        return new MonthlyReport( {
          year:Number(year), 
          month:Number(month), 
          date:new Date(spot_repot.worked_date).getDate(),
          unit_price:spot_repot.fee_information ? spot_repot.fee_information.unit_price : null,
          total_number:1,
          driver:{name:spot_repot.driver_name},
          project:project,
          content:spot_repot.detail,
          highway_cost:highway_cost,
          parking_cost:parking_cost,
          other_cost:other_cost,
          url: "/project/" + project.uid + "/monthly_report/" + target_project_driver.uid + "/" + year + "/" + month,
          updated_at:spot_repot.updated_at
        }) 
      }).filter(item => item !== null)
   
      const new_list = list.concat(monthly_report_list)

      setList(new_list)
      setListUpdatedAt(project.id + "/" +format(new Date(), "yyMMddhhmmss"))
    }else{
      //定期の時
      
      //ProjectのReport型
      var r = new Report(project)
      switch(Number(project.category)){
        case 1: r =  new ReportCorporate(project)
          break
        case 2 : r =  new ReportCharter(project)
          break;
        case 3: r =  new ReportAmazon(project)
          break
        case 4: r =  new ReportYamato(project)
          break
    
        default: r =  new ReportSpot(project)
      }
  
      //該当の月のレポートを抽出 

      const target_report = _target_report.map(repo => {
        if(r.isInMonth(new Date(repo.worked_date), year, month)) return repo
        return null
      }).filter(a => a !== null)
      

      if(target_report.length === 0) return null

      //金額ごとのに分ける
      const unit_price_origin = target_project_driver.fee_information ? Number(target_project_driver.fee_information.unit_price) : 0
      const _unit_price_list = target_report.map(report => {
        const addtional_price = report.fee_information ? Number(report.fee_information.unit_price) : 0
        return (unit_price_origin + addtional_price)
      })
      const set = new Set(_unit_price_list);
      const unit_price_list = [...set];

      const units = unit_price_list.map(unit_price => {
        const total_number = target_report.filter(repo => (Number(unit_price_origin) + Number(repo.fee_information && repo.fee_information.unit_price ? repo.fee_information.unit_price : 0)) ===  ( Number(unit_price) )).length
    
        return {unit_price:unit_price, total_number:total_number}
      })


      
      const over_time = target_report.map(report => {return(r.getOverTimeHour(new Date(report.work_start_at), new Date(report.work_end_at), report.fee_information ? report.fee_information.over_time : 0))}).reduce(function(sum, element){
        return sum + element;
      }, 0)

      const over_distance = target_report.map(report => {return(r.getOverDistance(report.distance, report.fee_information ? report.fee_information.over_distance : 0))}).reduce(function(sum, element){
        return sum + element;
      }, 0)


      const highway_cost = target_report.map(repo => {
        return getHighwayCost(repo.receipts,repo.fee_information ? repo.fee_information.highway_fee : 0)
      }).reduce(function(sum, element){
        return sum + element;
      }, 0);
      
      const parking_cost = target_report.map(repo => {
        return getParkingCost(repo.receipts,repo.fee_information ? repo.fee_information.parking_fee : 0)
      }).reduce(function(sum, element){
        return sum + element;
      }, 0);


      const other_cost = target_report.map(repo => {
        return getOtherCost(repo.receipts,repo.fee_information ? repo.fee_information.other_fee : 0)
      }).reduce(function(sum, element){
        return sum + element;
      }, 0);

      //最終更新
      const last_updated_at_report = target_report.reduce((a,b)=>a.updated_at>b.updated_at?a:b)
   

      const monthly_report =  new MonthlyReport( {
        year:Number(year), 
        month:Number(month), 
        unit_price:unit_price_list.length > 0 ? unit_price_list[0] : 0,
        total_number:target_report.length,
        total_number_yamato_normal:target_report.map(item=>Number(item.normal_unit)).reduce(function(sum, element){
          return sum + element;
        }, 0),
        total_number_yamato_neko:target_report.map(item=>Number(item.nekopos_unit)).reduce(function(sum, element){
          return sum + element;
        }, 0),
        total_number_yamato_dm:target_report.map(item=>Number(item.dm_unit)).reduce(function(sum, element){
          return sum + element;
        }, 0),

        total_number_yusei_normal:target_report.map(item=>Number(item.normal_unit)).reduce(function(sum, element){
          return sum + element;
        }, 0),
        total_number_yusei_big:target_report.map(item=>Number(item.large_unit)).reduce(function(sum, element){
          return sum + element;
        }, 0),
        total_number_yusei_night:target_report.map(item=>Number(item.night_unit)).reduce(function(sum, element){
          return sum + element;
        }, 0),
        total_number_yusei_collect:target_report.map(item=>Number(item.load_unit)).reduce(function(sum, element){
          return sum + element;
        }, 0),

        highway_cost:highway_cost,
        parking_cost:parking_cost,
        other_cost:other_cost,

        over_time_min:over_time,
        over_distance:over_distance,
        driver:target_project_driver.driver,
        project:project,
        project_driver:target_project_driver,
        content:JSON.stringify(target_report),
        url: "/project/" + project.uid + "/monthly_report/" + target_project_driver.uid + "/" + year + "/" + month,
        updated_at:last_updated_at_report.updated_at,
        units:units
      }) 
      
      list.push(monthly_report)
      setList(list)

      setListUpdatedAt(project.id + "/" +format(new Date(), "yyMMddhhmmss"))
    } 

  }




  return { monthly_report:item, monthly_report_list:list, monthly_report_list_updated_at:list_updated_at,  monthly_list_updated_at:list_updated_at, monthly_report_error:error, monthly_report_deleted:created, monthly_report_created:created, monthly_repot_total_number:total_number,
    getMontylyReportList:getList, getMontylyReport:getDetail, createMonthlyReport:create, deleteMonthlyReport:deleteItem, 
    saveOnCookie:saveOnCookie, loadFromCookie:loadFromCookie, hasCookie:hasCookie, removeCookie:removeCookie,
    hasLatestRerpot, getTargetYearAndMonth,saveSearchParams, loadSearchParams, deleteSearchParams, createListByReportList:createListByReportList,
    clearMonthlyReportList:clearList
  };
}

export default useMonthlyReportState