import { useState } from 'react';
import cookie from 'react-cookies'

import ProfileAction from './ProfileAction';
import Profile from './Profile';

function useProfileState() {

  
  const [error, setError] = useState(null)
  const [profile, setProfile] = useState(new Profile())

  const completedHandler = (data) => {
    if(data){
      cookie.save('profile', data.data)
      setProfile(new Profile(data.data))
    }
  }

  const errorHandler = (data) => {

    setError(data)
  
  }

  const get = (token) => {
    ProfileAction.getProfile(token, completedHandler, errorHandler)
  }
  

  return { profile:profile,  profile_error:error, getProfile:get};
}

export default useProfileState