
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action'

const ProfileAction = {

  getProfile( token, callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_PROFILE") , token, "GET", {}, null,  callback, errorHandler)
  },


  get( token, callback, errorHandler){
    var item =  cookie.load("profile")
   
    if(item)
      callback(item)
    else
    ProfileAction.getProfile(token, callback, errorHandler)
  },


 
}

export default ProfileAction
