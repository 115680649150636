import React, {useState, useEffect  } from 'react'
import { useSearchParams } from "react-router-dom";
import { PageApp } from '../../stories/PageApp'
import ClientList from "./ClientList"
import useClientState from './useClientState';

function ClientListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?  searchParams.get("page") : 1 ;
  const [token, setToken] = useState(null)
  const [list, setList] = useState([])
  const [total_number, setTotalNumber] = useState(1)
  const {client_list, list_updated_at,client_total_number,client_search_limit, getClientList} = useClientState()
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    if(client_total_number > 1){
      setTotalNumber(client_total_number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[client_total_number])


  useEffect(() => {
    setList(client_list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])

  useEffect(() => {

    if(token){
      //GET LIST
      getClientList(token,page, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  const searchHandler = (e) => {
    getClientList(token,page, e)
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
  }




  return(
    <div id="home">
      <PageApp current_page="client" content={<ClientList token={token} profile={profile} clients={list} search_limit={client_search_limit} total_number={total_number} current_page={page} search={searchHandler}/>} authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler}/>
    </div>
     
  )
}

export default ClientListApp

