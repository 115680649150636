
import Report from "../Report"

class ReportCorporate  extends Report{



  return_unit ?= "持戻個数"
  delivery_unit ?= "持出個数"
  load_unit ?= "便数（往復回数）"
  
  area ?= undefined
  detail ?= undefined
  meter_start ?= undefined
  meter_end ?= undefined

  
  compleated_unit ?= undefined
  nekopos_unit ?= undefined
  dm_unit ?= undefined
  normal_unit ?= undefined
  night_unit ?= undefined
  large_unit ?= undefined
  waiting_hour ?= undefined

  received_gender ?= undefined
  received_name ?= undefined
  driver_name ?= undefined
  
}

export default ReportCorporate

