import React, {useEffect} from 'react';


interface DeleteWindowProps {
  message:String,
  executeHandler:Function,
  closeHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const DeleteWindow = ({
  message,
  executeHandler,
  closeHandler,
  ...props
}: DeleteWindowProps) => { 

  

  useEffect(() => {
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className="delete-window" data-id="modal1" >
      <div className="message">{message}</div>
      <div className="button_wrap center">
        <button  type="button" onClick={closeHandler}>キャンセル</button>
        <button className="red" type="button" onClick={executeHandler}>削除</button>
      </div>
    </div>
  );
};

