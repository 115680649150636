import React, {useState ,useEffect} from 'react';
import config from 'react-global-configuration';
import Receipt from '../../../receipt/Receipt';
interface ReceiptBoxProps {

  receipt:Receipt,
   changeHandler:Function,

}

/**
 * Primary UI component for user interaction
 */
export const ReceiptBox = ({
  //current_page,
  receipt,
  changeHandler,
  ...props
}: ReceiptBoxProps) => { 


  const [item, setItem] = useState(null)
  const [error, setError] = useState(null)
  useEffect(() => { 
    setItem(receipt)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[receipt])


  const select_op = config.get("TATEKAE_TYPE").map(op => {
    return(
      <option value={op.value} >{op.label}</option>
    )
  })


  const receiptChangeHandler =(e, index) =>{
    const target = e.target
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  }


  const receiptFileChangeHandler =(e) =>{
    const _file = e.target.files[0]
    const new_item = item.set("file", _file)
    setItem(new_item)
  }
  
  const btnClickHandler = () => {
  
      if(item.price || item.description || item.file ){
        if(!item.price ){
          setError(<div>金額が未記入です</div>)
        }else if( !item.description){
          setError(<div>項目が未記入です</div>)
        }else{
          changeHandler(item)
        }
      }
  
  }

  return (
  <div className="receipt-item">
     <div>
      <select name="description"  placeholder="領収書項目を記載　例)駐車場代" onChange={(e => receiptChangeHandler(e))} >
          <option value="" selected="">選択してください</option>
          {select_op}
      </select>
    </div>
    <div><input type="number" name="price" placeholder="金額 (円）" onChange={(e => receiptChangeHandler(e))}/></div>
    <div><input type="file" name="file" accept="image/*, application/pdf" onChange={(e => receiptFileChangeHandler(e))} /> </div>
    <div className='error'>{error}</div>
    <button className="red" type="button" onClick={btnClickHandler}>追加</button>
  </div>
  );
};

