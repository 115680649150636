import React, { useEffect, useState } from 'react'
import useDriverState from '../../drivers/useDriverState';
import UserDetailItem from '../../drivers/DriverDetailItem';
import Driver from '../../drivers/Driver';
function ProjectDriverOverlay(props:PropsValue) {

  const [driveritem, setDriver] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const {driver, driver_created,  createDriver} = useDriverState()
  
  useEffect(() => {
    if(props.type === "confirm"){
      
    }else{
      if(props.partner_id){
        const _driver = (new Driver({partner_id:props.partner_id.value}))
        setDriver(_driver)
      } 
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.type])


  useEffect(() => {
    if(driver_created){
      props.createdHandler(driver)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver_created])
  
  const changeHandler = (data) => {
    setDriver(data)
  }

  const btnConfirmHandler = (e) => {
    const _errors = driveritem.validate()
    if(_errors.length >0 ){
      setErrorMessage(_errors.map(item => <div>{item}</div>))
    }else{
      createDriver(props.token, driveritem)
    }
  }

  return(
    <div className="modal-window" data-id="modal1" >
    <button className="js-close button-close" onClick={props.closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail">
            {
              props.type === "confirm" ? null : <UserDetailItem changeHandler={changeHandler} token={props.token}  driver={driveritem} is_edit={true}/>
            }        
          </div>
          {error_message ? <div className="input_wrap"> <div className="error">{error_message} </div> </div> : null }
          <div className="button_wrap center">
              <button className="red" type="button" onClick={btnConfirmHandler}>新規作成</button>
          </div>
        </div>
  </div>
  
    
  )
}

export default ProjectDriverOverlay

