import React, { useEffect,useState } from 'react'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import Partner from '../../partners/Partner'

function HeaderDefault(props:PropsValue) {

  /**
   * year
   * mnth
   * project
   * project_driver
   * report list
   */
  
  const [target_date, setTargetDate] = useState(null)
  const [partner, setPartner] = useState(null)  
  const [payment_date, setPaymentDate] = useState(null)
  const [company, setCompany] = useState(null)


  useEffect(() => {
    if(props.driver ){  
      setPaymentDate(props.driver.getPaymentExecuteDate(new Date(props.year, (props.month -1), 1, 0, 0, 0)))
    }else
    if(partner){
      setPaymentDate(partner.getPaymentExecuteDate(new Date(props.year, (props.month -1), 1, 0, 0, 0)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner,props.driver])


  useEffect(() => {
    if(props.company){
      setCompany(props.company)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.company])


  useEffect(() => {
    setPartner(new Partner(props.partner))
    const _target = lastDayOfMonth(new Date(props.year, (props.month -1), 1, 0, 0, 0))
    setTargetDate(_target)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.projects])
  
   const getInvoiceNum = () => {
      if(props.driver && props.driver.invoice) return "登録番号 " + props.driver.invoice
      if(props.partner && props.partner.invoice_id) return "登録番号 " + props.partner.invoice_id
      return null
   }

  return(
    <div>
       <h3 className="report_ttl center">支払明細書</h3>
        <p className="report_manth center"><input type="text" className="in01" value={target_date ? target_date.getFullYear() : null} />年<input type="text" className="in01" value={target_date ?( target_date.getMonth()  + 1) : null} />月 <input type="text" className="in01" value={target_date ? target_date.getDate() : null} />日</p>
        <div className="payment-report-box">
          <div className="report_casename_box">
            <div className="report_casename">{props.driver ? props.driver.name : props.partner ? props.partner.name : null}様</div>
            <div>{getInvoiceNum()}</div>
            <div className="bank_info">
              <div>{props.driver ?  props.driver.bank_info ? props.driver.bank_info.account_name : null : partner && partner.bank_info ? partner.bank_info.account_name : null}</div>
              <div>{props.driver ? props.driver.bank_info ? props.driver.bank_info.bank_name : null : partner && partner.bank_info ? partner.bank_info.bank_name : null} { props.driver && props.driver.bank_info ? props.driver.bank_info.branch_name : partner && partner.bank_info ? partner.bank_info.branch_name : null}</div>
              <div>{props.driver ? props.driver.bank_info ? props.driver.bank_info.account_type : null :partner && partner.bank_info ? partner.bank_info.account_type : null} { props.driver && props.driver.bank_info ? props.driver.bank_info.account_id : partner && partner.bank_info ? partner.bank_info.account_id : null}</div>

            </div>
          </div>

          <div className="hnj-box">
    
              <p className="name">{company ? company.name : null}</p>
              <p>{company ? company.address_info.address_1 : null}</p>
              <p>{company ? company.address_info.address_2 : null}</p>
              <p>TEL {company ? company.address_info.tel : null}  FAX {company ? company.address_info.fax : null}</p>
              <p>登録番号　{company ? company.invoice_id : null}</p>
    
          </div>

        </div>
        <div className="report_period-box">
          <div className="report_period">お振込金額 <div className="total">¥{props.total !== null ? props.total.toLocaleString() : null }</div></div>
          <div className="report_payment_exe">支払期日:  {payment_date}</div>
        </div>
     
		</div>
    
  )
}

export default HeaderDefault

