import React, { useEffect, useState } from 'react'

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { setMinutes, setHours,differenceInDays,lastDayOfMonth,addDays,subMonths,format} from 'date-fns';
import HeaderDefault from './header/HeaderDefault';
import BodyDefault from './body/BodyDefault';
import useReportState from '../report/useReportState';
import useProjectState from '../project/useProjectState';

function MonthlyReceiptDetail(props:PropsValue) {


  
  const [repot_list , setReportList] = useState([])

  const [header, setHeader] = useState(null)
  const [body, setBody]= useState(null)
  const { getEndDateByType} = useProjectState()
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())
  
  const {report_list, getReportList,report_created}= useReportState()

  useEffect(() => {
    setReportList(props.report_list)
    setRepoListUpdatedAt(new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ report_list])



  useEffect(() => {
  
    if(props.start_date && props.end_date && props.driver && props.project && repot_list){
    
        const days =  differenceInDays(props.end_date, props.start_date)
        const _end_date = getEndDateByType(props.project.closing_date)
      
        var start_date = null 
        var end_date = null 
        if(_end_date === 0 ){
            const target_date = new Date(props.year, props.month-1, 1)
            start_date = target_date
            end_date = lastDayOfMonth(target_date)
        }else{
            const target_date = new Date(props.year, props.month-1, _end_date)
            start_date = addDays(subMonths(target_date,1),1)
            end_date = target_date
        }


        setHeader(<HeaderDefault year={props.year} total_price={props.total_price}  month={props.month} project={props.project}   driver={props.driver} repot_list={repot_list} />)
        setBody(<BodyDefault year={props.year} days={days} total_price={props.total_price} month={props.month} project={props.project} start_date={props.start_date} end_date={props.end_date}  repot_list={repot_list} repo_list_updated_at={repo_list_updated_at} changeHandler={changeHandler} changeStringHandler={changeStringHandler} changeTimeHandler={changeTimeHandler}/>)
     
        getReportList(props.token,1, 2,props.project.id, props.driver.id, format(start_date, "yyyy-MM-dd"), format(end_date,"yyyy-MM-dd"))

    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.driver , props.project, report_created])



  const btnClickHandler = () => {
    props.updateHandler(repot_list)
  }


  const changeHandler = (e, id) => {
    const index = repot_list.findIndex(item => item.id === id)
    const target = e.target
    repot_list[index] = repot_list[index].set(target.name , Number(target.value))
    setReportList(repot_list)
    setRepoListUpdatedAt(new Date())
    //setItem(item.set(target.name, target.value))
  } 

  const changeStringHandler = (e, id) => {
    const index = repot_list.findIndex(item => item.id === id)
    const target = e.target
    repot_list[index] = repot_list[index].set(target.name , target.value)
    setReportList(repot_list)
    setRepoListUpdatedAt(new Date())
  } 


    const changeTimeHandler = (e, id) => {
      const index = repot_list.findIndex(item => item.id === id)
      const target = e.target
      const time =  target.value.split(":")
      const target_datetime = target.name === "work_start_at"  ? repot_list[index].work_start_at : repot_list[index].work_end_at 
      repot_list[index] = repot_list[index].set(target.name , setMinutes(setHours(target_datetime, time[0]), time[1] ))
      setReportList(repot_list)
      setRepoListUpdatedAt(new Date())
    } 




  
  return(
    <div className="inner">
       <form >
			  <div className="content_wrap sub_narrow">
					    <div className="page_title_wrap">
								<h2 className="page_title">立替清算・編集</h2>
                <Breadcrumbs pages={[new LinkProps(props.project ?  props.project.name + " 立替清算・編集 "  : "立替清算・編集" ,"#")]} />
							</div>
							<div className="report_body ">

                {header}
              
                {body}
              </div>  
              <div className="makereport">
                <button className="red" type="button" onClick={btnClickHandler}>この内容で立替精算書生成</button>
                <p className="attention">※この画面で編集した内容は日報には反映されません。</p>
              </div>
				</div>
       </form>
		</div>
    
  )
}

export default MonthlyReceiptDetail

