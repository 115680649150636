import React, { useEffect, useState,  } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

function UserDelete(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)

  useEffect(() => {
    setItem(props.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.user])

 

  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler()
  }




  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">ユーザー削除</h2>
                <Breadcrumbs pages={[new LinkProps("ユーザー削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">ユーザー削除</p>
                  {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
									<div className="input_wrap">
										
										<table className="input_table col12 login_tbl">
											<tbody>
												<tr>
													<th className="tl">ユーザー種別</th>
													<td><label className="radio">{item ? config.get("USER_TYPE_" + item.user_type) : null}</label></td>
												</tr>
												<tr>
													<th className="tl">ユーザー名</th>
													<td>{item ? item.name : null}</td>
												</tr>
												<tr>
													<th className="tl">フリガナ</th>
													<td>{item ? item.furigana : null}</td>
												</tr>
												<tr>
													<th className="tl">メールアドレス</th>
													<td>{item ? item.username : null}</td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>{item ? item.note : null}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								

                <div className="button_wrap center">
								
                    <button className="red" type="button" onClick={btnClickHandler}>削除する</button>
               
							  </div>

            


                </div> 
				</div>  
      
		</div>

  )
}

export default UserDelete

