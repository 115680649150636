import { useState } from 'react';

import MemoAction from './MemoAction';

function useMemoState() {

  const [error, setError] = useState(null)
  const [memo, setMemo] = useState(null)
  const [updated_at, setUpdatedAt] = useState(null)
  const [deleted_at, setDeletedAt] = useState(null)

  var time_id = null 

  const completedHandler = (data) => {
    if(data){

      setMemo(data.data)
      setUpdatedAt(new Date())
    }
  }

  const createdHandler = (data) => {
   
    if(data.token){
      MemoAction.get(data.token, data.data.category, data.data.unqueid, completedHandler, errorHandler)
    }else{
      setUpdatedAt(new Date())
    }
  }

  const deletedHandler = () => {
    setDeletedAt(new Date())
  }

  const errorHandler = (data) => {
    setError(data)
  }

  const get = (token, category, uniqueid) => {
    MemoAction.get(token, category, uniqueid, completedHandler, errorHandler)
  }


  const create = (token, item) => {
    MemoAction.create(token, item, createdHandler, errorHandler)
  }

  const createByTime = (token, item) => {
    if(time_id){
      clearTimeout(time_id)
    }
    time_id = setTimeout(function(){

      create(token, item)
    }, 1000)
   // MemoAction.create(token, item, createdHandler, errorHandler)
  }


  const deleteItem = (token, id) => {
    MemoAction.delete(token, id, deletedHandler, errorHandler)
  }

  return { memo,  memo_error:error, memo_updated_at:updated_at, memo_deleted_at:deleted_at , getMemo:get,  createMemo:create, createByTime:createByTime, deleteMemo:deleteItem};
}

export default useMemoState