import React, {  } from 'react'
import ClientDetailItem from './ClientDetailItem'
import ClientDetailLinks from './ClientDetailLinks'
import config from 'react-global-configuration';

function ClientDetailOverlay(props:PropsValue) {

  

  const btnClickHandler = () => {
    window.location.href = "/client/" + props.client.id  + "?new=1"
  }

  const btnDeleteHandler = () => {
    window.location.href = "/client/" + props.client.id + "/delete"
  }
  

  return(
    <div className="modal-window" data-id="modal1" >
    <button className="js-close button-close" onClick={props.closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail">
            <div className="column-left">
              <ClientDetailItem client={props.client} is_edit={false}  />
            </div>
              <ClientDetailLinks client={props.client} token={props.token} />
          </div>
          { props.profile && props.profile.user_type === config.get("USER_FINANCE") ? 
            <div className="button_wrap center">
              <button className="textbutton" onClick={btnDeleteHandler}>削除</button>
              <button className="red" type="button" onClick={btnClickHandler}>編集</button>
            </div>
            : null
          }

        </div>
  </div>
  
    
  )
}

export default ClientDetailOverlay

