import React, {useState,useEffect} from 'react';
import { Record } from 'immutable'

import { DriverSearchBox } from '../driver_search/DriverSearchBox';
import { ProjectSearchBox } from '../project_search/ProjectSearchBox';
import { ParnterSearchBox } from '../partner_search/ParnterSearchBox';
import { ClientSearchBox } from '../client_search/ClientSearchBox';
interface SearchBoxMonthlyReportProps {
   /**
   * content
   */
   token:String,

   /**
    * Form 
    */
   data:?Record,

   params:?Object,
   is_partner:Boolean,
   /**
    * SearchBtnClickHandler
    */
   btnClickHandler:Function,

   clearHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const SearchBoxMonthlyReport = ({
  //current_page,
  token,
  content,
  data,
  is_partner,
  params,
  btnClickHandler,
  clearHandler,
  ...props
}: SearchBoxMonthlyReportProps) => { 
  const today = new Date()
  const [form_css, setFormCss] = useState(null)
  const [form_active, setFormActive] = useState("active")
  const [item, setItem]= useState(Record({year:today.getFullYear(), month:(today.getMonth() + 1), client_id:null, project_id:null, partner_id:null, driver_id:null}))

  const [year , setYear] = useState(today.getFullYear())
  const [month , setMonth] = useState(today.getMonth() + 1)
  const [client_id , setClientId] = useState(null)
  const [project_id , setProjectId] = useState(null)
  const [partner_id , setPartnerId] = useState(null)
  const [driver_id , setDriverId] = useState(null)

  const [year_option, setYearOption] = useState([])
  const [month_option, setMonthOption] = useState([])


  useEffect(() => {
      const selected_year = params ? params.year :today.getFullYear()
      const selected_month = params ? params.month :today.getMonth() + 1
      const duration = today.getFullYear() - 2022
      const _year_option = new Array(duration).fill(0).map((item , index ) => {
        return <option value={2023 + index} selected={(index + 2023) === Number(selected_year) ? true : false}>{2023 + index}年</option>
      })
      _year_option.unshift(<option value={0}>選択してください</option>)
      const _month_option = new Array(12).fill(0).map((item , index ) => {
        return <option value={index + 1} selected={index + 1 === Number(selected_month )? true : false}>{index + 1}月</option>
      })
      _month_option.unshift(<option value={0}>選択してください</option>)
      if(params){
        setYear(params.year)
        setMonth(params.month)
        setClientId(params.client_id)
        setPartnerId(params.partner_id)
        setProjectId(params.project_id)
        setDriverId(params.driver_id)
      }
     
      setYearOption(_year_option)
      setMonthOption(_month_option)
    
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[params])




  const toggleSearch = () => {
    if(form_css){
      setFormCss(null)
      setFormActive("active")
    }else{
      setFormCss("search-hidden")
      setFormActive(null)
    }
  }


  useEffect(() => {
    if(props.params){
      setItem(Record(props.params))  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.params])

  
  const onChangHandler = (e) => {
    const target = e.target 
    if(target.name === "year"){
      setYear(target.value)
    }else if(target.name === "month"){
      setMonth(target.value)
    }
  }

  const clickHandler = () => {
   btnClickHandler({year:year, month:month,client_id:client_id,partner_id:partner_id,driver_id:driver_id,project_id:project_id})
  }



  const onChangeClientSelectHandler = (e) => {
    setClientId(Number(e.value))
    const new_item = item.set("client_id", Number(e.value) )
    setItem(new_item)
  }

  const onChangePartnerSelectHandler = (e) => {
    setPartnerId(Number(e.value))
    const new_item = item.set("partner_id", Number(e.value) )
    setItem(new_item)
  }


  const onChangeDriverSelectHandler = (e) => {
    setDriverId(Number(e.value))
    const new_item = item.set("driver_id", Number(e.value) )
    setItem(new_item)
  }

  const onChangeProjectSelectHandler = (e) => {
    setProjectId(Number(e.value))
    const new_item = item.set("project_id", Number(e.value) )
    setItem(new_item)
  }

  const kedownHandler = (e) => {
    if (e.key === 'Enter') {
      clickHandler()
    }
  }




  return (
    <div className="container more_search">
    <div className="column">
    <p id="more_search_ttl" className={form_active} onClick={toggleSearch}>絞り込み検索</p>
    <form className={form_css} onKeyDown={kedownHandler}>
      <div>
        <div >
           <div>
      
        <div className="input_wrap">
            <label className="">
             
              <span className="select_wrap">
                <select name="year" onChange={onChangHandler} >
                  {
                    year_option
                  }
                </select>
              </span>
            </label>

            <label className="">
             
              <span className="select_wrap">
                <select name="month" onChange={onChangHandler} >
                  {
                    month_option
                  }
                </select>
              </span>
            </label>

            {
              is_partner  ? null : 
            <label className="">
            <span className="label_name">クライアント名</span>
            {props.params ? props.params.client_id : null}
              <ClientSearchBox changeHandler={onChangeClientSelectHandler} token={token} defaultValue={params ? params.client_id : null}/>
            </label>
            }
          </div>
          <div className="input_wrap">
           
          {
              is_partner  ? null : 
          <label className="">
            <span className="label_name">案件名</span>
             <ProjectSearchBox changeHandler={onChangeProjectSelectHandler} token={token} defaultValue={params ? params.project_id : null} with_sport={true} />
            </label>
            }{
              is_partner  ? null : 
            <label className="">
            <span className="label_name">依頼先</span>
             <ParnterSearchBox changeHandler={onChangePartnerSelectHandler} token={token} defaultValue={params ? params.partner_id : null}/>
            </label>
            }{
              is_partner  ? null : 
            <label className="">
            <span className="label_name">ドライバー名</span>
             <DriverSearchBox changeHandler={onChangeDriverSelectHandler} token={token} defaultValue={params ? params.driver_id : null} />
            </label>
}
          </div>

      </div>

      </div>
          <div className="input_wrap"></div>
          <div className="button_wrap center">
            
            <button type="button" onClick={clearHandler}>クリア</button>
            <input type="button" value="検索" className="button red" onClick={clickHandler} />
          </div>
        </div>
        
      </form>
    </div>
  </div>

  );
};


/**
 * 

           
 */