import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import {  addMonths ,addDays, setMinutes, setHours,differenceInDays, parseISO, lastDayOfMonth} from 'date-fns';
import { LoadingWindow } from '../../stories/components/loading_window/LoadingWindow';
import HeaderDefault from './header/HeaderDefault';
import HeaderLogi from "./header/HeaderLogi"
import HeaderYamato from './header/HeaderYamato';
import HeaderYusei from './header/HeaderYusei';
import BodyDefault from './body/BodyDefault';
import BodyLogi from './body/BodyLogi';
import BodyYamato from './body/BodyYamato';
import { DetailWindow } from '../../stories/components/detail_window/DetailWindow';
import ReportProps from '../report/ReportProps';
import BodyYusei from './body/BodyYusei';
import HeaderSpot from './header/HeaderSpot';
import BodySpot from './body/BodySpot';
import ReportObject from '../report/ReportObject';
import { MemoComponent } from '../../stories/memo/MemoComponent';
import MonthlyPayment from '../monthly_payment/MonthlyPayment';

import useProjectState from '../project/useProjectState';
import useReportState from '../report/useReportState';
import useMonthlyReportState from './useMonthlyReportState';
import useMonthlyPaymentState from '../monthly_payment/useMonthlyPaymentState';
import { ChangeAllWindow } from './ChangeAllWindow';

function MonthlyReportDetail(props:PropsValue) {
  const [repot_list , setReportList] = useState([])
  const [header, setHeader] = useState(null)
  const [body, setBody]= useState(null)
  const [message, setMessage]= useState(null)
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())
  const [uniqueid, setUniqueId] = useState(null)

  const [selected_all, setSelectedAll] = useState(false)
  const [selected_list, setSelectedList] = useState([])
  const [selected_at, setSelectedAt] = useState(null)

  const { getEndDateByType} = useProjectState()
  const {report_list, getReportListByMonth,report_created,createReport,getReportUrl, deleteReport}= useReportState()
  const {monthly_report_list} = useMonthlyReportState()
  const {monthly_payment_list, getMontylyPaynmentListByDriver} = useMonthlyPaymentState()
  const [detail, setDetail] = useState(null)
  const [loading, setLoading] = useState(null)
  const [change_all, setChangeAll] = useState(null)
  const [additionalCss, setAdditionalCss] = useState(null)
  const [partner_id, setPartnerId] = useState(null)
  const [has_edit_right, setHasEditRight] = useState(false)
  const url = window.URL || window.webkitURL;



  useEffect(() => {
    if(props.btn){

      if(props.project && props.profile) {
   
        if( props.profile.user_type === config.get("USER_FINANCE")){
          setHasEditRight(true)
        }else{
          //自分がアサインしたドライバーの場合
          const manager_ids = props.project.drivers.map(project_driver => project_driver.manager_id)
          if(manager_ids.findIndex(mid => Number(mid)  === Number(props.profile.id)) >=0 ){
            setHasEditRight(true)
          }
        }
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[ props.profile, props.project, props.btn])


  useEffect(() => {
    
   if(report_list) {
    setReportList(report_list.map(item => new ReportObject(item)))
    setRepoListUpdatedAt(new Date())
   }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ report_list])


  useEffect(() => {
    
    if(monthly_payment_list && monthly_payment_list.length > 0) {
      const latest_monthly_report = new MonthlyPayment(monthly_payment_list[0])
      latest_monthly_report.getWorkedDaysByDriver(1)
    }
     
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[ monthly_payment_list])



  useEffect(() => {

    if(monthly_report_list && monthly_report_list.length > 0) {
      const _list = JSON.parse(monthly_report_list[0].content)
      setReportList(_list.map(item => new ReportObject(item)))
      setRepoListUpdatedAt(new Date())
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[ monthly_report_list])


  useEffect(() => {
    
    setLoading(null)

    if( props.driver && props.project){
      
      loadList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.driver , props.project, report_created])


  const loadList = () => {
  
    const partner_id = props.project.project_type === 1 ? props.driver.partner.id : props.project.sport_partner_id
      
    getMontylyPaynmentListByDriver(props.token,partner_id, props.driver.id,props.year,props.month)
    getReportListByMonth(props.token,props.project.id, props.driver.id,props.year,props.month,1)
    setUniqueId(props.year + "_" + (props.month) + "_" + props.project.id + "_" + props.driver.id)
    setPartnerId(partner_id)
  }

  useEffect(() => {
    if( props.driver && props.project){
      const _end_date = getEndDateByType(props.project.closing_date)
      var start_date = null 
      var end_date = null 
      var target_date = null
      if(_end_date === 0 ){
          target_date = new Date(props.year, props.month-1, 1)
          start_date = target_date
          end_date = lastDayOfMonth(target_date)
      }else{
          target_date = new Date(props.year, props.month-1, _end_date)
          start_date = addDays(target_date,1)
          end_date = addMonths(target_date,1)
      }
      
  
      const days =  differenceInDays(end_date, start_date) 
      

      switch(Number(props.project.report_type)){
        case 1 : 
          setHeader(<HeaderDefault year={props.year}  month={props.month} project={props.project}   driver={props.driver} repot_list={repot_list} />)
          setBody(<BodyDefault checkAll={checkAll}  checkHandler={checkHandler} selected_at={selected_at} selected_list={selected_list} is_public={!props.btn} has_edit_right={has_edit_right} year={props.year} days={days} month={props.month} project={props.project} start_date={start_date} end_date={end_date}  repot_list={repot_list} repo_list_updated_at={repo_list_updated_at} changeHandler={changeHandler} changeStringHandler={changeStringHandler} changeTimeHandler={changeTimeHandler}  />)
          break
        case 2: 
          setHeader(<HeaderLogi year={props.year} days={days}  month={props.month}   project={props.project}   driver={props.driver} repot_list={repot_list} />)
          setBody(<BodyLogi checkAll={checkAll}  checkHandler={checkHandler}  selected_at={selected_at}  selected_list={selected_list} is_public={!props.btn} has_edit_right={has_edit_right}  year={props.year} days={days} month={props.month} project={props.project} start_date={start_date} end_date={end_date}  repot_list={repot_list} repo_list_updated_at={repo_list_updated_at} changeHandler={changeHandler} changeStringHandler={changeStringHandler} changeTimeHandler={changeTimeHandler} />)
          setAdditionalCss("logiquest")
          break
        case 3: 
          setHeader(<HeaderYamato year={props.year} days={days}  month={props.month}   project={props.project}    driver={props.driver} repot_list={repot_list} />)
          setBody(<BodyYamato checkAll={checkAll} checkHandler={checkHandler}  selected_at={selected_at} selected_list={selected_list} is_public={!props.btn} has_edit_right={has_edit_right}  year={props.year} days={days} month={props.month} project={props.project} start_date={start_date} end_date={end_date}  repot_list={repot_list} repo_list_updated_at={repo_list_updated_at} changeHandler={changeHandler} changeStringHandler={changeStringHandler} changeTimeHandler={changeTimeHandler} />)
          break
        case 4: 
          setHeader(<HeaderYusei year={props.year} days={days}  month={props.month}   project={props.project}   driver={props.driver}repot_list={repot_list} />)
          setBody(<BodyYusei checkAll={checkAll} checkHandler={checkHandler}  selected_at={selected_at}  selected_list={selected_list} is_public={!props.btn} has_edit_right={has_edit_right}  year={props.year} days={days} month={props.month} project={props.project} start_date={start_date} end_date={end_date}  repot_list={repot_list} repo_list_updated_at={repo_list_updated_at} changeHandler={changeHandler} changeStringHandler={changeStringHandler} changeTimeHandler={changeTimeHandler} />)
          break
      
        case 5: 
          setHeader(<HeaderSpot year={props.year} days={days}  month={props.month}   project={props.project}   driver={props.driver}repot_list={repot_list} />)
          setBody(<BodySpot checkAll={checkAll}  checkHandler={checkHandler}  selected_at={selected_at}  selected_list={selected_list} is_public={!props.btn} has_edit_right={has_edit_right}  year={props.year} days={days} month={props.month}  driver={props.driver} project={props.project} start_date={start_date} end_date={end_date}  repot_list={repot_list} repo_list_updated_at={repo_list_updated_at} changeHandler={changeHandler} changeStringHandler={changeStringHandler} changeTimeHandler={changeTimeHandler} />)
          setAdditionalCss("spot")
          break
        default: 
      }
  }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[repo_list_updated_at,props.project, selected_at])



   useEffect(() => {

    if(repot_list){
      var new_list = []
      if(selected_all){
        new_list = repot_list.map(item => item.id)
      }
      setSelectedList(new_list)
      setSelectedAt(new Date())
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[ selected_all])



  const loadingCloseHanlder = ()=>{
    setLoading(null)
  }

  const checkAll = () => {
    setSelectedAll(selected_all ? false : true)
  }

  const checkHandler = (id) => {

    const _selected_id = selected_list.findIndex(item => item === id)
    
    if(_selected_id >= 0) {
      selected_list.splice(_selected_id,1)
      setSelectedList(selected_list)
      setSelectedAt(new Date())
    } 
    else{
      selected_list.push(id)
      setSelectedList(selected_list)
      setSelectedAt(new Date())
    }
  }
  const statusUpdateHandler = (e, is_delete) => {
    setLoading(<LoadingWindow closeHandler={loadingCloseHanlder} ></LoadingWindow>)
    if(is_delete){
      deleteReport(props.token, e.project.uid, e.driver.uid, e.id )
    }else{
      createReport(props.token,e.project.uid, e.driver.uid, e)
    } 
    
    //props.updateReportItemHandler(e)
  }

  const closeHandler = () => {
    setDetail(null)
  }
  
  
  const closeAllHandler = () => {
    setChangeAll(null)
    loadList()
  }
  const changeHandler = (id) => {
    if(!props.btn || !has_edit_right) return null

    const index = repot_list.findIndex(item => item.id === id)
    const report = repot_list[index]
    var _report =  new ReportProps(report)
    _report = _report.set("worked_date", parseISO(report.worked_date) )
    _report = _report.set("work_start_at", parseISO(report.work_start_at) )
    _report = _report.set("work_end_at", parseISO(report.work_end_at) )

    setDetail(<DetailWindow  profile={props.profile} report={_report} url={url} token={props.token} closeHandler={closeHandler} updateHandler={statusUpdateHandler} />)

  }
/**
  const newReportCreateHandler = () => {
    if(!props.btn) return null
    const report = repot_list[0]

    var _report =  new ReportObject({
      project: props.project,
      receipts:[]
    })

    console.log(_report)

    setDetail(<DetailWindow  profile={props.profile} report={_report} url={url} token={props.token} closeHandler={closeHandler} updateHandler={statusUpdateHandler} />)

  }
 */
  /**
  changeHandler をdetail showに利用のためコメントアウト
  const changeHandler = (e, id) => {
    const index = repot_list.findIndex(item => item.id === id)
    const target = e.target
    repot_list[index] = repot_list[index].set(target.name , Number(target.value))
    setReportList(repot_list)
    setRepoListUpdatedAt(new Date())
    //setItem(item.set(target.name, target.value))
  } 
   */

  const changeStringHandler = (e, id) => {
    const index = repot_list.findIndex(item => item.id === id)
    const target = e.target
    repot_list[index] = repot_list[index].set(target.name , target.value)
    setReportList(repot_list)
    setRepoListUpdatedAt(new Date())
  } 

  const copy = (url) => {
    navigator.clipboard.writeText(config.get("BASE_PATH") + url).then(  () => {}, () => {});
    setMessage(<span className="copyied">コピーしました。</span> )
  }


  const changeTimeHandler = (e, id) => {
    const index = repot_list.findIndex(item => item.id === id)
    const target = e.target
    const time =  target.value.split(":")
    const target_datetime = target.name === "work_start_at"  ? repot_list[index].work_start_at : repot_list[index].work_end_at 
    repot_list[index] = repot_list[index].set(target.name , setMinutes(setHours(target_datetime, time[0]), time[1] ))
    setReportList(repot_list)
    setRepoListUpdatedAt(new Date())
  } 
  
  const displayChangeAll = () => {
    const list = report_list.filter(a => selected_list.find(b => a.id === b))
    setChangeAll(<ChangeAllWindow report_list={list} token={props.token} closeHandler={closeAllHandler}/>)
  }

  const getMontylyPaymentDetailUrl = () => {
    if(props.driver  && props.driver.company_driver === 1){
      return "/monthly_payment/" + partner_id + "/" + props.year + "/" + props.month + "/driver/" + props.driver.id + "/e"
    }else{
     return  "/monthly_payment/" + partner_id + "/" + props.year + "/" + props.month + "/e"
    }
  }

  return(
    <div className="inner">
      {detail ||  loading  ?  <div id="overlay" className="overlay"  onClick={closeHandler}></div> : null}
      {change_all ?  <div id="overlay" className="overlay"  onClick={null}></div> : null}
       <form >
			  <div className="content_wrap sub_narrow report">
					    <div className="page_title_wrap">
								 { props.btn ? <h2 className="page_title">月報確認・編集</h2> : null}
                 { props.btn ? <Breadcrumbs pages={[new LinkProps("月報一覧","/monthly_report"),new LinkProps(props.project ?  props.project.name + " 月報確認・編集 "  : "月報確認・編集" ,"#")]} />: null}
							</div>
        {props.btn && props.project  ? 
                <div className="right-buttons">
                  {has_edit_right ?<div className="link-item a icon_button link" onClick={displayChangeAll}> 一括変更<i className="fa-solid fa-arrow-right"></i></div> : null}
                  {has_edit_right ? <div className="link-item a icon_button link"><a href={getReportUrl(props.project.uid,props.project_driver_id,"new")}  >日報を新規作成 <i className="fa-solid fa-arrow-right"></i></a></div>: null}
                  <div className="link-item a icon_button link" onClick={()=>(copy("/project/" + props.project.uid + "/monthly_report/" + props.project_driver_id +"/" +props.year +"/" + props.month + "/pub"))}>月報リンクをコピー <i className="fa-solid fa-arrow-right"></i></div>
                  {message}
                  <div className="link-item"><a href={getMontylyPaymentDetailUrl()}>この月報を含む支払い明細 <i className="fa-solid fa-arrow-right"></i></a></div>
                </div>
                :null}
							<div className={"report_body " +  additionalCss}>
                {header}
                {body}
              </div>  
              
				</div>
       </form>
        {
          uniqueid  && props.btn &&  props.profile && props.profile.user_type !== config.get("USER_PARTNER") ? <MemoComponent category={1} uniqueid={uniqueid} token={props.token} /> : null
        }
       
       {detail}
       {change_all}
		</div>
    
  )
}

export default MonthlyReportDetail


/**
<div className="link-item a icon_button link" onClick={()=> newReportCreateHandler()}>日報を追加 <i className="fa-solid fa-arrow-right"></i></div>

 */