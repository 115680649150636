import React, {useState,useEffect} from 'react';
import Project from '../../../components/project/Project';
import useReportState from '../../../components/report/useReportState';

interface ProjectListWindowProps {
  projects:List[Project],
  driver_id:string,
  closeHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const ProjectListWindow = ({
  projects,
  driver_id,
  closeHandler,
  updateHandler,
  ...props
}: ProjectListWindowProps) => { 

  
  const [item ,setItem] = useState(null)
  const {getReportUrl} = useReportState()
  useEffect(() => {
    if(projects){
      setItem(
        projects.map(project => {
          const url = getReportUrl(project.uid, driver_id, "new")
          return(<div className="driver-list"><a href={"/project/" + project.id}>{project.name}</a> &nbsp;
            <div className="flex-right"><a href={url} rel="noreferrer">{url.substr(0,40)}...</a>&nbsp;<span className="link" onClick={() => copy(url)}>[コピー]</span></div>
          </div>)
        })
      )
  
      //setProjectDriver(report.)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const copy = (url) => {
    navigator.clipboard.writeText(url).then(  () => {}, () => {});
  }

 
  return (
    <div className="modal-window" data-id="modal1" >
    <button className="js-close button-close" onClick={closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column">
          <h2>案件</h2>
           {item}
          </div>
        </div>
  </div>
  );
};

