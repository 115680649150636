import React, { useEffect, useState } from 'react'
import Utile from '../../../utiles/Utile';
import { format, parseISO,addDays,isSameDay } from 'date-fns';
import ReportObject from "../../report/ReportObject"
//import useReportState from '../../report/useReportState';
function BodyYamato(props:PropsValue) {
  
  const [list, setList] = useState(null)

  const [repot_list , setReportList] = useState([])
  const [repo_list_updated_at , setRepoListUpdatedAt] = useState(new Date())
  //const {getFee} = useReportState()

  useEffect(() => {
   
    setReportList(props.repot_list)
    setRepoListUpdatedAt(new Date())
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ props.repot_list, props.repo_list_updated_at])

  useEffect(() => {
    
    
    if(repot_list ){
  
        var distance_total = 0
        var compleated_total  = 0
        var nekopos_total = 0
        var dm_total = 0
        var fee_total = 0
        var fee_real_total = 0

        //var fee_total = 0

        const _list = new Array(props.days + 1).fill(0).map((d,index) => {

          const target_day = addDays(props.start_date, index)
          const item = repot_list.find(item => isSameDay(parseISO(item.worked_date),target_day ))
          var fee = 0
          var fee_real = 0 
   
          if(item){ 
         
          const report_obj = new ReportObject(item)
       
          //const project_driver = report.project.drivers.filter(p => p.driver_id === item.driver.id)[0]
          //fee_real = getFee(project_driver.fee_information.unit_price, item.fee_information ? item.fee_information.unit_price : 0)
          fee_real = report_obj.getRealFee()
          fee = item.fee
          distance_total += item.distance
          compleated_total += item.normal_unit
          nekopos_total += item.nekopos_unit
          dm_total += item.dm_unit
          //fee_total += item.fee
          fee_total += fee
          fee_real_total += Number(fee_real ? fee_real : 0)
          }
          return(
            <tr className={item ? "link" : null}>
               {props.is_public ||!props.has_edit_right  ? null : <td>{item ? <input type="checkbox" checked={props.selected_list.find( s => (item && s === item.id)) ? true : false} onClick={() => props.checkHandler(item.id)}/> : null}</td>}
              <td  onClick={() => item? props.changeHandler(item.id) :null}>{format(target_day, "dd")}</td>
              <td  onClick={() => item? props.changeHandler(item.id) :null}>{Utile.getWeekString(target_day)}</td>
              {item ? (<td  onClick={() => item? props.changeHandler(item.id) :null}>{format(parseISO(item.work_start_at), "HH:mm")}～{format(parseISO(item.work_end_at), "HH:mm")} </td>) : <td></td> }
              {item ? (<td  onClick={() => item? props.changeHandler(item.id) :null}>{item.area} </td>): <td></td>}
              {item ? (  <td  onClick={() => item? props.changeHandler(item.id) :null}>{item.distance}<span className="km">km</span></td>): <td></td>}
              {item ? (  <td  onClick={() => item? props.changeHandler(item.id) :null}>{item.normal_unit}<span className="yen_mark">個</span></td>): <td></td>}
              {item ? (  <td  onClick={() => item? props.changeHandler(item.id) :null}>{item.nekopos_unit}<span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (  <td  onClick={() => item? props.changeHandler(item.id) :null}>{item.dm_unit}<span className="yen_mark" >個</span></td>) : <td></td>}
              {!props.is_public ? 
                item ? (<td  onClick={() => item? props.changeHandler(item.id) :null}><span className="yen_mark">&yen;</span>{fee ? fee.toLocaleString(): null}</td>): <td></td>
               : null}
              {!props.is_public ? 
                item ? (<td  onClick={() => item? props.changeHandler(item.id) :null}><span className="yen_mark">&yen;</span>{fee_real ? fee_real.toLocaleString(): null}</td>): <td></td>
               : null}
            
            </tr>
          )
        })
      _list.push(<tr>
        <td colspan={props.is_public ||!props.has_edit_right  ?  4 :  5} class="report_subtotal">合計</td><td>{distance_total.toLocaleString()}<span class="km">km</span></td>
          <td>{compleated_total.toLocaleString()}<span class="overtime_mark">個</span></td>
          <td>{nekopos_total.toLocaleString()}<span class="overtime_mark">個</span></td>
          <td>{dm_total.toLocaleString()}<span class="overtime_mark">個</span></td>
          {props.is_public ?  null : <td>{fee_total.toLocaleString()}<span class="overtime_mark">円</span></td>}
          {props.is_public ?  null : <td>{fee_real_total.toLocaleString()}<span class="overtime_mark">円</span></td>}
        </tr>)
      setList(_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ repo_list_updated_at, props.selected_list, props.selected_at])



  return(
    <table>
      <tbody>
      <tr>
        {props.is_public ||!props.has_edit_right  ? null : <th><input type="checkbox" onChange={props.checkAll}/></th>}<th>日</th><th>曜</th><th>時間</th><th>配達エリア</th><th>距離</th><th>宅配完了個数</th><th>ネコポス個数</th><th>DM便個数</th>
          {!props.is_public ? <th>報告運賃</th> : null}
          {!props.is_public ? <th>支払い運賃</th> : null}
      </tr>
      {list}
      </tbody>
    </table>
    
  )
}

export default BodyYamato


/**
 *  {item ? (<td><input type="time" className="report_time" value={format(parseISO(item.work_start_at), "HH:mm")} name="work_start_at" onChange={(e) => props.changeTimeHandler(e, item.id)} />～<input type="time" value={format(parseISO(item.work_end_at), "HH:mm")} name="work_end_at" onChange={(e) => props.changeTimeHandler(e, item.id)} className="report_time" /></td>) : <td></td> }
              {item ? (<td><input type="text" className="report_erea" value={item.area} name="area" onChange={(e) => props.changeStringHandler(e, item.id)}  /></td>): <td></td>}
              {item ? (  <td><input type="number"  className="report_distance" value={item.distance}  name="distance" onChange={(e) => props.changeHandler(e, item.id)} /><span className="km">km</span></td>): <td></td>}
              {item ? (  <td><input type="number" className="" value={item.compleated_unit}  name="compleated_unit" onChange={(e) => props.changeHandler(e, item.id)}  /><span className="yen_mark">個</span></td>): <td></td>}
              {item ? (  <td><input type="number" className="" value={item.nekopos_unit}  name="nekopos_unit" onChange={(e) => props.changeHandler(e, item.id)}  /><span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (  <td><input type="number" className="" value={item.dm_unit}   name="dm_unit" onChange={(e) => props.changeHandler(e, item.id)} /><span className="yen_mark" >個</span></td>) : <td></td>}
              {item ? (    <td><span className="yen_mark">&yen;</span><input type="number"   name="fee" onChange={(e) => props.changeHandler(e, item.id)}  className="report_reimbursement" value={item.fee}  /></td>): <td></td>}
 */