import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import UserDelete from "./UserDelete"
import useUserState from './useUserState';

function UserDeleteApp(props:PropsValue) {
  const { id } = useParams();
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {user, user_error,user_deleted, getUserDetail,deleteUser} = useUserState()
 
  

  useEffect(() => {
    if(user){
      setItem(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user])


  useEffect(() => {

    if(token){
      getUserDetail(token, id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(user_deleted){
      window.location.href = "/user/success/deleted"
    }
  },[user_deleted])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = () => {
    deleteUser(token, id)
    //userCreate(token, _item)
    //saveOnCookie(_item)
    //window.location.href = "/user/confirm"
  }
  
  return(
    <div id="home">
      <PageApp current_page="user" content={<UserDelete  user={item} error={user_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default UserDeleteApp

