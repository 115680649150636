import { Record } from 'immutable'
const ReportDriverFeeProps = Record({
  unit_price:0,
  highway_fee:0,
  parking_fee:0,
  other_fee:0,
  over_time:0,
  over_distance:0
});
export default ReportDriverFeeProps;
