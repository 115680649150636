import React, { useEffect, useState,  } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../utiles/Utile';
//import { DeleteWindow } from '../../stories/components/delete_window/DeleteWindow';

function ClientDelete(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [delete_window, setDeleteWindow] = useState(null)
  const [error_message, setErrorMessage] = useState(null)

  useEffect(() => {
    if(props.client){
      setItem(props.client)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.client])

  const closeHandler = () => {
    setDeleteWindow(null)
  }
 /** const executeHandler = () => {
    props.updateHandler()
  } */
  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler()
    //setDeleteWindow(<DeleteWindow message={props.client.name +"を削除してよろしいですか。"} closeHandler={closeHandler} executeHandler={executeHandler}/>)
  }


  return(
    <div className="inner">
       {delete_window  ?  <div id="overlay" className="overlay"  onClick={closeHandler}></div> : null}
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">クライアント 削除</h2>
                <Breadcrumbs pages={[new LinkProps("クライアント一覧","/client"),new LinkProps("クライアント削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">クライアント削除</p>
                {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
								<div className="input_wrap">
					
                <table class="input_table col12 login_tbl">
											<tbody>

												<tr>
													<th class="tl">クライアント名</th>
													<td>{item?item.name:null}</td>
												</tr>
												<tr>
													<th class="tl">フリガナ</th>
													<td>{item?item.kana:null}</td>
												</tr>
												<tr>
													<th class="tl">都道府県</th>
													<td>{item && item.prefecture ? Utile.getLabelByValue(config.get("PREFS"),Number(item.prefecture)):null}</td>
												</tr>
												<tr>
													<th class="tl">所在地</th>
													<td>{item?item.address:null}</td>
												</tr>
												<tr>
													<th class="tl">電話番号</th>
													<td>{item?item.tel:null}</td>
												</tr>
												<tr>
													<th class="tl">メールアドレス</th>
													<td>{item?item.email:null}</td>
												</tr>
												<tr>
													<th class="tl">契約書</th>
													<td>{item?item.contract_url:null}</td>
												</tr>
												<tr>
													<th class="tl">担当者名</th>
													<td>{item?item.name_in_charge:null}</td>
												</tr>
												<tr>
													<th class="tl">担当者フリガナ</th>
													<td>{item?item.kana_in_charge:null}</td>
												</tr>
											
												<tr>
													<th class="tl">備考</th>
													<td>{item?item.note:null}</td>
												</tr>
											</tbody>
										</table>
								</div>
							</div>
              <div className="button_wrap center">
								 <button className="red" type="button" onClick={btnClickHandler}>削除する</button>
               </div>
            </div> 
				</div>  
      {delete_window}
		</div>

  )
}

export default ClientDelete
