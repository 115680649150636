import React, { useEffect, useState } from 'react'
import DriverDetailItem from './DriverDetailItem'
import DriverDetailLinks from './DriverDetailLinks'
import config from 'react-global-configuration';
import useProjectState from '../project/useProjectState';

function DriverDetailOverlay(props:PropsValue) {

  const [driver, setDriver] = useState(null)
  const [projects, setProjecdts] = useState(null)
  const {project_list, getProjectList} = useProjectState()
  useEffect(() => {
    if(props.driver){
      setDriver(props.driver)
    }
  
    if(props.projects){
      setProjecdts(props.projects)
    }else{
      getProjectList(props.token,1,[props.driver.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(project_list && !props.projects){
      setProjecdts(project_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_list])



  const btnClickHandler = () => {
    window.location.href = "/driver/" + props.driver.id  + "?new=1"
  }

  const btnDeleteHandler = () => {
    window.location.href = "/driver/" + props.driver.id + "/delete"
  }

  return(
    <div className="modal-window" data-id="modal1" >
    <button className="js-close button-close" onClick={props.closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail">
            <div className="column-left">
              <DriverDetailItem driver={driver} is_edit={false}/>
            </div>
              <DriverDetailLinks driver={driver} projects={projects} token={props.token}/>
          </div>
          { props.profile && props.profile.user_type === config.get("USER_FINANCE") ? 
            <div className="button_wrap center">
              <button className="textbutton" onClick={btnDeleteHandler}>削除</button>
              <button className="red" type="button" onClick={btnClickHandler}>編集</button>
            </div>
            : null
        }
        </div>
       
  </div>
  
    
  )
}

export default DriverDetailOverlay   

