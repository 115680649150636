import React, {  } from 'react'

function HeaderDefault(props:PropsValue) {

  /**
   * year
   * mnth
   * project
   * project_driver
   * report list
   */

  
  return(
    <div>
       <h3 className="report_ttl">定期便 稼働日報</h3>
        <p className="report_manth">稼働年月：<input type="text" className="in01" value={props.year} />年<input type="text" className="in01" value={props.month} />月</p>
        <p className="report_driver">Dr名：<input type="text" className="in01" value={props ? props.driver.name : null} /></p>
        <p className="report_casename"><input type="text" className="in02" value={props ? props.project.name : null} /></p>
        <p className="report_period">稼働日数：<input type="text" className="in01" value={ props.repot_list ? props.repot_list.length :  null} />日間</p>
		</div>
    
  )
}

export default HeaderDefault

