import React, {  } from 'react'
import PartnerDetailItem from './PartnerDetailItem'
import PartnerDetailLinks from './PartnerDetailLinks'
import config from 'react-global-configuration';

function PartnerDetailOverlay(props:PropsValue) {

  const btnClickHandler = () => {
    window.location.href = "/partner/" + props.partner.id 
  }

  const btnDeleteHandler = () => {
    window.location.href = "/partner/" + props.partner.id + "/delete"
  }
  
  

  return(
    <div className="modal-window" data-id="modal1" >
    <button className="js-close button-close" onClick={props.closeHandler}>Close</button>
        <div className="modal-scroll__container">
          <div className="column-detail">
            <div className="column-left">
              <PartnerDetailItem partner={props.partner} is_edit={props.is_edit}  />
            </div>
              <PartnerDetailLinks partner={props.partner} token={props.token} profile={props.profile}/>
          </div>
          { props.profile && props.profile.user_type === config.get("USER_FINANCE") ? 
            <div className="button_wrap center">
              <button className="textbutton" onClick={btnDeleteHandler}>削除</button>
              <button className="red" type="button" onClick={btnClickHandler}>編集</button>
            </div>
            : null
          }
        </div>
  </div>
  
    
  )
}

export default PartnerDetailOverlay

