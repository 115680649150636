import { Record } from 'immutable'

const ReceiptProps = Record({
  id:null,
  path:null,
  file:null,
  price:null,
  payto:"",
  description:null,
  paid_date:null,
});
export default ReceiptProps;
