import React, {useState, useEffect  } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { PageApp } from '../../../stories/PageApp'
import ProjectDriverDetail from "./ProjectDriverDetail"
import ProjectDriverProps from './ProjectDriverProps';
import useProjectDriverState from './useProjectDriverState';
import useProjectState from '../useProjectState';


function ProjectDriverDetailApp(props:PropsValue) {
  const { project_id } = useParams();
  const { project_driver_id } = useParams();
  const [searchParams] = useSearchParams();
  const is_new = searchParams.get("new") ?  searchParams.get("new") : 0 ;
  const [has_cookie] = useState(false)
  const [profile, setProfile] = useState(null)
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  
  const { project_driver, saveOnCookie, getProjectDriver, hasCookie, loadFromCookie} = useProjectDriverState()
  const {project, getProject} = useProjectState()


  useEffect(() => {
    if(token){
      //setHasCookie(hasCookie())
      getProject(token,project_id)
      if(is_new === 0 && hasCookie()){
        loadFromCookie()
      }else{
        if(project_driver_id === "new"){
          setItem(new ProjectDriverProps())
        }else{
          getProjectDriver(token, project_id, project_driver_id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
    
  



  useEffect(() => {
    if(project_driver){
      if(is_new !== 0 && (project_driver.project_id !== Number(project_id) || project_driver.id !== Number(project_driver_id)) ){
        getProjectDriver(token, project_id, project_driver_id)
      }else{
        setItem(new ProjectDriverProps(project_driver))
      } 
       
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_driver])

/** useEffect(() => {

    if(project_driver && project_driver.driver && project){
      getMontylyReportList(token,project.uid, project_driver.driver.uid)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_driver ,project])

 */
  
  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    saveOnCookie(_item)
    window.location.href = "/project/" + project_id + "/driver/confirm" 
  }
  const saveOnCookieHandler = (item) => {
    saveOnCookie(item)
    window.location.href = "/project"
  }
  
  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  return(
    <div id="home">
      <PageApp content={<ProjectDriverDetail  has_cookie={has_cookie}  profile={profile} token={token} project={project}  project_id={project_id} project_driver={item}  updateHandler={updateHandler} loadFromCookie={loadFromCookie} saveOnCookieHandler={saveOnCookieHandler} />} profileChangeHandler={profileChangeHandler}  authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectDriverDetailApp

