
import Project from "../Project"

class YamatoProject  extends Project{

  is_unit_price = false
  is_over_time_fee = false
  is_over_distance_fee = false
    
  validateForm(form){
      return []
  }
}

export default YamatoProject

