import React, { useEffect, useState,  } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

function UserConfirm(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)

  useEffect(() => {
    setItem(props.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.user])

  useEffect(() => {
    if(props.error)
      setErrorMessage("エラーが発生しました。すでに登録済みのアカウントの可能性があります。")
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.error])


  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler(item)
  }

  const backHandler = () => {
   const url = item.id ? "/user/" + item.id : "/user/new"
   window.location.href = url
  }



  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">ユーザー登録</h2>
                <Breadcrumbs pages={[new LinkProps("ユーザー登録","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">新規ユーザー登録</p>
                  {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
									<div className="input_wrap">
										
										<table className="input_table col12 login_tbl">
											<tbody>
												<tr>
													<th className="tl">ユーザー種別</th>
													<td><label className="radio">{item ? config.get("USER_TYPE_" + item.user_type) : null}</label></td>
												</tr>
												<tr>
													<th className="tl">ユーザー名</th>
													<td>{item ? item.name : null}</td>
												</tr>
												<tr>
													<th className="tl">フリガナ</th>
													<td>{item ? item.furigana : null}</td>
												</tr>
												<tr>
													<th className="tl">メールアドレス</th>
													<td>{item ? item.username : null}</td>
												</tr>
                        <tr>
													<th className="tl">パスワード</th>
													<td>******</td>
												</tr>
                        <tr>
													<th className="tl">電話番号</th>
													<td>{item ? item.tel : null}</td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>{item ? item.note : null}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								

                <div className="button_wrap center">
								
                    <button className="red" type="button" onClick={btnClickHandler}>  { item && item.id ? "この情報で" + config.get("EDIT"):"この情報で" +  config.get("REGIST")} </button>
               
							  </div>

                <div className="button_wrap center">
								  <button className="red" type="button" onClick={backHandler}>入力に戻る</button>
							  </div>


                </div> 
				</div>  
      
		</div>

  )
}

export default UserConfirm

