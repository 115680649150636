
import PaymentItemProps from './PaymentItemProps'

const props = extendValues => class extends PaymentItemProps {};
class PaymentItem extends props({}) {


  getTotal(){

    if(this.non_tax_price) return Number(this.non_tax_price)
    if(this.total_price) return Number(this.total_price)
    if(!this.unit_number) return 0
    if(!this.unit_price) return 0
  
    return Math.round(Number(this.unit_number) *  (Number(this.unit_price) / Number(this.unit_min))) 
  }

  getTaxTotal(type){
    if(this.non_tax_price) return 0
    if(this.total_price) return Number(this.total_price)
    if(!this.unit_number) return 0
    if(!this.unit_price) return 0
    
    return  Number(this.unit_number) *  (Number(this.unit_price) / Number(this.unit_min))
  }
  getTypedTaxTotal(type){
    if(Number(this.tax_rate) !== Number(type)) return 0
    if(this.total_price) return Number(this.total_price)
    return 0
  }
  
  getUnTaxTotal(){
    if(this.non_tax_price) return Number(this.non_tax_price)
    return 0
  }


}
export default PaymentItem
