import React, {useState} from 'react';
import { Record } from 'immutable'
import UserProps from "../../../components/user/UserProps"
import config from 'react-global-configuration';
interface SearchBoxUserprops {
   /**  
   * content
   */
   token:String,

   /**
    * Form 
    */
   data:?Record,

   /**
    * SearchBtnClickHandler
    */
   btnClickHandler:Function,

   clearHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const SearchBoxUser = ({
  //current_page,
  token,
  content,
  data,
  btnClickHandler,
  clearHandler,
  ...props
}: SearchBoxUserprops) => { 

  const [form_css, setFormCss] = useState(null)
  const [form_active, setFormActive] = useState("active")
  const [item, setItem]= useState(new UserProps())


  const toggleSearch = () => {
    if(form_css){
      setFormCss(null)
      setFormActive("active")
    }else{
      setFormCss("search-hidden")
      setFormActive(null)
    }
  }


  
  const onChangHandler = (e) => {
    const target = e.target 
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
  }

  const clickHandler = () => {
    btnClickHandler(item)
  }



  const kedownHandler = (e) => {
    if (e.key === 'Enter') {
      clickHandler()
    }
  }



  return (
    <div className="container more_search">
    <div className="column">
    <p id="more_search_ttl" className={form_active} onClick={toggleSearch}>絞り込み検索</p>
    <form className={form_css} onKeyDown={kedownHandler}>
      <div>
        <div >
          
        <div className="input_wrap">
          <label className="">
            <span className="label_name">ユーザー種別</span>
            <span className="select_wrap">
              <select name="user_type" onChange={onChangHandler} >
                <option value="all">全て</option>
                <option value={config.get("USER_FINANCE")}>{config.get("USER_TYPE_finance")}</option>
                <option value={config.get("USER_MNANAGER")}>{config.get("USER_TYPE_manager")}</option>
              </select>
            </span>
          </label>
          <label className="">
            <span className="label_name">ユーザー名／フリガナ</span>
            <input type="text" name="name" onChange={onChangHandler} />
          </label>

          <label className="">
            <span className="label_name">電話番号</span>
            <input type="text" name="tel" onChange={onChangHandler}/>
          </label>
        </div>

      </div>
          <div className="input_wrap"></div>
          <div className="button_wrap center">
            
            <button type="button" onClick={clearHandler}>クリア</button>
            <input type="button" value="検索" className="button red" onClick={clickHandler} />
          </div>
        </div>
        
      </form>
    </div>
  </div>

  );
};


/**
 * 

           
 */