import React, {useState  } from 'react'


import { PageApp } from '../../stories/PageApp'
import CompanyDetail from "./CompanyDetail"
import Company from './Company'
import AddressInfo from './AddressInfo'


function CompanyDetailApp(props:PropsValue) {
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)



  const authChangeHandler = (e) => {
    if(e.error){
  
    }else{
      setToken(e.token)
    }
  }

  const profileChangeHandler = (e) => {
    if(e.company){
      const address_info = new AddressInfo(e.company.address_info)
      const company =  new Company(e.company)
      company.set("address_info",address_info )
      setItem(company)
    }
  }



  return(
    <div id="home">
      <PageApp current_page="company" content={<CompanyDetail company={item} token={token} is_edit={true} />} profileChangeHandler={profileChangeHandler} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default CompanyDetailApp

