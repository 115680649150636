import React, { useEffect, useState } from 'react'
import useProjectState from '../project/useProjectState';
import { subMonths,  endOfMonth } from 'date-fns';

function ClientDetailLinks(props:PropsValue) {
  const [reports, setReports] = useState(null)
  const {project_list,getProjectList} = useProjectState()
  

  useEffect(() => {
    if(props.token && props.client){
      getProjectList(props.token, 1, null, {clinet_id:props.client.id} )
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.client, props.token])



  useEffect(() => {
   
    if(project_list){


      const today = new Date()
      const _m_list = [];

      (new Array(12).fill(0).map((m, index) => {
        const target_date = subMonths(today, index)
      
        const result = isWorking(target_date )
        if(result){
          _m_list.push(<div className="link-list-item"><a  href={"/monthly_report?client_id=" + props.client.id + "&year=" + target_date.getFullYear()+ "&month=" + (target_date.getMonth() + 1) }>{target_date.getFullYear()}年{target_date.getMonth() + 1}月</a></div> )
        }
        return 1
      }))

      /**const _list = project_list.map(project => {
  
        return (<div className="sub-title"><div className="project-name">{project.name}</div>
          {
        
          }
        </div>)
        const _array = new Array(dif_month + 1)
        _array.fill(0)
        return(
        <div className="sub-list" key={"report" + project_driver.id}><div className="driver-name">{project_driver.driver.name} ({project_driver.driver.partner.name}) </div>
          {_array.map((i,index) => {  
            const target_date = addMonths(start_date, index)
            return (<div className="sub-list"><a href={"project/" + project_driver.project_uid + "/monthly_report/" + project_driver.uid+ "/" + format(target_date, "yyyy/MM")}>{format(target_date, "yyyy年M月月報")}</a></div>)
          }).reverse()}
        </div>)
      })
      **/
      setReports(_m_list)

      //console.log(my_project_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_list])


  const isWorking = (target_date) => {
   
    if(!project_list || project_list.length === 0) return false

    const list = project_list.map(project =>{
      const _l = project.drivers.map(project_driver => {
        const start_date = new Date(project_driver.start_date)
        const  end_date = project_driver.end_date ? new Date(project_driver.end_date) :  endOfMonth(new Date())
       
     
        if(target_date >= start_date && target_date <= end_date ) return true 
        return false
      })
      if(_l.indexOf(true) >= 0) return true 
      return false
    })
    if(list.indexOf(true) >= 0) return true 
    return false
  }
  


  

  return(
    <div className="column-right">
      <h4>月報一覧</h4>
       <div className="item-list">
        {reports}
      </div>
     
    </div>
  
    
  )
}

export default ClientDetailLinks

