import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../utiles/Utile';
function ReportDetail(props:PropsValue) {

  const [project, setProject] = useState(null)
  const [project_driver, setProjectDriver] = useState(null)
  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  
  useEffect(() => {
    setItem(props.report)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.report])
  

  useEffect(() => {
    
    setProject(props.project)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project])

  useEffect(() => {
    
    setProjectDriver(props.project_driver)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project_driver])

  const changeHandler = (e) => {
    const target = e.target
    setItem(item.set(target.name, target.value))
  } 
  
  const btnClickHandler = () => {
    const errors = getErrorMessagte()
    setErrorMessage(null)

    if(errors.length >0 ){
      setErrorMessage(errors.map(item => <div>{item}</div>))
    }else{
      props.updateHandler(item)
    }
  }

  const getErrorMessagte = () => {
    var errors = []
    if(!item.worked_date || item.worked_date === "") errors.push("依頼先会社名が未入力です。")
    return errors
  }



  return(
    <div className="inner">
       <form >
			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">日報入力</h2>
                <Breadcrumbs pages={[new LinkProps("日報入力","#")]} />
							</div>
							
							<div className="container">
                <div className="column"><p className="container_name">日報入力</p>
                  {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }
                  <div className="input_wrap">
										
                  <table className="input_table col12 login_tbl">
											<tbody>
												<tr>
													<th className="tl">案件名</th>
													<td>{project ? project.name : null}</td>
												</tr>
												<tr>
													<th className="tl">区分</th>
													<td>{project ? Utile.getLabelByValue(config.get("PROJECT_TYPE"), project.project_type) : null}</td>
												</tr>
												<tr>
													<th className="tl">依頼先会社名</th>
													<td>{project ? project.client.name : null}</td>
												</tr>
												<tr>
													<th className="tl">ドライバー名</th>
													<td>{project_driver ? project_driver.driver.name : null}</td>
												</tr>
												<tr>
													<th className="tl">日付</th>
													<td><input autocomplete="off" type="date" name="worked_date" value={item ? item.worked_date : undefined} onChange={changeHandler} /></td>
												</tr>
												
												<tr>
													<th className="tl">開始（着車）時間 / 発時</th>
													<td><input autocomplete="off" type="datetime-local" className="col9" placeholder="10:00"  name="work_start_at" value={item ? item.work_start_at : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">終了（完了/業務締め完了）時間</th>
													<td><input autocomplete="off" type="datetime-local"  className="col9" placeholder="18:00"  name="work_end_at" value={item ? item.work_end_at : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">配達エリア</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="川崎市"  name="area" value={item ? item.area : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">積み地</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●区●丁目"  name="load_location" value={item ? item.load_location : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">納品先</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="川崎市" name="delivery_location" value={item ? item.delivery_location : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">業務内容</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="保土ヶ谷Amazon お弁当配送"  name="detail" value={item ? item.detail : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">距離（走行距離）</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●㎞"  name="distance" value={item ? item.distance : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">開始メーター</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●㎞"  name="meter_start" value={item ? item.meter_start : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">終了メーター</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●㎞" name="meter_end" value={item ? item.meter_end : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">配達件数</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●件"  name="delivery_unit" value={item ? item.delivery_unit : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">宅配完了個数</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●個"  name="compleated_unit" value={item ? item.compleated_unit : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">ネコポス個数</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●個"  name="nekopos_unit" value={item ? item.nekopos_unit : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">DM便個数</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●個" name="dm_unit" value={item ? item.dm_unit : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">普通</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●個" name="normal_unit" value={item ? item.normal_unit : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">夜間便</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●個"  name="night_unit" value={item ? item.night_unit : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">集荷</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●個" name="load_unit" value={item ? item.load_unit : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">大型</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●個" name="large_unit" value={item ? item.large_unit : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">持戻り</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●個" name="return_unit" value={item ? item.return_unit : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">待機</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●h" name="waiting_hour" value={item ? item.waiting_hour : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">運賃</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●●●円" name="fee" value={item ? item.fee : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">日合計売上</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●●●円"  name="sales" value={item ? item.sales : undefined} onChange={changeHandler}/></td>
												</tr>
												
												<tr>
													<th className="tl">高速代</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●●円"  name="high_way_cost" value={item ? item.high_way_cost : undefined} onChange={changeHandler}/></td>
												</tr>
												<tr>
													<th className="tl">駐車場代</th>
													<td><input autocomplete="off" type="text" className="col9" placeholder="●●●円" name="parking_cost" value={item ? item.parking_cost : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td><textarea type="text" className="col12" rows="5" name="note" value={item ? item.note : undefined} onChange={changeHandler} /></td>
												</tr>
												<tr>
													<th className="tl">領収書</th>
													<td>画像添付</td>
												</tr>
												<tr>
													<th className="tl">領収書</th>
													<td>画像添付</td>
												</tr>
												<tr>
													<th className="tl">領収書</th>
													<td>画像添付</td>
												</tr>
											</tbody>
										</table>
									</div>
                  <div className="button_wrap center">
                    <button className="red" type="button" onClick={btnClickHandler}>登録内容確認</button>
                  </div>
                </div>
              </div>
				</div>
       </form>
		</div>
    
  )
}

export default ReportDetail

