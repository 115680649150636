import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ReportDetail from "./ReportDetail"
import ReportProps from './ReportProps';
import useReportState from './useReportState';
import useProjectState from '../project/useProjectState';
import useProjectDriverState from '../project/driver/useProjectDriverState';
import { parseISO } from 'date-fns';

function ReportDetailApp(props:PropsValue) {
  const { id } = useParams();
  const { project_id } = useParams();
  const { project_driver_id } = useParams();
  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {report, saveOnCookie, getReport, hasCookie, loadFromCookie} = useReportState()
  const {project, getProject} = useProjectState()
  const {project_driver, getProjectDriver} = useProjectDriverState()

  useEffect(() => {

    if(token){

      getProject(token, project_id)
      getProjectDriver(token, project_id, project_driver_id)

      if(hasCookie()){
        loadFromCookie()
      }else{
        if(id === "new"){
          setItem(new ReportProps())
        }else{
          getReport(token, id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])

  
  useEffect(() => {
    if(report){
      var _report = new ReportProps(report)
      _report = _report.set("worked_date", parseISO(report.worked_date) )
      _report = _report.set("work_start_at", parseISO(report.work_start_at) )
      _report = _report.set("work_end_at", parseISO(report.work_end_at) )
   
      setItem(_report)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report])

  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    saveOnCookie(_item)
    window.location.href = "/project/" +project_id + "/report/" + project_driver_id + "/" + id +"/confirm"
  }
  
  return(
    <div id="home">
      <PageApp content={<ReportDetail  project={project} project_driver={project_driver} report={item} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ReportDetailApp

