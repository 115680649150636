
import MontylyPaymentProps from './MontylyPaymentProps'


const projectProps = extendValues => class extends MontylyPaymentProps {};
class MonthlyPayment extends projectProps({}) {

    getWorkedDaysByDriver(driver_id){
      //const contents_obj = JSON.parse(this.content)
      return 0
    }
 

}
export default MonthlyPayment
