import React, {useEffect, useState} from 'react';
import "./memo.css"
import Memo from "./Memo"
import useMemoState from './useMemoState';

interface MemoItemProps {
  token:String,
  index:Number,
  memo:Memo,
  deleteHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const MemoItem = ({
  token,
  index,
  memo,
  deleteHandler,
  ...props
}: MemoItemProps) => { 

  const inputRef = React.createRef();

  const [item, setItem] = useState(null)
  const [top, setTop] = useState(0)
  const [start_top, setStartTop] = useState(null)
  const [left, setLeft] = useState(0)
  const [start_left, setStarLeft] = useState(null)
  const [is_changed, setIsChanged] = useState(false)
  const [content_width, setWidth] = useState(0)

  const [content_height, setHeight] = useState(0)

  const {createMemo,createByTime} = useMemoState()


  useEffect(() => {
    console.log("h:" + memo.position.height)
    setItem(memo) 
    setTop(memo.position ? memo.position.top : 0)
    setLeft(memo.position ? memo.position.left : 0)
    setWidth(memo.position ? memo.position.width : 140)
    setHeight(memo.position ? memo.position.height : 100)

    const resizeObserver = new ResizeObserver((e) => {
     
      if(e ){
      
        const target_width = e[0].contentRect.width
        const target_height = e[0].contentRect.height
        
        if(Math.abs(target_width - content_width) > 20 ||  Math.abs(target_height - content_height) > 20 ) {
          const new_item = memo.setIn(["position", "width"], target_width).setIn(["position", "height"], target_height)
          setItem(new_item)
          createByTime(token, new_item)
        }
      }
     
    });
  
    resizeObserver.observe(document.querySelector('#memo_' + memo.id)); 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const changeHandler = (e) => {
    const new_item = item.set("content", e.target.value)
    setIsChanged(true)
    setItem(new_item)

  }

  const blurHandler = (e) => {
   
    if(is_changed){
      createMemo(token, item)
      setIsChanged(false)
    } 

  }
  
  const dragStart = (e) => {
   setStarLeft(e.clientX)
   setStartTop(e.clientY)
  };


  const dragEnd = (e) => {
    
    const dif_x = e.clientX - start_left
    const new_left = (item.position ? item.position.left : 0 )+ dif_x
    const dif_y = e.clientY - start_top
    const new_top = (item.position ? item.position.top : 0 ) + dif_y

    
    var new_item = item.setIn(["position", "top"], new_top)
    new_item = new_item.setIn(["position", "left"], new_left)
    
    setItem(new_item)
    setTop(new_item.position ? new_item.position.top : 0)
    setLeft(new_item.position ? new_item.position.left : 0)
    e.preventDefault();
    createMemo(token, new_item)
  };



  return (
    <div id={"memo_" + memo.id} style={{top:top, left:left}} className="memo-window" 
      draggable 
      onDragStart={(e) => dragStart(e)} 
      onDragEnd={(e)=> dragEnd(e)} 
      onDrop={(e) =>e.preventDefault()} 
      onDragOver={(e) =>e.preventDefault()} 
      >
      <div className="header">メモ[{index + 1}]
      
      <div className="delete link" onClick={() => deleteHandler(memo.id)}><i className="fa-solid fa-trash"></i></div>
      </div>
      <textarea  ref={inputRef}  onChange={changeHandler} value={item ? item.content : ""} onBlur={blurHandler} style={{width:content_width, height:content_height}}  />
      
    </div>
  );
};

