import React, { useEffect,  } from 'react'

import useClientState from './useClientState';



function ClientSearchBox(props:PropsValue) {
  //const [search_valuse ,setSearchValue] = useState(null)
 // const [options, setOptions] = useState([])
  const {client_list, list_updated_at, /** getClientList **/} = useClientState()

  
  useEffect(() => {

    if(client_list){
      /**const _list = client_list.map(item => {
          return(
            { value: item.id, label: item.name }
          )
      })
      setOptions( _list) **/
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at])


/*
  const changeHadler = (e) => {
    const target = e.target 
    setSearchValue(target.value)
    getClientList(props.token,1, {name:target.value})
  }
*/

  return(
    <label className="no_select_icon">
      <span className="label_name">クライアント名</span>
      <span className="select_wrap">
      
      <input type="text" name="lient" list="client_value" />
      <datalist id="client_value">
    
      </datalist>
      
      </span>
    </label>

  )
}

export default ClientSearchBox

/**
<input type="text" name="name" value={search_valuse} list="client_value" onChange={ changeHadler }  /> */