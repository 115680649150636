
import Report from "../Report"

class ReportSpot  extends Report{
  delivery_unit = "持出個数"
  return_unit = "持戻個数"
  detail=  "案件名"
  driver_name = "ドライバー名"

  work_start_at =  undefined
  work_end_at =  undefined
  drive_start_at =  undefined
  area=  undefined
  load_location =  undefined
  delivery_location =  undefined

  distance =  undefined
  meter_start=  undefined
  meter_end =  undefined
  compleated_unit =  undefined
  nekopos_unit=  undefined
  dm_unit =  undefined
  normal_unit =  undefined
  night_unit =  undefined
  load_unit =  undefined
  large_unit =  undefined
  waiting_hour =  undefined

  fee = "当日単価"
  spot_type = 0

}

export default ReportSpot

