import React, {  } from 'react'
import { Breadcrumbs } from '../../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../../stories/components/breadcrumbs/LinkProps';

function ProjectDriverDeleteSuccess(props:PropsValue) {




  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">担当ドライバー削除</h2>
              <Breadcrumbs pages={[new LinkProps("案件一覧","/project"),new LinkProps("担当ドライバー削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">担当ドライバー削除 完了</p>
                <p>担当ドライバー削除が完了しました。</p>
									
							</div>
						</div> 
				</div>
		</div>

  )
}

export default ProjectDriverDeleteSuccess

