import React, { useEffect, useState } from 'react'

function BodyDefaultFixItem(props:PropsValue) {

  const [item, setItem] = useState(props.item)
  
  useEffect(() => {

    setItem(props.item)
  },[ props.item, props.item.index])


  const changeHandler = (e) =>{
    const target = e.target
    const new_item = item.set(target.name, target.value)
    setItem(new_item)
    props.updateRowHadler(new_item)
  }
  return(
    <tr >  
      <td>{props.index}</td>
      <td>{props.date}</td>
      <td className="w100">{props.editable ? <input type="text" name="driver_name" value={item.driver_name} onChange={changeHandler} /> : item.driver_name}</td>
      <td >{props.label}</td>
      <td className="w60">{props.editable ? <input type="text" name="comment" value={item.comment} onChange={changeHandler} /> : item.driver_name}</td>
      <td className="w60">{props.editable ? <input type="text" name="fee_text_1" value={item.fee_text_1} onChange={changeHandler} /> : item.fee_text_1}</td>
      <td className="w60">{props.editable ? <input type="text" name="fee_text_2" value={item.fee_text_2} onChange={changeHandler} /> : item.fee_text_2}</td>
      <td>{props.editable ? <input type="text" name="fee_text_3" value={item.fee_text_3} onChange={changeHandler} /> : item.fee_text_3}</td>
      <td className="right w80">{props.editable && props.total_value_param_name?  <input type="number" onWheel={(e) => e.target.blur()}  name={props.total_value_param_name} value={props.total} onChange={props.changeParamHandler} /> : props.total.toLocaleString()}</td>
  </tr>
    
  )
}

export default BodyDefaultFixItem
