import React, { useEffect, useState,  } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../utiles/Utile';

function PartnerDelete(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)

  useEffect(() => {
    setItem(props.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.user])

 

  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler()
  }




  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">依頼先会社 削除</h2>
                <Breadcrumbs pages={[new LinkProps("依頼先会社一覧","/partner"),new LinkProps("依頼先会社削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">依頼先会社削除</p>
                {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
								<div className="input_wrap">
					
										<table className="input_table col12 login_tbl">
											<tbody>
                      <tr>
													<th className="tl">依頼先会社名</th>
													<td>{item?item.name:null}</td>
												</tr>
												<tr>
													<th className="tl">フリガナ</th>
													<td>{item?item.kana:null}</td>
												</tr>
												<tr>
													<th className="tl">都道府県</th>
													<td>{item && item.prefecture ? Utile.getLabelByValue(config.get("PREFS"),Number(item.prefecture)):null}</td>
												</tr>
												<tr>
													<th className="tl">所在地</th>
													<td>{item?item.address:null}</td>
												</tr>
												<tr>
													<th className="tl">電話番号</th>
													<td>{item?item.tel:null}</td>
												</tr>
												<tr>
													<th className="tl">メールアドレス</th>
													<td>{item?item.email:null}</td>
												</tr>
												<tr>
													<th className="tl">契約書</th>
													<td>{item?item.contract_url:null}</td>
												</tr>
												<tr>
													<th className="tl">担当者名</th>
													<td>{item?item.name_in_charge:null}</td>
												</tr>
												<tr>
													<th className="tl">担当者フリガナ</th>
													<td>{item?item.kana_in_charge:null}</td>
												</tr>
												<tr>
													<th className="tl">振込先情報</th>
													<td>{item?item.bank_info.bank_name + " " + item.bank_info.branch_name  + " " + item.bank_info.account_type + " " + item.bank_info.account_id :null}</td>
												</tr>
												<tr>
													<th className="tl">支払い手数料</th>
													<td>{item && item.commision ? Utile.getLabelByValue(config.get("COMMISION"),Number(item.commision)):null}</td>
												</tr>
												<tr>
													<th className="tl">支払いサイト</th>
													<td>{item && item.payment_day_type ? Utile.getLabelByValue(config.get("PAYMENT_TYPE"),Number(item.payment_day_type)):null}</td>
												</tr>
												<tr>
													<th className="tl">インボイス番号</th>
													<td>{item?item.invoice_id:null}</td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>{item?item.note:null}</td>
												</tr>
											</tbody>
										</table>
								</div>
							</div>
              <div className="button_wrap center">
								 <button className="red" type="button" onClick={btnClickHandler}>削除する</button>
               </div>
            </div> 
				</div>  
      
		</div>

  )
}

export default PartnerDelete

