import React, { useEffect, useState } from 'react'
import LogoUploader from './LogoUploader';

function CompanyDetailItem(props:PropsValue) {

  const [item, setItem] = useState(null)

  
  useEffect(() => {
 
    setItem(props.company)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.company])
  

  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value.trim())    
    setItem(new_item)
    props.changeHandler(new_item)
  } 

  const changeAddressHandler = (e) => {
    const target = e.target 
    const new_item = item.setIn(["address_info", target.name], target.value )
    setItem(new_item)
    props.changeHandler(new_item)
  }

  const imageChangeHandler = (e) => {
    const new_item = item.set("logo", e)
    setItem(new_item)
    props.changeHandler(new_item)
  }

  return(
    <table className="input_table col12 login_tbl">
											<tbody>
												<tr>
													<th className="tl">会社名 *</th>
													<td>
                            {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="株式会社運送" name="name" value={item ? item.name : undefined} onChange={changeHandler} />
                              :item ? item.name : null
                            }
                          </td>
												</tr>
                        <tr>
													<th className="tl">代表者名 </th>
													<td>
                            {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="犬飼　太郎" name="representative" value={item ? item.representative : undefined} onChange={changeHandler} />
                              :item ? item.representative : null
                            }
                          </td>
												</tr>

                        <tr>
													<th className="tl">メール </th>
													<td>
                            {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="abc@hound-japan.com" name="mail" value={item ? item.mail : undefined} onChange={changeHandler} />
                              :item ? item.mail : null
                            }
                          </td>
												</tr>

												<tr>
													<th className="tl">住所1 </th>
													<td>
                            {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="神奈川県横浜市都筑区山田1-2-3" name="address_1" value={item ? item.address_info.address_1 : undefined} onChange={changeAddressHandler} />
                              :item ? item.name : null
                            }
                          </td>
												</tr>

                        <tr>
													<th className="tl">住所2 </th>
													<td>
                            {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="カンパーニュ北　2F-18" name="address_2" value={item ? item.address_info.address_2 : undefined} onChange={changeAddressHandler} />
                              :item ? item.name : null
                            }
                          </td>
												</tr>
                          
                        <tr>
													<th className="tl">TEL </th>
													<td>
                            {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="045-543-1234" name="tel" value={item ? item.address_info.tel : undefined} onChange={changeAddressHandler} />
                              :item ? item.name : null
                            }
                          </td>
												</tr>
                        
                        <tr>
													<th className="tl">FAX</th>
													<td>
                            {
                              props.is_edit ? 
                              <input autocomplete="off"  type="text" className="col9" placeholder="045-543-1234" name="fax" value={item ? item.address_info.fax : undefined} onChange={changeAddressHandler} />
                              :item ? item.name : null
                            }
                          </td>
												</tr>

												<tr>
													<th className="tl">インボイス番号</th>
													<td>
                            {
                                props.is_edit ? 
                                <input autocomplete="off" type="text" className="col9" placeholder="Txxxxxxxxxxx"  name="invoice_id" value={item ? item.invoice_id : undefined}  onChange={changeHandler}/>
                                :item ? item.invoice_id : null
                            }
                            </td>
												</tr>


                        <tr>
													<th className="tl">印影</th>
													<td>
                            {
                                props.is_edit && item? 
                                <LogoUploader logo={item.logo} imageChangeHandler={imageChangeHandler}/>
                                :item ? item.logo : null
                            }
                            </td>
												</tr>
												
											</tbody>
										</table>
    
  )
}

export default CompanyDetailItem

