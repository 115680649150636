import React, { } from 'react'
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';

function UserSucessDeleted(props:PropsValue) {


  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">ユーザー削除</h2>
                <Breadcrumbs pages={[new LinkProps("ユーザー一覧","/user"), new LinkProps("ユーザー削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">ユーザー削除 完了</p>
                <p>ユーザーの削除が完了しました。</p>
									
							</div>
						</div> 
				</div>
		</div>

  )
}

export default UserSucessDeleted

