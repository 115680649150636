import React, {  } from 'react'
import "../body/logi.css"
import Project from '../../project/Project'
function HeaderLogi(props:PropsValue) {

  /**
   * year
   * mnth
   * project
   * project_driver
   * report list
   */


  const project =  new Project(props.project)

  
  return(
    <div>
      <div className="info_box">
        <div className="inleft">
          <h3 className="report_ttl">稼&emsp;動&emsp;報&emsp;告&emsp;書</h3>
        </div>
        <div className="inright">
          <p className="fax">FAX送付先：<span></span></p>
        </div>
      </div>
      <div className="info_box under">
        <div className="inleft">
          <table>
            <tr>
              <th>支店名</th>
              <td>{props.project.client ? props.project.client.name : null} 様</td>
            </tr>
            <tr>
              <th>氏名</th>
              <td>{props ? props.driver.name : null}</td>
            </tr>
            <tr>
              <th>得意先名</th>
              <td>{props.project ? props.project.name : null}</td>
            </tr>
          </table>
        </div>
        <div className="incenter">
          <table>
            <tr>
              <td>{props.year}</td>
              <td>年</td>
            </tr>
            <tr>
              <td>{props.month} </td>
              <td>月度分</td>
            </tr>
          </table>
        </div>
        <div className="inright">
          <p>({project.getEndDateString()})</p>
        </div>
        </div>
        <div className="info_box under text">
        <p></p>
      </div>
     </div>
  )
}

export default HeaderLogi
