import React, {useState, useEffect  } from 'react'
import { PageApp } from '../../stories/PageApp'
import DriverConfirm from "./DriverConfirm"
import useDriverState from './useDriverState';

function DriverConfirmApp(props:PropsValue) {

  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {driver, driver_error, driver_created, loadFromCookie, createDriver, removeCookie} = useDriverState()

  

  useEffect(() => {
    if(driver){
      setItem(driver)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver])


  useEffect(() => {

    if(token){
      loadFromCookie()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
   
    if(driver_created && driver_created.id){
      removeCookie()
      const is_update = item.id ? "?updated=true" : ""
      window.location.href = "/driver/success" + is_update
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[driver_created])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    createDriver(token, _item)
  }
  
  return(
    <div id="home">
      <PageApp current_page="driver" content={<DriverConfirm   token={token} driver={item} error={driver_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default DriverConfirmApp

