import React, { useEffect, useState,  } from 'react'
import config from 'react-global-configuration';
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import Utile from '../../utiles/Utile';

function ReportConfirm(props:PropsValue) {



  const [project, setProject] = useState(null)
  const [project_driver, setProjectDriver] = useState(null)
  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  
  useEffect(() => {
    setItem(props.report)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.report])
  

  useEffect(() => {
    
    setProject(props.project)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project])

  useEffect(() => {
    
    setProjectDriver(props.project_driver)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.project_driver])

  useEffect(() => {
    if(props.error)
      setErrorMessage("エラーが発生しました。")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.error])


  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler(item)
  }

  const backHandler = () => {
   const url = item.id ? "/project/" + item.id : "/project/new"
   window.location.href = url
  }

  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
              <h2 className="page_title">日報入力</h2>
                <Breadcrumbs pages={[new LinkProps("日報入力","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">日報入力 内容確認</p>
                  {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
									<div className="input_wrap">
                  <table className="input_table col12 login_tbl">
											<tbody>
												<tr>
													<th className="tl">案件名</th>
													<td>{project ? project.name : null}</td>
												</tr>
												<tr>
													<th className="tl">区分</th>
													<td>{project ? Utile.getLabelByValue(config.get("PROJECT_TYPE"), project.project_type) : null}</td>
												</tr>
												<tr>
													<th className="tl">依頼先会社名</th>
													<td>{project ? project.client.name : null}</td>
												</tr>
												<tr>
													<th className="tl">ドライバー名</th>
													<td>{project_driver ? project_driver.driver.name : null}</td>
												</tr>
												<tr>
													<th className="tl">日付</th>
													<td>{item ? item.worked_date : undefined}</td>
												</tr>
												<tr>
													<th className="tl">稼働</th>
													<td>{item ? item.is_worked : undefined}</td>
												</tr>
												<tr>
													<th className="tl">開始（着車）時間 / 発時</th>
													<td>{item ? item.work_start_at : undefined}</td>
												</tr>
												<tr>
													<th className="tl">終了（完了/業務締め完了）時間</th>
													<td>{item ? item.work_end_at : undefined}</td>
												</tr>
												<tr>
													<th className="tl">配達エリア</th>
													<td>{item ? item.area : undefined}</td>
												</tr>
												<tr>
													<th className="tl">積み地</th>
													<td>{item ? item.load_location : undefined}</td>
												</tr>
												<tr>
													<th className="tl">納品先</th>
													<td>{item ? item.delivery_location : undefined}</td>
												</tr>
												<tr>
													<th className="tl">業務内容</th>
													<td>{item ? item.detail : undefined}</td>
												</tr>
												<tr>
													<th className="tl">距離（走行距離）</th>
													<td>{item ? item.distance : undefined}</td>
												</tr>
												<tr>
													<th className="tl">開始メーター</th>
													<td>{item ? item.meter_start : undefined}</td>
												</tr>
												<tr>
													<th className="tl">終了メーター</th>
													<td>{item ? item.meter_end : undefined}</td>
												</tr>
												<tr>
													<th className="tl">配達件数</th>
													<td>{item ? item.delivery_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">宅配完了個数</th>
													<td>{item ? item.compleated_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">ネコポス個数</th>
													<td>{item ? item.nekopos_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">DM便個数</th>
													<td>{item ? item.dm_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">普通</th>
													<td>{item ? item.normal_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">夜間便</th>
													<td>{item ? item.night_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">集荷</th>
													<td>{item ? item.load_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">大型</th>
													<td>{item ? item.large_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">持戻り</th>
													<td>{item ? item.return_unit : undefined}</td>
												</tr>
												<tr>
													<th className="tl">待機</th>
													<td>{item ? item.waiting_hour : undefined}</td>
												</tr>
												<tr>
													<th className="tl">運賃</th>
													<td>{item ? item.fee : undefined}</td>
												</tr>
												<tr>
													<th className="tl">日合計売上</th>
													<td>{item ? item.sales : undefined}</td>
												</tr>
												
												<tr>
													<th className="tl">高速代</th>
													<td>{item ? item.high_way_cost : undefined}</td>
												</tr>
												<tr>
													<th className="tl">駐車場代</th>
													<td>{item ? item.parking_cost : undefined}</td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>{item ? item.note : undefined}</td>
												</tr>
												<tr>
													<th className="tl">領収書</th>
													<td>画像添付</td>
												</tr>
												<tr>
													<th className="tl">領収書</th>
													<td>画像添付</td>
												</tr>
												<tr>
													<th className="tl">領収書</th>
													<td>画像添付</td>
												</tr>
											</tbody>
										</table>
                 
									</div>
								
                </div>
                <div className="button_wrap center">
								
                    <button className="red" type="button" onClick={btnClickHandler}>  { item && item.id ? "この情報で編集":"この情報で登録"} </button>
               
							  </div>

                <div className="button_wrap center">
								  <button className="red" type="button" onClick={backHandler}>入力に戻る</button>
							  </div>


                </div> 
				</div>  
      
		</div>

  )
}

export default ReportConfirm

