
import Report from "../Report"

class ReportCharter  extends Report{
  delivery_unit = "持出個数"
  return_unit = "持戻個数"
  received_gender = "受領者（性別）"
  received_name  = "受領者（名前）"
  area = undefined
  detail = undefined
  meter_start = undefined
  meter_end = undefined
  compleated_unit= undefined
  nekopos_unit = undefined
  dm_unit = undefined
  normal_unit = undefined
  night_unit = undefined
  load_unit = undefined
  large_unit = undefined
  driver_name = undefined
  
}

export default ReportCharter

