import React, {useState, useEffect  } from 'react'
import { PageApp } from '../../stories/PageApp'
import PartnerConfirm from "./PartnerConfirm"
import usePartnerState from './usePartnerState';

function PartnerConfirmApp(props:PropsValue) {

  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {partner, partner_error, partner_created, loadFromCookie, createPartner, removeCookie} = usePartnerState()

  

  useEffect(() => {
    if(partner){
      setItem(partner)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner])


  useEffect(() => {

    if(token){
      loadFromCookie()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(partner_created && partner_created.id){
      removeCookie()
      const is_update = item.id ? "?updated=true" : ""
      window.location.href = "/partner/success" + is_update
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner_created])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    createPartner(token, _item)
  }
  
  return(
    <div id="home">
      <PageApp current_page="partner" content={<PartnerConfirm  partner={item} error={partner_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default PartnerConfirmApp

