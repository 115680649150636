import React, { useEffect, useState } from 'react'
import config from 'react-global-configuration';

import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
import { Pageing } from '../../stories/components/pageing/Pageing';
import Utile from '../../utiles/Utile';
import { SearchPartner } from '../../stories/components/searchbox/SearchPartner';
import PartnerProps from './PartnerProps';
import useProjectDriverState from '../project/driver/useProjectDriverState';
import useProjectState from '../project/useProjectState';
import useReportState from '../report/useReportState';
import PartnerDetailOverlay from './PartnerDetailOverlay';

function PartnerList(props:PropsValue) {


  const [list, setList] = useState(null)
  const [total_number, SetTotalNumber] = useState(1)
  const [message_id, setMessageId]= useState(null)
  const {my_project_list,  getListByPartners} = useProjectDriverState()
  const {project_list, getProjectList} = useProjectState()

  const [detail, setDetail] = useState(null)

  const {getReportUrl} = useReportState()



  const closeHandler = () => {
    setDetail(null)
  }

  const clearHandler = () => {
    window.location.href = "/partner"
  }


  useEffect(() => {
   // const project_driver = my_project_list ? my_project_list.find(myp => myp.)
    const user_ids = props.partners.map(item => item.user_id)
    getListByPartners(props.token, user_ids)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.partners])


  useEffect(() => {
    if(my_project_list){
      getProjectList(props.token,1,null,{ids:my_project_list.map(item => item.project_id)})
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[my_project_list])

  useEffect(() => {
    
    setList(
      props.partners.map(item => {
        const project = project_list.find(p => p.user_id === item.user_id)

        const project_driver = project ? my_project_list.find(p => p.project_id === project.id) :null
        const url = project_driver ? getReportUrl(project.uid, project_driver.uid, "new") :null
        return(
          <tr key={"item_" + item.id} >
					  <td>{item.id}</td>
						<td>{item.name}</td>
						<td>{Utile.getLabelByValue(config.get("PREFS"), item.prefecture)}</td>
            <td>{item.name_in_charge}</td>
            <td>{item.tel}</td>
						
            <td>{item.email}</td>
            <td><a href={item.contract_url}  ><i className="fa-solid fa-arrow-up-right-from-square"></i></a></td>
            <td>{item.commision}%</td>
						<td>
              {url ? <span><span className="link" onClick={()=> copy(url,item.id)}><i className="fa-regular fa-copy"></i></span> </span>: null}
              {message_id === item.id ? <div><span className="copyied">コピーしました。</span></div> : null}
            </td>
            <td>
                <div className="buttons">
                  <div className="textbutton" onClick={() =>  displayDetail(item)}>詳細確認</div>
                </div>
            </td>
            
					</tr>
        )
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.partners,project_list,message_id])

  
  useEffect(() => {
    SetTotalNumber(props.total_number)
  },[props.total_number])
  
  const btnClickHandler = (e) =>{
    props.search(e)
  }

  
  const copy = (u,id) => {
    navigator.clipboard.writeText(u).then(  () => {}, () => {});
    setMessageId(id)
  }

  const displayDetail = (partner) => {
    setDetail(<PartnerDetailOverlay token={props.token}  profile={props.profile} partner={partner} is_edit={false} closeHandler={closeHandler} />)
  }
  

  return(
    <div className="inner">
        {detail?  <div id="overlay" className="overlay" onClick={closeHandler}></div> : null}
			  <div className="content_wrap">
					  <div className="page_title_wrap">
								<h2 className="page_title">依頼先会社一覧</h2>
                <Breadcrumbs pages={[new LinkProps("依頼先会社一覧","#")]} />
						</div>
            <a href="/partner/new?new=1" className="button red">新規依頼先会社登録</a>
            <SearchPartner token={props.token} data={new PartnerProps()} btnClickHandler={btnClickHandler} clearHandler={clearHandler}/>
            <table className="datatable">
							
							<thead>
                <tr>
									<th>No.</th>
									<th>依頼先会社名</th>
									<th>都道府県</th>
                  <th>担当者</th>
									<th>電話番号</th>
                  <th>メール</th>
                  <th>契約書</th>
                  <th>支払い手数料</th>
                  <th>日報入力フォーム</th>
                  <th>詳細確認</th>
				
								</tr>
							</thead>
							<tbody>
              {list}
							</tbody>
							<tfoot>
								<tr>
									<td colspan="99">
                    <Pageing total_number={total_number} current_page={props.current_page} limit={props.search_limit}  />
										
									</td>
								</tr>
							</tfoot>
						</table>
				</div>
        {detail}
		</div>

  )
}

export default PartnerList


/**
 *  <a href={"/partner/" + item.id + "?edit=1"} className="icon_button"><i className="fa-solid fa-pen"></i></a>
                <a href={"/partner/" + item.id + "/delete"} className="icon_button"><i className="fa-solid fa-trash"></i></a>
 */