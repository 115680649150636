
import 'whatwg-fetch'
import config from 'react-global-configuration';
import cookie from 'react-cookies'
import Action from '../../action/Action';

const MonthlyReportAction = {


  getList(token, offset, project_id, driver_id, report_type, year, month, callback, errorHandler){
    const data = new FormData() 
    if(project_id)
      data.append('project_id', project_id)
    if(driver_id)
      data.append('driver_id', driver_id)
    if(report_type)
      data.append('report_type', report_type)
    if(year)
      data.append('year', year)
    if(month)
      data.append('month', month)
    
    const queryString = new URLSearchParams(data).toString()
    const url = config.get("API_PATH") + config.get("API_MONTHLY_REPORT") + "?"  + queryString

    Action.fetchAuthorizedJson( url , token, "GET", {}, null,  callback, errorHandler)
  },
  

  getDetail(token, id,  callback, errorHandler){
    Action.fetchAuthorizedJson( config.get("API_PATH") + config.get("API_MONTHLY_REPORT")  + id  , token, "GET", {}, null,  callback, errorHandler)
  },

  create( token, project_id, deiver_id, project_driver_id, item,  callback, errorHandler){
    const method = item.id ? "PATCH" : "POST"
    const url = item.id ? config.get("API_PATH") + config.get("API_MONTHLY_REPORT") + project_id + "/" + deiver_id + "/" + item.id  : config.get("API_PATH") + config.get("API_MONTHLY_REPORT") + project_id + "/" + deiver_id + "/" + project_driver_id
    
    Action.fetchAuthorizedJson(url , token, method,  {'Content-Type': 'application/json' }, JSON.stringify(item),  callback, errorHandler)
  },

  delete( token, id, project_id, deiver_id, callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_MONTHLY_REPORT")  + project_id + "/" + deiver_id + "/" + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  saveOnCookie(item){
    cookie.save('mreport_form',item, { path: '/' })
  },

  loadSearchParams( ){
    return   cookie.load("mreport_search_form")
  },

  saveSearchParams(item){
    cookie.save('mreport_search_form',item, { path: '/' })
  },
  deleteSearchParams(item){
    cookie.remove('mreport_search_form',{ path: '/' })
  },

  loadFromCookie( callback, errorHandler){
    const driver =  cookie.load("mreport_form")
   
    if(driver) {
      callback({data:driver})
    }else{
      errorHandler({"error":"not found"})
    }
  },

  hasCookie(item, callback, errorHandler){
    const project_form =  cookie.load("mreport_form")
    if(project_form) return true 
    return false
  },
  removeCookie(item, callback, errorHandler){
    cookie.remove('mreport_form', { path: '/' })
  }
 
 
}

export default MonthlyReportAction
