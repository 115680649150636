import { Record} from 'immutable'


const ProfileProps = Record({
  id:undefined,
  name: undefined,
  user_type: undefined,
  company:null
});
export default ProfileProps;
