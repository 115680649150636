import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../../stories/PageApp'
import ProjectDriverDelete from "./ProjectDriverDelete"
import useProjectDriverState from './useProjectDriverState';

function ProjectDriverDeleteApp(props:PropsValue) {
  const { project_id } = useParams();
  const { id } = useParams();
  const [token, setToken] = useState()

  const [item, setItem] = useState(null)

  const {project_driver, project_driver_error, project_driver_deleted, getProjectDriver, deleteProjectDriver} = useProjectDriverState()
 
  

  useEffect(() => {
    if(project_driver){
      setItem(project_driver)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_driver])


  useEffect(() => {
    if(token){
      getProjectDriver(token, project_id, id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token, id, project_id])
  
  useEffect(() => {
    if(project_driver_deleted){
      window.location.href = "/project/" + project_id + "/driver/success/deleted"
    }
  },[project_driver_deleted, project_id])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = () => {
    deleteProjectDriver(token, project_id, id)
  }
  
  return(
    <div id="home">
      <PageApp content={<ProjectDriverDelete token={token} project_id={project_id} project={item} error={project_driver_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectDriverDeleteApp

