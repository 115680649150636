
import BankInfoProps from './BankInfoProps'

const props = extendValues => class extends BankInfoProps {};
class BankInfo extends props({}) {  

  getString() {
    const bank_name = this.bank_name  ? this.bank_name   :""
    const branch_name = this.branch_name  ? this.branch_name   :""
    const account_type = this.account_type  ? this.account_type   :""
    const account_name = this.account_name  ? this.account_name   :""
    const account_id = this.account_id  ? this.account_id   :""

    return bank_name + " " + branch_name  + " " + account_type + " " + account_name + " " +account_id
  }



}
export default BankInfo
