
import 'whatwg-fetch'
import config from 'react-global-configuration';

import Action from '../action/Action';

const ReceiptAction = {

  create(  report_id, item, callback, errorHandler){
    const formData = new FormData();
    if(item.file){
      formData.append('file', item.file);
    }
    
    formData.append('price', item.price); 
    formData.append('payto', item.payto); 
    formData.append('description', item.description);
    const url =  config.get("API_PATH") + config.get("API_REPORT") + report_id + "/medias/" 
    Action.createMedia(url , formData,  callback, errorHandler)
  },

  getImage( token,  report_id, path, callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_REPORT")  + report_id + "/medias/" + path 
    Action.getMedia(token, url, callback, errorHandler)
  },


  delete( token, id, project_id, deiver_id, callback, errorHandler){
    const url =  config.get("API_PATH") + config.get("API_REPORT")  + project_id + "/" + deiver_id + "/" + id 
    Action.fetchAuthorizedDelete(url , token, {},  callback, errorHandler)
  },

  
 
 
}

export default ReceiptAction
