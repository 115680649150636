import React, { useEffect, useState } from 'react'

import config from 'react-global-configuration';
import { ManagerSearchBox } from '../../stories/components/manager_search/ManagerSearchBox'
import { ParnterSearchBox } from '../../stories/components/partner_search/ParnterSearchBox'
import BankInfo from "../bankinfo/BankInfo"
import Utile from '../../utiles/Utile';

function UserDetailItem(props:PropsValue) {


  const [item, setItem] = useState(null)

  
  useEffect(() => {
    if(props.driver){
      setItem(props.driver)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.driver])
  

  const changeHandler = (e) => {
    const target = e.target
    const new_item = item.set(target.name, target.value.trim())
    setItem(new_item)
    props.changeHandler(new_item)
  } 



  const changePartnerHandler = (e) => {

    var new_item = item.set("partner_id", Number(e.value))
    if(e.is_self === 1){
      //new_item = new_item.set("partner", (new Partner({is_self:1})))
     new_item = new_item.set("company_driver",1)
    }else{
     new_item = new_item.set("company_driver",0)
    }
    
    setItem(new_item)
    props.changeHandler(new_item)
  } 
    
  const changeManagerHandler = (e) => {
    const new_item = item.set("user_id", Number(e.value))
    setItem(new_item)
    props.changeHandler(new_item)
  }

  const changeBankHandler = (e) => {
    const target = e.target 
    const new_item = item.setIn(["bank_info", target.name], target.value )
    setItem(new_item)
    props.changeHandler(new_item)
  }



  return(
    <table className="input_table col12 login_tbl">
    <tbody>
    <tr>
        <th className="tl">所属企業 *</th>
       
        <td>
        {props.is_edit ? 
           
            item && item.partner ? 
            item.partner.name
            :<ParnterSearchBox changeHandler={changePartnerHandler} token={props.token} defaultValue={item ? item.partner_id : null} />
           
          :item ? item.partner.name : null
        }
        </td>
      </tr>
      <tr>
        <th className="tl">担当マネージャー *</th>
        <td>
         {props.is_edit ? 
          item  ? <ManagerSearchBox changeHandler={changeManagerHandler} token={props.token} selected={item  && item.user_id ? item.user_id : null}/> : null
          :item && item.user ? item.user.name : null
         }
        </td>
      </tr>


      <tr>
        <th className="tl">ドライバー名 *</th>
        <td>
          {props.is_edit ? 
            <input autocomplete="off" type="text" className="col9" placeholder="犬飼　太郎" name="name" value={item ? item.name : undefined}  onChange={changeHandler} />
            :item ? item.name : null}
          </td>
      </tr>
      <tr>
        <th className="tl">フリガナ *</th>
        <td>
          {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="イヌカイ　タロウ" name="kana" value={item ? item.kana : undefined}  onChange={changeHandler} />
          :item ? item.kana : null}
          </td>
      </tr>
    
      <tr>
        <th className="tl">電話番号 *</th>
        <td>
          {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder="08012340000" name="tel" value={item ? item.tel : undefined}  onChange={changeHandler} />
          :item ? item.tel : null}
          </td>
      </tr>
      <tr>
        <th className="tl">車両番号</th>
        <td>
          {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" placeholder=""  name="car_no" value={item ? item.car_no : undefined}  onChange={changeHandler}/>
          :item ? item.car_no : null}
          </td>
      </tr>

      <tr>
        <th className="tl">インボイス番号</th>
        <td>
          {props.is_edit ? 
          <input autocomplete="off" type="text" className="col9" step="0.01" placeholder="" name="invoice" value={item ? item.invoice : undefined}  onChange={changeHandler} />
          :item ? item.invoice : null}
          </td>
      </tr>
{item &&( (item.partner && item.partner.is_self === 1 ) || item.company_driver === 1)?
      <tr>
													<th className="tl">支払い手数料 *</th>
													<td>
                          {props.is_edit ? 
                            <input autocomplete="off" type="number" step="0.01" onWheel={(e) => e.target.blur()} className="col3" placeholder=""  name="commision" value={item ? item.commision : undefined}  onChange={changeHandler}/>
                            :item ? item.commision : null}
													 &nbsp;%  </td>
												</tr>
        :null }
{item &&( (item.partner && item.partner.is_self === 1 ) || item.company_driver === 1)?
												<tr>
													<th className="tl">支払いサイト *</th>
													<td>

                            {
                                props.is_edit ? 
                                <select name="payment_day_type" value={item ? item.payment_day_type : undefined}  onChange={changeHandler} >
                                  <option value="" selected="">選択してください</option>
                                  {
                                    config.get("PAYMENT_TYPE").map(_item => {
                                      return(<option value={_item.value}>{_item.label}</option>)
                                    })
                                }
                                </select>
                                :item ? Utile.getLabelByValue(config.get("PAYMENT_TYPE"),Number(item.payment_day_type)) : null
                            }
                            
													</td>
												</tr>
      :null}

{item &&( (item.partner && item.partner.is_self === 1 ) || item.company_driver === 1)?       
      <tr>
        <th className="tl">振込先情報 *</th>
        <td>
          {props.is_edit ? 
          <div>
             <input autocomplete="off" type="text" className="col9" placeholder="銀行名"  name="bank_name" value={item  && item.bank_info ? item.bank_info.bank_name : undefined}  onChange={changeBankHandler}/>
             <input autocomplete="off" type="text" className="col9" placeholder="支店名"  name="branch_name" value={item  && item.bank_info ? item.bank_info.branch_name : undefined}  onChange={changeBankHandler}/>
             <select name="account_type" value={item && item.bank_info  ? item.bank_info.account_type : undefined}    onChange={changeBankHandler}>
                <option value="" selected="">選択してください</option>
                  {
                    config.get("ACCOUNT_TYPE").map(item => {
                      return (<option value={item.value}>{item.label}</option>)
                    })
                  }
                </select>
                <input autocomplete="off" type="text" className="col9" placeholder="口座名義"  name="account_name" value={item  && item.bank_info ? item.bank_info.account_name : undefined}  onChange={changeBankHandler}/>
                <input autocomplete="off" type="text" className="col9" placeholder="口座番号"  name="account_id" value={item  && item.bank_info ? item.bank_info.account_id : undefined}  onChange={changeBankHandler}/>
          </div>
         
          :item  && item.bank_info ? ( new BankInfo(item.bank_info).getString()) : null}
          </td>
      </tr>

:null}


      <tr>
        <th className="tl">備考</th>
        <td>
        {props.is_edit ? 
          <textarea type="text" className="col12" rows="5" name="note" value={item ? item.note : undefined}  onChange={changeHandler} />
          :item ? item.note : null}  
        </td>
      </tr>
    </tbody>
  </table>
    
  )
}

export default UserDetailItem

