import React, {} from 'react';





interface LoadingWindowProps {
  
  closeHandler:Function
}

/**
 * Primary UI component for user interaction
 */
export const LoadingWindow = ({

  closeHandler,
  ...props
}: LoadingWindowProps) => { 





  return (
    <div className="loading-window"  >
      <div>
        <i className="fas fa-sync fa-spin"></i>
      </div>
    </div>
  );
};

//<button className="js-close button-close" onClick={closeHandler}>Close</button>