import { useState } from 'react';

import ReceiptAction from './ReceiptAction';


function useReceiptState() {

  
  const [error, setError] = useState(null)
  const [created_counter, setCreatedCounter] = useState(0)
  const [createNumber, setCreateNumber] = useState(0)
  const [created, setCreated] = useState(null)
  const [media, setMedia] = useState(null)
  

  const createdHandler = (data) => {

    if(created_counter + 1 >= createNumber){
      setCreated(data.data)
    }else{
      const new_create = created_counter + 1
      setCreatedCounter(new_create)
    }
    
  }

  const imageLoadHandler = (data) => {
    setMedia(data)
  }
  

  const errorHandler = (data) => {
    setError(data)
  }
  
  const create = ( report_id,  items) => {

    setCreateNumber(items.length)
    items.map(item => {
      ReceiptAction.create( report_id, item, createdHandler, errorHandler)
      return true
    })
   
  }

  const getImage = ( report_id,  url, token) => {
    if(url){
      ReceiptAction.getImage( token, report_id, url, imageLoadHandler, errorHandler)
    }
   
  }


  return { media, receipt_error:error, receipt_created:created,  createReceipt:create, getImage};
}

export default useReceiptState