
import AddressInfoProps from './AddressInfoProps'

const props = extendValues => class extends AddressInfoProps {};
class AddressInfo extends props({}) {  




}
export default AddressInfo
