import React, {useState, useEffect  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ReportConfirm from "./ReportConfirm"
import ReportProps from './ReportProps';
import useReportState from './useReportState';
import useProjectState from '../project/useProjectState';
import useProjectDriverState from '../project/driver/useProjectDriverState';


function ReportConfirmApp(props:PropsValue) {

  const { id } = useParams();
  const { project_id } = useParams();
  const { project_driver_id } = useParams();

  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)


  const {report, report_created, report_error, getReport, hasCookie, removeCookie, createReport, loadFromCookie} = useReportState()
  const {project, getProject} = useProjectState()
  const {project_driver, getProjectDriver} = useProjectDriverState()

  useEffect(() => {

    if(token){

      getProject(token, project_id)
      getProjectDriver(token, project_id, project_driver_id)

      if(hasCookie()){
        loadFromCookie()
      }else{
        if(id === "new"){
          setItem(new ReportProps())
        }else{
          getReport(token, id)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  

  useEffect(() => {
    if(report){
      setItem(new ReportProps(report))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report])

  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }

  useEffect(() => {
    if(report_created && report_created.id){
      removeCookie()
      window.location.href = "/project/report/success"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[report_created])



  const updateHandler = (_item) => {
    createReport(token,project_id,  project_driver.driver.uid, _item)
  }
  
  return(
    <div id="home">
      <PageApp content={<ReportConfirm   token={token}  project={project} project_driver={project_driver} report={item} error={report_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ReportConfirmApp

