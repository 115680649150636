import React, {  } from 'react'
import { useSearchParams } from 'react-router-dom';
import { Breadcrumbs } from '../../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../../stories/components/breadcrumbs/LinkProps';

function ProjectDriverCreateSuccess(props:PropsValue) {

  const [searchParams] = useSearchParams();
  const updated_str = searchParams.get("updated") ?  "担当ドライバー更新" : "担当ドライバー登録" ;


  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
                <h2 className="page_title">{updated_str}</h2>
                <Breadcrumbs pages={[new LinkProps("案件一覧","/project"),new LinkProps(updated_str,"#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">担当ドライバー登録 完了</p>
                <p>{updated_str}が完了しました。</p>
									
							</div>
						</div> 
				</div>
		</div>

  )
}

export default ProjectDriverCreateSuccess

