import React, {useState, useEffect  } from 'react'
import { PageApp } from '../../stories/PageApp'
import UserConfirm from "./UserConfirm"
import useUserState from './useUserState';

function UserConfirmApp(props:PropsValue) {

  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {user, user_error,user_created, loadFromCookie, userCreate, removeUserCookie} = useUserState()

  

  useEffect(() => {
    if(user){
      setItem(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user])


  useEffect(() => {
    if(token){
      loadFromCookie()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(user_created && user_created.id){
      removeUserCookie()
      const is_update = item.id ? "?updated=true" : ""
      window.location.href = "/user/success" + is_update
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user_created])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    userCreate(token, _item)
  }
  
  return(
    <div id="home">
      <PageApp current_page="user"  content={<UserConfirm  user={item} error={user_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default UserConfirmApp

