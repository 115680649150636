import React, { useEffect, useState } from 'react'
import { Header } from '../../stories/components/header/Header';
import useLoginState from './useLoginState'


function PartnerLoginApp(props:PropsValue) {

  const [mail, setMail] = useState(undefined)
  const [password, setPassword] = useState(undefined)
  const {token, loginError, isLogin, loginPartner} = useLoginState()

  useEffect(() => {
    if(isLogin() === true){
      window.location.href = "/partners/dashboard"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  

  useEffect(() => {
    if(token){
     window.location.href = "/partners/dashboard"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  


  const loginClickHandler = (e) => {
    loginPartner(mail, password)
  }


  return(
    <div id="home">
      <div>
      <Header profile={null}  noauth={true}/>
      <div className="main_row row">
        <main>
          <div className="content_wrap sub_narrow">
            <div className="container">
              <div className="column">
                <div className="input_wrap">
                  
                  <table className="input_table col12 login_tbl">
                    <tbody>
                      <tr>
                        <th className="tl">ユーザーID</th>
                        <td><input type="text" className="col9" value={mail} onChange={(e) => setMail(e.target.value)}/></td>
                      </tr>
                      <tr>
                        <th class="tl">パスワード</th>
                        <td><input type="password" className="col9" value={password} onChange={(e) => setPassword(e.target.value)}/></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {loginError ? <div className="error">ログインに失敗しました。IDとパスワードを確認してください。</div> : null}
                <div className="button_wrap center">
                  
                  <button className="red" onClick={loginClickHandler} type="submit">ログイン</button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    </div>
     
  )
}

export default PartnerLoginApp

