import React, {useEffect, useState} from 'react';
import "./memo.css"
import useMemoState from './useMemoState';
import Memo from "./Memo"
import { MemoItem } from './MemoItem';

interface MemoComponentProps {
  token:String,
  category:Number,
  uniqueid:String
}

/**
 * Primary UI component for user interaction
 */
export const MemoComponent = ({
  token,
  category,
  uniqueid,
  ...props
}: MemoComponentProps) => { 

  const [items, setItems] = useState(null)
  const {memo, memo_updated_at, memo_deleted_at,createMemo, getMemo, deleteMemo } = useMemoState()

  useEffect(() => {
    setItems(null)
    getMemo(token,category, uniqueid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[memo_deleted_at])

  useEffect(() => {
    if(memo){
      setItems(memo.map((item, index) => {
  
          return <MemoItem memo={new Memo(item)} index={index} token={token} deleteHandler={deleteItem}/>
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[memo_updated_at])



  const createNew = () => {
    createMemo( token, new Memo({category:category, uniqueid:uniqueid}))
  }

  const deleteItem = (id) => {
    deleteMemo( token, id)
  }
  return (
    <div className="memo-list">
      <div className="memo-list-new link" onClick={createNew} > <i className="fa-solid fa-circle-plus"></i> 新規メモ作成</div>
     {items}
    </div>
  );
};

