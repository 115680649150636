import React, {useState, useEffect  } from 'react'
import { PageApp } from '../../stories/PageApp'
import ProjectConfirm from "./ProjectConfirm"
import useProjectState from './useProjectState';

function ProjectConfirmApp(props:PropsValue) {

  const [token, setToken] = useState(null)
  const [item, setItem] = useState(null)

  const {project, project_error, project_created, loadFromCookie, createProject,removeCookie} = useProjectState()


  useEffect(() => {
    if(project){
      setItem(project)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[project])


  useEffect(() => {
    if(token){
      loadFromCookie()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  useEffect(() => {
    if(project_created && project_created.id){
      removeCookie()
      const is_update = item.id ? "?updated=true" : ""
      window.location.href = "/project/success" + is_update
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[project_created])

  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const updateHandler = (_item) => {
    createProject(token, _item)
  }
  
  return(
    <div id="home">
      <PageApp current_page="project"  content={<ProjectConfirm   token={token} project={item} error={project_error} updateHandler={updateHandler}/>} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ProjectConfirmApp

