import { Record} from 'immutable'


const PositionProps = Record({
  top:100,
  left: 1200,
  width: 280,
  height: 210
});
export default PositionProps;
