import { Record } from 'immutable'


const  ReportSearchProps = Record({
  project_type:null,
  project_id:null,
  driver_id:null,
  start_date:null,
  end_date:null,
  free_word:null,
  partner_id:null
});
export default ReportSearchProps;


