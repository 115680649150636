
import Project from "../Project"

class YuseiProject  extends Project{

  is_unit_price = false
  is_over_time_fee = false
  is_over_distance_fee = false
  

  validateForm(form){
    const errors = []
    if(!form.fee_information.unit_yusei_normal){  errors.push("通常単価が未入力です")}
    if(!form.fee_information.unit_yusei_big){  errors.push("大型単価が未入力です")}
    if(!form.fee_information.unit_yusei_yakan){  errors.push("夜間単価が未入力です")}
    if(!form.fee_information.unit_yusei_collect){  errors.push("通常単価が未入力です")}

    return errors
  }


}

export default YuseiProject


