import React, { useEffect, useState,  } from 'react'
import { Breadcrumbs } from '../../stories/components/breadcrumbs/Breadcrumbs';
import LinkProps from '../../stories/components/breadcrumbs/LinkProps';
//user
import useUserState from '../user/useUserState';

//Partner
import usePartnerState from '../partners/usePartnerState';

function DriverDelete(props:PropsValue) {

  const [item, setItem] = useState(null)
  const [error_message, setErrorMessage] = useState(null)
  const [user_object, setUserObject] = useState(null)
  const [partner_object, setPartnerObject] = useState(null)

  const {user, getUserDetail} = useUserState()
  const {partner, getPartner} = usePartnerState()

  useEffect(() => {
    if(props.driver){
      setItem(props.driver)
      getUserDetail(props.token, props.driver.user_id)
      getPartner(props.token, props.driver.partner_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.driver])

  useEffect(() => {
    if(user)
      setUserObject(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user])

  useEffect(() => {
    if(partner)
     setPartnerObject(partner)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner])


  const btnClickHandler = () => {
    setErrorMessage(null)
    props.updateHandler()
  }




  return(
    <div className="inner">

			  <div className="content_wrap sub_narrow">
					  <div className="page_title_wrap">
								<h2 className="page_title">ドライバー 削除</h2>
                <Breadcrumbs pages={[new LinkProps("ドライバー一覧","/partner"),new LinkProps("ドライバー削除","#")]} />
							</div>
							
							<div className="container">
              <div className="column"><p className="container_name">ドライバー削除</p>
                {error_message ? <div className="input_wrap"> <div>{error_message} </div> </div> : null }      
								<div className="input_wrap">
					
										<table className="input_table col12 login_tbl">
                    <tbody>
											
												<tr>
													<th className="tl">担当マネージャー</th>
													<td>{user_object?user_object.name:null}</td>
												</tr>
												<tr>
													<th className="tl">ドライバー名</th>
													<td>{item?item.name:null}</td>
												</tr>
												<tr>
													<th className="tl">フリガナ</th>
													<td>{item?item.kana:null}</td>
												</tr>
												<tr>
													<th className="tl">所属企業</th>
													<td>{partner_object?partner_object.name:null}</td>
												</tr>
												<tr>
													<th className="tl">電話番号</th>
													<td>{item?item.tel:null}</td>
												</tr>
												<tr>
													<th className="tl">車両番号</th>
													<td>{item?item.car_no:null}</td>
												</tr>
												<tr>
													<th className="tl">インボイス番号</th>
													<td>{item?item.invoice:null}</td>
												</tr>
												<tr>
													<th className="tl">振込先情報</th>
													<td>{item?item.bank_info.bank_name + " " + item.bank_info.branch_name  + " " + item.bank_info.account_type + " " + item.bank_info.account_id :null}</td>
												</tr>
												<tr>
													<th className="tl">備考</th>
													<td>{item?item.note:null}</td>
												</tr>
											</tbody>
										</table>
								</div>
							</div>
              <div className="button_wrap center">
								 <button className="red" type="button" onClick={btnClickHandler}>削除する</button>
               </div>
            </div> 
				</div>  
      
		</div>

  )
}

export default DriverDelete
