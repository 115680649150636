import { Record } from 'immutable'


const UserProps = Record({
  id:null,
  name:null,
  furigana:null,
  username:null,
  password:null,
  user_type:null,
  tel:null,
  note:null
});
export default UserProps;
