import { useState } from 'react';
import ProjectDriverAction from './ProjectDriverAction';
import format from 'date-fns/format';
import { isSameMonth } from 'date-fns';

function useProjectDriverState() {

  
  const [error, setError] = useState(null)
  const [created, setCreated] = useState(null)
  const [item, setItem] = useState(null)
  const [list, setList] = useState(null)
  const [list_updated_at, setListUpdatedAt] = useState(null)

  const loadedListHandler = (data) => {
    setList(data.data)
    setListUpdatedAt((new Date()).getMilliseconds())
  }
  const loadedHandler = (data) => {
    setItem(data.data)
  }
  const createdHandler = (data) => {
    setItem(data.data)
    setCreated(data.data)
  }

  const errorHandler = (data) => {
    setError(data)
  }

  const hasCookie = () => {
    return ProjectDriverAction.hasCookie()
  }
  const saveOnCookie = (item) => {
    ProjectDriverAction.saveOnCookie(item)
  }

  const loadFromCookie = () => {
    ProjectDriverAction.loadFromCookie( loadedHandler, errorHandler)
  }

  const removeCookie = () => {
    ProjectDriverAction.removeCookie()
  }


  const getListByIds = (token, ids) => {
    ProjectDriverAction.getListByIds(token, ids, loadedListHandler, errorHandler)
  }

  const getListByPartner = (token, partner_id) => {
    ProjectDriverAction.getListByPartner(token, partner_id, loadedListHandler, errorHandler)
  }
  const getListByPartnerAndPeriod = (token, partner_id, start_date, end_date) => {

    ProjectDriverAction.getListByPartnerAndPeriod(token, partner_id, format(start_date,"yyyy-MM-dd"), format(end_date, "yyyy-MM-dd"), loadedListHandler, errorHandler)
  }
  const getListByClientAndPeriod = (token, client_id, start_date, end_date) => {
    ProjectDriverAction.getListByClientAndPeriod(token, client_id, format(start_date,"yyyy-MM-dd"), format(end_date, "yyyy-MM-dd"), loadedListHandler, errorHandler)
  }
  const getListByPartners = (token,user_ids) => {
    ProjectDriverAction.getListByPartners(token, user_ids, loadedListHandler, errorHandler)
  }

  const getListByProject = (token,project_id) => {
    ProjectDriverAction.getListByProject(token, project_id, loadedListHandler, errorHandler)
  }

  const getMyList = (token, target_date, client_id, project_id, partner_id, driver_id, with_spot = true , with_zero = 1, for_payment = false) => {
    ProjectDriverAction.getMyList(token, target_date, client_id, project_id, partner_id, driver_id, with_spot, with_zero, for_payment, loadedListHandler, errorHandler)
  }

  const create = (token, projedt_id, item) => {
    ProjectDriverAction.create(token, projedt_id, item, createdHandler, errorHandler)
  }

  const getDetail = (token, projedt_id, id) => {
    ProjectDriverAction.getDetail(token, projedt_id, id, loadedHandler, errorHandler)
  }

  const deleteItem = (token, projedt_id, id) => {
    ProjectDriverAction.delete(token,projedt_id , id, createdHandler, errorHandler)
  }

  const isWorking = (item, target_date, report_list) => {
    const result = report_list.find(report => report.project_driver_id === item.uid && isSameMonth(target_date, new Date(report.worked_date)))
    if(result) return true
    //if(isBefore(target_date, parseISO(item.start_date))) return false
    //if(item.end_date && isBefore(parseISO(item.end_date), target_date)) return false
    return false
  }



  return { my_project_list:list, project_driver:item,  project_driver_error:error, project_driver_deleted:created, project_driver_created:created, list_updated_at, project_driver_list_updated_at:list_updated_at,
    getMyProjectList:getMyList, getListByIds, getListByPartners,getListByPartner, getListByPartnerAndPeriod, getListByClientAndPeriod,  getListByProject, getProjectDriver:getDetail, hasCookie:hasCookie, saveOnCookie:saveOnCookie, loadFromCookie:loadFromCookie, removeCookie:removeCookie, createProjectDriver:create, deleteProjectDriver:deleteItem,
    isWorkingProject:isWorking
  };
}

export default useProjectDriverState