import React, {useState, useEffect  } from 'react'

import { useSearchParams } from "react-router-dom";

import { PageApp } from '../../stories/PageApp'
import PartnerList from "./PartnerList"
import usePartnerState from './usePartnerState';

function PartnerListApp(props:PropsValue) {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") ?  searchParams.get("page") : 1 ;
  const [token, setToken] = useState(null)
  const [list, setList] = useState([])
  const [total_number, setTotalNumber] = useState(1)
  const {partner_list, partner_updated_at,partner_total_number, partner_search_limit, getPartnerList} = usePartnerState()
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    if(partner_total_number > 1){
      setTotalNumber(partner_total_number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner_total_number])


  useEffect(() => {
    setList(partner_list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[partner_updated_at])

  useEffect(() => {

    if(token){
      //GET LIST
      getPartnerList(token,page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token])
  
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      setToken(e.token)
    }
  }


  const searchHandler = (e) => {
    getPartnerList(token,page, e)
  }

  const profileChangeHandler = (e) => {
    setProfile(e)
  }

  return(
    <div id="home">
      <PageApp current_page="partner" content={<PartnerList  token={token}  profile={profile}  partners={list} search_limit={partner_search_limit} total_number={total_number} current_page={page} search={searchHandler}  />} authChangeHandler={authChangeHandler} profileChangeHandler={profileChangeHandler}/>
    </div>
     
  )
}

export default PartnerListApp

