import React, {  } from 'react'
import { useParams } from 'react-router-dom';
import { PageApp } from '../../stories/PageApp'
import ReportCreateSuccess from "./ReportCreateSuccess"
import ReportDeleteSuccess from './ReportDeleteSuccess';

function ReportSuccessApp(props:PropsValue) {
  const { id } = useParams();
  const authChangeHandler = (e) => {
    if(e.error){
      console.log("error")
    }else{
      //setToken(e.token)
    }
  }

  return(
    <div id="home">
      <PageApp content={id === "deleted" ?  <ReportDeleteSuccess /> :<ReportCreateSuccess />} authChangeHandler={authChangeHandler} />
    </div>
     
  )
}

export default ReportSuccessApp

